import React, { useEffect } from 'react'

import compact from 'lodash/compact'
import isEqual from 'lodash/isEqual'
import startCase from 'lodash/startCase'
import memoizeOne from 'memoize-one'
import { useHistory } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import { FiltersValueType, renderFilters } from 'components/Filters'

import useTeamSetting from 'utils/hooks/useTeamSetting'
import { PortfolioCompaniesFiltersType } from 'utils/types'

interface Props {
  filters: PortfolioCompaniesFiltersType
  setFilters: (filters: PortfolioCompaniesFiltersType) => void
  allTags: string[]
  allStages: string[]
  loadingTags: boolean
  teamSlug: string
}

const downcastFilters = memoizeOne((filters: FiltersValueType) => {
  const result: PortfolioCompaniesFiltersType = {}

  const search = filters['search'] as string[] | null
  if (!!search?.length) {
    result.search = search
  }

  const tags = filters['tags'] as string[] | null
  if (!!tags?.length) {
    result.tags = tags
  }

  const stage = filters['stage'] as string[] | null
  if (!!stage?.length) {
    result.stage = stage
  }

  return result
}, isEqual)

const upcastFilters = memoizeOne((filters: PortfolioCompaniesFiltersType) => {
  const result: FiltersValueType = {}

  const search = filters.search
  if (!!search?.length) {
    result.search = search
  }

  const tags = filters.tags
  if (!!tags?.length) {
    result.tags = tags
  }

  const stage = filters.stage
  if (!!stage?.length) {
    result.stage = stage
  }

  return result
}, isEqual)

const Filters: React.VFC<Props> = ({
  filters,
  setFilters,
  allTags,
  allStages,
  loadingTags,
  teamSlug,
}) => {
  const history = useHistory()

  const tags = useSearchParam('tags')
  const stage = useSearchParam('stage')
  const search = useSearchParam('search')
  const { canViewPortfolio } = useAccessControl(teamSlug)

  const onSelectTag = (filters: PortfolioCompaniesFiltersType) => {
    const query: string[] = []

    Object.keys(filters).forEach((param) => {
      const filterKey = param as keyof PortfolioCompaniesFiltersType
      const list = encodeURIComponent(filters[filterKey]?.join(',') || '')
      query.push(`${param}=${list}`)
    })

    history.replace({
      search: `?${query.join('&')}`,
    })

    setFilters(filters)
  }

  useEffect(() => {
    const filterParams: {
      [Property in keyof PortfolioCompaniesFiltersType]: string | null
    } = { tags, stage, search }

    const filters: FiltersValueType = {}

    Object.keys(filterParams).forEach((param) => {
      const filterKey = param as keyof PortfolioCompaniesFiltersType
      const list = filterParams[filterKey]?.split(',')
      if (list && filterParams[filterKey] != '') {
        filters[filterKey] = list
      }
    })

    setFilters(filters)
  }, [])

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: compact([
      {
        key: 'search',
        type: 'string[]',
        label: <i className="far fa-search" />,
        labelStr: 'Search',
        skipLabelInAppliedPill: true,
      },
      {
        key: 'tags',
        type: 'dropdown[]',
        label: 'Tags',
        loadingOptions: loadingTags,
        options: allTags.map((t) => ({ label: t, value: t })),
      },
      canViewPortfolio && {
        key: 'stage',
        type: 'dropdown[]',
        label: 'Stage',
        options: allStages.map((s) => ({
          label: startCase(s),
          value: s,
        })),
      },
    ]),
    value: upcastFilters(filters),
    onChange: (f) => onSelectTag(downcastFilters(f)),
  })

  return (
    <div className={'flex flex-col justify-center'}>
      <div className="flex justify-between">
        <div className="flex flex-col sm:flex-row items-center">
          <div className="flex flex-wrap gap-2" data-testid="portfolio-companies-filters-options">
            {renderedFilters}
          </div>
        </div>
      </div>
      {appliedFilters && appliedFilters.length > 0 && (
        <div
          className="flex gap-1 flex-wrap mt-2"
          data-testid="portfolio-companies-applied-filters"
        >
          {appliedFilters}
        </div>
      )}
    </div>
  )
}

export default Filters
