import React, { useState } from 'react'

import AddWidget from 'containers/ListIndexView/Components/AddWidget'
import ImportSection from 'containers/ListIndexView/SalesList/SalesCompanyList/SalesCompanyAddWidget/ImportSection'

import AddIndividualSection from './AddIndividualSection'

interface Props {
  onClose: () => void
}

const SalesPeopleAddWidget: React.FC<Props> = ({ onClose }) => {
  const tabs = [
    {
      key: 'add_individually',
      label: 'Add Individually',
      iconClass: 'far fa-plus',
      component: <AddIndividualSection setOnClose={onClose} />,
      show: true,
    },
    {
      key: 'import',
      label: 'Import',
      iconClass: 'far fa-file-csv',
      component: <ImportSection setOnClose={onClose} isPeople={true} />,
      show: true,
    },
  ]

  return <AddWidget onClose={onClose} tabs={tabs} title="Add People" />
}

export default SalesPeopleAddWidget
