import React, { useEffect } from 'react'

import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import MemberViewSettings from 'containers/Settings/AsksSettings/MemberView'
import { useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanySlugParam, Team } from 'utils/types'

import CompanyInfo from './CompanyInfo'
import Features from './Features'

export interface TeamSettingProps {
  team: Team
  reloadTeam: () => Promise<any>
}

const Company = () => {
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const { team, reloadTeam } = useTeam(companySlug)

  const [showDefaults, setShowDefaults] = React.useState(false)

  const updateTeamMutation = useMutation(
    ['updateTeam'],
    (val: Partial<Team>) => callApi(api.updateTeam, companySlug, { team: val }),
    {
      onSuccess: () => {
        reloadTeam()
        cabalToast({ style: 'success', content: 'Changes saved' })
      },
    },
  )

  useEffect(() => {
    if (team) {
      setShowDefaults(!team.enable_portfolio)
    }
  }, [team, team?.enable_portfolio])

  if (!team) return <></>

  return (
    <div className="flex flex-col gap-4 mt-4">
      <CompanyInfo updateTeamMutation={updateTeamMutation} />
      <MemberViewSettings updateTeamMutation={updateTeamMutation} companySlug={companySlug} />
      <Features onEnablePortfolioChange={(enabled) => setShowDefaults(!enabled)} />
    </div>
  )
}

export default Company
