import React, { useEffect, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { sanitize } from 'dompurify'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import { SeeMore } from 'containers/CandidateProfile'
import DetailPageAboutSection from 'containers/ListIndexView/Components/DetailPageAboutSection'
import DetailPageDetailsSection from 'containers/ListIndexView/Components/DetailPageDetailsSection'
import DetailPageFilters from 'containers/ListIndexView/Components/DetailPageFilters'
import DetailPageHeader from 'containers/ListIndexView/Components/DetailPageHeader'
import JobsEditOrAddModal from 'containers/ListIndexView/TalentList/JobsList/JobsEditOrAddModal'
import { IJob } from 'containers/ListIndexView/TalentList/JobsList/types'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanyListBlueprint } from 'utils/types'

import ActivityWidget from './ActivityWidget'

interface Props {
  job: IJob
  companyList: CompanyListBlueprint
  refetchJob: () => void
}

const JobsDetailPage: React.FC<Props> = ({ job, companyList, refetchJob }) => {
  const teamSlug = useTeamSlug()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const { showModal } = useModal()
  const history = useHistory()
  const showIntro = useSearchParam('intro')
  const facilitatorKey = useSearchParam('facilitator_key')

  const descriptionLength = job.description?.length || 0
  const [isExpanded, setIsExpanded] = useState(descriptionLength < 400 || false)
  const description = isExpanded ? job.description : `${job.description?.slice(0, 400)}...`
  const [memberView, setMemberView] = useState(false)

  const [parent] = useAutoAnimate()
  const [filter, setFilter] = useState<string>('')
  const showAbout = filter === 'about' || filter === ''
  const showDetail = filter === 'detail' || filter === ''

  const { list_id } = useParams<{ list_id: string }>()

  useEffect(() => {
    if (!isAdminOrEmployee && showIntro) {
      openRequestIntroModal()
      history.replace({ search: '' })
    }
  }, [])

  const parseJobUrl = (url: string) => {
    if (!url) return ''

    if (!url.match(/^[a-zA-Z]+:\/\//)) {
      url = `https://${url}`
    }

    try {
      const parsedUrl = new URL(url)
      return parsedUrl.href
    } catch {
      // If URL parsing fails, it might be because the protocol is missing
      return url
    }
  }

  const filterOptions = [
    { label: 'All', value: '' },
    { label: 'About', value: 'about', icon: 'far fa-user' },
    { label: 'Details', value: 'detail', icon: 'far fa-list-ol' },
  ]

  const openRequestIntroModal = () => {
    showModal(
      (resolve) => (
        <FacilitateIntroModal
          requestable={job}
          companyList={companyList}
          resolve={resolve}
          gatekeeperId={companyList?.gatekeeper_id}
          facilitator={companyList?.owning_team}
          includeInitialNote={false}
          networkingOnly={false}
          facilitatorKey={facilitatorKey}
        />
      ),
      'facilitate-intro-modal',
    )
  }

  const { mutate: bulkAction } = useMutation(
    (params: any) =>
      callApi(api.jobsBulkActions, companyList.uuid, {
        selected_jobs_uuids: [job.uuid],
        bulk_action: params.action,
        kind: params.kind,
      }),
    {
      onSuccess: (_, params) => {
        refetchJob()
        cabalToast({ style: 'success', content: `Successfully ${params.action}d!` })
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Something went wrong, please try again or contact support',
        })
      },
    },
  )

  const logoUrl = () => {
    if (!job.investor_company) return undefined

    if (job.investor_company.logo_url) {
      return job.investor_company.logo_url
    } else if (job.investor_company.domain) {
      return `https://logo.clearbit.com/${job.investor_company.domain}`
    }

    return undefined
  }

  return (
    <PageWrapper title={job.title}>
      <DetailPageHeader
        name={job.title}
        logo={logoUrl()}
        description={job.investor_company.company_name}
        cta={
          <>
            {(!isAdminOrEmployee || memberView) && (
              <CabalButton variant="primary" onClick={() => openRequestIntroModal()}>
                Request Intro
              </CabalButton>
            )}
            {isAdminOrEmployee && !memberView && (
              <div>
                <CabalButton
                  leftIcon={<i className="far fa-pen"></i>}
                  onClick={() =>
                    showModal(
                      (resolve) => (
                        <JobsEditOrAddModal
                          onHide={resolve}
                          jobUuid={job.uuid!}
                          teamSlug={teamSlug}
                          refetchListItems={() => refetchJob()}
                          listUuids={job.list_uuids}
                          currentListUuid={companyList.uuid}
                          company={job.investor_company}
                          showListDropdown={true}
                        />
                      ),
                      'edit-job-modal',
                    )
                  }
                  variant="link"
                >
                  Edit
                </CabalButton>
                <CabalButton
                  leftIcon={<i className="far fa-box-archive"></i>}
                  onClick={() =>
                    bulkAction({ action: job.archived ? 'restore' : 'archive', kind: 'jobs' })
                  }
                  variant="link"
                >
                  {job.archived ? 'Restore' : 'Archive'}
                </CabalButton>
              </div>
            )}
          </>
        }
      />

      <DetailPageFilters
        filterOptions={filterOptions}
        filter={filter}
        onSetFilter={setFilter}
        memberView={memberView}
        setMemberView={setMemberView}
      />
      <div className="mt-10 flex gap-4">
        <div ref={parent} className="flex flex-col flex-1 gap-8 max-w-[630px]">
          {showAbout && (
            <DetailPageAboutSection
              description={
                <div>
                  <p className="leading-5 mb-2">
                    <Typography
                      component="span"
                      color="fog"
                      fontSize="12"
                      dangerouslySetInnerHTML={{
                        __html: sanitize(description || ''),
                      }}
                    />

                    {(job.description?.length ?? 0) > 400 && (
                      <SeeMore
                        className="mt-1 cursor-pointer"
                        color="purple"
                        fontSize="12"
                        lineHeight={1.2}
                        onClick={() => setIsExpanded(!isExpanded)}
                      >
                        {isExpanded ? 'See less' : 'See more'}
                        {isExpanded ? (
                          <i className="far fa-chevron-up fa-xs ml-1"></i>
                        ) : (
                          <i className="far fa-chevron-down fa-xs ml-1"></i>
                        )}
                      </SeeMore>
                    )}
                  </p>
                  {job.url && (
                    <div className="flex mt-4 w-full">
                      <CabalButton
                        variant="tertiary"
                        onClick={() => window.open(parseJobUrl(job.url), '_blank', 'noreferrer')}
                        leftIcon={<i className="far fa-arrow-up-right-from-square"></i>}
                        padding="8px 0"
                      >
                        View more
                      </CabalButton>
                    </div>
                  )}
                </div>
              }
            />
          )}

          {showDetail && (
            <DetailPageDetailsSection
              locations={job.locations}
              office={job.office_location}
              tags={job.tags}
            />
          )}
        </div>
        <div className="w-80 flex-shrink-0 flex flex-col gap-4">
          <ActivityWidget advisorUuid={''} />
        </div>
      </div>
    </PageWrapper>
  )
}

export default JobsDetailPage
