import React from 'react'

import Typography from 'global/Typography'

export type CTAOption = {
  label: React.ReactNode
  onClick: () => void
}

interface Props {
  CTAOptions: CTAOption[]
  searchComp?: React.ReactNode
}

const CTAs = ({ CTAOptions, searchComp }: Props) => {
  return (
    <div className="flex items-center justify-center gap-6">
      {searchComp}
      {CTAOptions.map((c, i) => {
        return (
          <Typography
            className="cursor-pointer"
            color="purple"
            fontSize="12"
            key={i}
            onClick={c.onClick}
          >
            {c.label}
          </Typography>
        )
      })}
    </div>
  )
}

export default CTAs
