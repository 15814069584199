import Cookies from 'js-cookie'

export const setJobIntroRequestCookie = (data: {
  team_slug: string
  facilitator: { uuid: string }
  secret_uuid: string
}) => {
  Cookies.set(
    `latest_non_advisor_job_intro_request`,
    JSON.stringify({
      company_slug: data.team_slug,
      facilitator_user_uuid: data.facilitator.uuid,
      secret_uuid: data.secret_uuid,
    }),
  )
}
