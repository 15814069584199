import React from 'react'

import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import CandidateProfileModal from 'containers/CandidateProfileModal'
import CandidateDataTags from 'containers/CompanyPage/SharedListsSection/CandidateDataTags'
import { usePeopleListContext } from 'containers/ListIndexView/TalentList/PeopleList'
import { ITalentPeople } from 'containers/ListIndexView/TalentList/type'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'

const TalentPerson = ({
  person,
  selectedPeople,
  setSelectedPeople,
  view,
}: {
  person: ITalentPeople
  selectedPeople: ITalentPeople[]
  setSelectedPeople: (selectedPeople: ITalentPeople[]) => void
  view: 'grid' | 'list'
}) => {
  const history = useHistory()
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()

  const { companyListData, refetchPeople } = usePeopleListContext()
  const { candidate_profile: candidateProfile } = person
  const companyListSlug = companyListData.slug

  const selected = !!selectedPeople.find((p) => p.uuid === person.uuid)

  const handleSelection = (person: ITalentPeople, selected: boolean) => {
    if (selected) {
      setSelectedPeople([...selectedPeople, person])
    } else {
      setSelectedPeople(selectedPeople.filter((a) => a.uuid !== person.uuid))
    }
  }

  const { mutate: deleteResourceItem, isLoading: isDeleting } = useMutation(
    () => api.removeListItems(teamSlug, companyListData.uuid, [person.uuid]),
    {
      onSuccess: () => {
        refetchPeople?.()
        cabalToast({ style: 'success', content: 'Item has been removed from the list' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to delete item' })
      },
    },
  )

  const { mutate: bulkAction } = useMutation(
    (params: any) =>
      callApi(api.candidatesBulkActions, {
        list_uuid: companyListData.uuid,
        selected_candidates_uuids: [candidateProfile.uuid],
        bulk_action: params.action,
        kind: params.kind,
      }),
    {
      onSuccess: (_, params) => {
        refetchPeople?.()
        cabalToast({ style: 'success', content: `Successfully ${params.action}d!` })
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Something went wrong, please try again or contact support',
        })
      },
    },
  )

  const menuItems = [
    {
      label: 'Edit',
      onSelect: () => {
        showModal(
          (resolve) => (
            <CandidateProfileModal
              listUuid={''}
              teamSlug={teamSlug}
              onFinalize={() => {
                refetchPeople?.()
                resolve()
              }}
              linkedinUrl={candidateProfile?.linkedin_url}
              onHide={() => resolve()}
              inPeopleList={false}
              candidateUuid={candidateProfile?.uuid}
            />
          ),
          'edit-candidate-modal',
        )
      },
    },
    {
      label: 'Delete',
      onSelect: () => {
        if (window.confirm(`Are you sure you want to delete ${person.name}?`)) {
          deleteResourceItem()
        }
      },
    },
    {
      label: candidateProfile.archived ? 'Restore' : 'Archive',
      onSelect: () =>
        bulkAction({
          action: candidateProfile.archived ? 'restore' : 'archive',
          kind: 'candidates',
        }),
    },
  ]

  const gridView = view === 'grid'
  
  return (
    <DataCardV2
      onClick={() => history.push(`/user/${person.global_person_uuid}`)}
      cta={
        <CabalButton
          onClick={() => history.push(`/user/${person.global_person_uuid}`)}
          variant={gridView ? 'primary' : 'link'}
          working={isDeleting}
          className="w-full"
        >
          {isDeleting ? 'Deleting' : 'View'}
        </CabalButton>
      }
      avatar={<Avatar src={person.logo_url} name={person.name} size={gridView ? '72' : '32'} />}
      title={person.name}
      description={person.headline}
      checkbox={{
        checked: selected,
        onChange: (e) => {
          handleSelection(person, e.target.checked)
        },
      }}
      descriptionBottom={
        <div className="flex flex-col items-start gap-2.5 mb-2">
          {candidateProfile?.company && (
            <Typography lineHeight={1}>
              <i className="far fa-building" /> {candidateProfile?.company}
            </Typography>
          )}
          {candidateProfile?.location && (
            <Typography lineHeight={1}>
              <i className="far fa-location-dot" /> {candidateProfile.location}
            </Typography>
          )}
        </div>
      }
      gridPillsSection={<CandidateDataTags company={candidateProfile} />}
      dropDownMenu={menuItems.length > 0 ? { menuItems: menuItems } : undefined}
      view={view}
      className={cn({
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded':
          !gridView,
      })}
    />
  )
}

interface Props {
  selectedPeople: any[]
  setSelectedPeople: (selectedPeople: any[]) => void
}

const PeopleListAdminView = ({ selectedPeople, setSelectedPeople }: Props) => {
  const { talentPeople, view, setView } = usePeopleListContext()

  const itemRow = (view: 'grid' | 'list') => {
    return (
      <>
        {talentPeople.map((person) => (
          <TalentPerson
            key={person.uuid}
            person={person}
            selectedPeople={selectedPeople}
            setSelectedPeople={setSelectedPeople}
            view={view}
          />
        ))}
      </>
    )
  }

  return (
    <div>
      {view === 'grid' && <GridWrapper>{itemRow('grid')}</GridWrapper>}
      {view === 'list' && <>{itemRow('list')}</>}
    </div>
  )
}

export default PeopleListAdminView
