import React from 'react'
import { useQuery } from 'react-query'
import ActivityRow from 'components/Activity/ActivityRow'
import Widget from 'ui-components/Widget'
import api, { callApi } from 'utils/api'
import { Activity } from 'utils/types'
import { useCurrentUser } from 'store/hooks'
import Typography from 'global/Typography'

interface Props {
  personUuid: string
}

const ActivityWidget: React.FC<Props> = ({ personUuid }) => {
  const {user} = useCurrentUser()
  
  const { data: activityData, isLoading } = useQuery(
    [user.team?.slug, 'getMemberActivity', personUuid],
    () => callApi(api.getMemberActivity, user.team?.slug, 'candidate_activities', personUuid)
  )

  if (isLoading) {
    return <Widget title="Activity" />
  }

  const activities = activityData?.activity || []

  debugger
  return (
    <Widget title="Activity">
      <div
        className="-mx-2 transition-opacity"
        style={{
          maxHeight: 300,
          overflow: 'auto',
          opacity: '1',
        }}
      >
        {activities.length === 0 ? (
          <div className="p-4">
            <Typography fontSize="14" color="fog">
              No activity found.
            </Typography>
          </div>
        ) : (
          activities.map((activity) => (
            <ActivityRow
              key={activity.uuid}
              style={{ backgroundColor: 'transparent' }}
              activity={activity}
              splitView={true}
              isCandidateProfilePage={true}
            />
          ))
        )}
      </div>
    </Widget>
  )
}

export default ActivityWidget
