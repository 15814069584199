import React from 'react'

import styled from 'styled-components'

import Avatar from 'global/Avatar'
import DropDownMenu from 'global/DropDownMenu'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'

const Wrapper = styled.div`
  border: ${({ theme }) => theme.border};
`

const AccessorRow = ({ accessor, className }: { accessor: any; className?: string }) => {
  return (
    <DataCardV2
      title={accessor.name}
      className={className}
      description={accessor.email}
      avatar={<Avatar name={accessor.name} src={accessor.avatar} />}
      titleIcon={
        <div className="flex items-center gap-1">
          <Typography fontSize="12" color="purple" lineHeight={1}>
            <i className="fa fa-badge-check fa-fw" />
          </Typography>
          <Typography fontSize="12" color="fog" lineHeight={1} component="p">
            <i className="fab fa-linkedin" />
          </Typography>
          <Typography fontSize="12" color="fog" lineHeight={1} component="p">
            <i className="fab fa-x-twitter" />
          </Typography>
        </div>
      }
      cta={
        <DropDownMenu
          menuItems={[
            {
              label: 'Remove',
              onSelect: () => {},
            },
          ]}
          trigger={
            <Typography color={'fog'} fontSize={'12'}>
              Can view
              <i className="fas fa-chevron-down fa-sm ml-1" />
            </Typography>
          }
        />
      }
    />
  )
}

const PeopleWithAccess = () => {
  const dummyAccessors = [
    {
      name: 'Jim Doe',
      email: 'jim@doe.com',
      linkedInUrl: 'https://www.linkedin.com/in/jimdoe/',
      twitterUrl: 'https://x.com/jimdoe',
      avatar: 'https://via.placeholder.com/32',
      uuid: '123e4567-e89b-12d3-a456-426614174000',
    },
    {
      name: 'Sarah Smith',
      email: 'sarah@smith.com',
      linkedInUrl: 'https://www.linkedin.com/in/sarahsmith/',
      twitterUrl: 'https://x.com/sarahsmith',
      avatar: 'https://via.placeholder.com/32',
      uuid: '123e4567-e89b-12d3-a456-426614174001',
    },
    {
      name: 'Mike Johnson',
      email: 'mike@johnson.com',
      linkedInUrl: 'https://www.linkedin.com/in/mikejohnson/',
      twitterUrl: 'https://x.com/mikejohnson',
      avatar: 'https://via.placeholder.com/32',
      uuid: '123e4567-e89b-12d3-a456-426614174002',
    },
  ]

  return (
    <div className="flex flex-col gap-2 mt-2">
      <Typography fontSize="12" fontWeight={600}>
        People, groups or teams with access
      </Typography>
      <Wrapper className="rounded-lg">
        <DataCardV2
          className="rounded-t-lg"
          title="John Doe"
          description="jim@doe.com"
          avatar={<Avatar name="John Doe" src="https://via.placeholder.com/32" />}
          titleIcon={
            <div className="flex items-center gap-1">
              <Typography fontSize="12" color="purple" lineHeight={1}>
                <i className="fa fa-badge-check fa-fw" />
              </Typography>
              <Typography fontSize="12" color="fog" lineHeight={1} component="p">
                <i className="fab fa-linkedin" />
              </Typography>
              <Typography fontSize="12" color="fog" lineHeight={1} component="p">
                <i className="fab fa-x-twitter" />
              </Typography>
            </div>
          }
          cta={
            <Typography color={'fog'} fontSize="12">
              Owner
            </Typography>
          }
        />
        {dummyAccessors.map((accessor, index) => (
          <AccessorRow
            key={accessor.uuid}
            accessor={accessor}
            className={index === dummyAccessors.length - 1 ? 'rounded-b-lg' : ''}
          />
        ))}
      </Wrapper>
    </div>
  )
}

export default PeopleWithAccess
