import React from 'react'

import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import PortfolioList from 'containers/ListIndexView/PortfolioList'
import ResourcesList from 'containers/ListIndexView/ResourcesList'
import SalesList from 'containers/ListIndexView/SalesList'
import TalentList from 'containers/ListIndexView/TalentList'
import { useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'

const ListIndexView = () => {
  const { list_id } = useParams<{ list_id: string }>()
  const teamSlug = useTeamSlug()

  const {
    data: listData,
    isLoading: listLoading,
    refetch: refetchCompanyList,
  } = useQuery(
    ['getCompanyList', teamSlug, list_id],
    () => callApi(api.getCompanyList, teamSlug, list_id, false, false),
    {
      enabled: !['connections', 'social', 'team-connections'].includes(list_id),
    },
  )

  const companyListData = listData?.company_list

  // List Type should be used to distinguish between lists? portfolio and talent should be their own list type?
  if (listLoading || !companyListData) {
    return null
  }

  const isPortfolioList = companyListData.portfolio
  const isTalentList = companyListData && companyListData.list_category === 'talent'
  const isSalesList =
    companyListData.list_category === 'sales' || companyListData.list_category == 'fundraise'
  const isResourceList = companyListData.list_category === 'resources'

  return (
    <div>
      {isPortfolioList && (
        <PortfolioList companyListData={companyListData} refetchCompanyList={refetchCompanyList} />
      )}
      {isSalesList && (
        <SalesList companyListData={companyListData} refetchCompanyList={refetchCompanyList} />
      )}
      {isResourceList && (
        <ResourcesList companyListData={companyListData} refetchCompanyList={refetchCompanyList} />
      )}
      {isTalentList && (
        <TalentList companyListData={companyListData} refetchCompanyList={refetchCompanyList} />
      )}
    </div>
  )
}

export default ListIndexView
