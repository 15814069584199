import React, { useEffect, useRef, useState } from 'react'

import { useQuery } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import { CheckBox, TextInput } from 'global/Input'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import { SavedSearch } from 'utils/types/savedSearch'

const DropdownContainer = styled.div`
  ${tw`absolute mt-2 rounded-md shadow-lg z-50`}
  min-width: 16rem;
  width: auto;
  top: 100%;
  left: 0;
  background-color: ${({ theme }) => theme.colors.shadow};
  border: 1px solid ${({ theme }) => theme.colors.border};
`

const formatSearchName = (slug: any): string => {
  if (!slug) return ''

  if (Array.isArray(slug)) {
    const filterNames = slug
      .map((filter) => {
        if (filter.props?.children?.[0]) {
          return typeof filter.props.children[0] === 'string' ? filter.props.children[0] : ''
        }
        return ''
      })
      .filter(Boolean)

    if (filterNames.length === 0) return ''
    if (filterNames.length === 1) return filterNames[0]
    if (filterNames.length === 2) return `${filterNames[0]}, ${filterNames[1]}`

    const remainingCount = filterNames.length - 2
    return `${filterNames[0]}, ${filterNames[1]} + ${remainingCount} more`
  }

  return String(slug)
}

interface SavedSearchModalProps {
  isOpen: boolean
  onClose: () => void
  searchName: string
  setSearchName: (name: string) => void
  emailFrequency: string
  setEmailFrequency: (frequency: string) => void
  onSave: () => void
  onDelete?: () => void
  isEdit?: boolean
  filters?: React.ReactNode[]
  handleSelectSearch?: (search: SavedSearch | null) => void
  savedSearch?: SavedSearch | null
}

const SavedSearchModal = ({
  isOpen,
  onClose,
  searchName,
  setSearchName,
  emailFrequency,
  setEmailFrequency,
  onSave,
  onDelete,
  isEdit,
  filters,
  handleSelectSearch,
  savedSearch,
}: SavedSearchModalProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLDivElement>(null)
  const [emailFrequencies, setEmailFrequencies] = useState<string[]>([])

  useQuery({
    queryKey: ['emailFrequencies'],
    queryFn: () => api.V2getEmailFrequencies(),
    onSuccess: (res) => {
      setEmailFrequencies(res.data.email_frequencies)
      setEmailFrequency(res.data.email_frequencies[0])
    },
    onError: () => {
      cabalToast({ style: 'error', content: 'Failed to fetch email frequency.' })
    },
  })

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target instanceof Element)) return

      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside, true)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true)
    }
  }, [isOpen, onClose])

  useEffect(() => {
    if (isOpen && dropdownRef.current && triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect()
      dropdownRef.current.style.setProperty('--modal-left-position', `${triggerRect.left}px`)
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <DropdownContainer ref={dropdownRef}>
      <div className="p-3 space-y-4">
        <div>
          <Typography fontSize="12" color="fog" className="mb-1">
            Name your search
          </Typography>
          <TextInput
            className="w-full"
            type="text"
            value={searchName || setSearchName(formatSearchName(filters))}
            onChange={(e) => {
              setSearchName(e.target.value)
            }}
            placeholder="Enter search name"
          />
        </div>

        <div>
          <Typography fontSize="12" color="fog" className="mb-2">
            Email notifications
          </Typography>
          <div className="space-y-2">
            {emailFrequencies?.map((frequency) => (
              <label key={frequency} className="flex items-center gap-2 cursor-pointer">
                <CheckBox
                  type="radio"
                  checked={emailFrequency === frequency}
                  onChange={() => setEmailFrequency(frequency)}
                />
                <Typography fontSize="14">{frequency}</Typography>
              </label>
            ))}
          </div>
        </div>

        {isEdit && onDelete && (
          <div>
            <Typography
              fontSize="12"
              color="red"
              className="cursor-pointer hover:underline"
              onClick={onDelete}
            >
              <i className="far fa-trash-alt mr-1" />
              Delete saved search
            </Typography>
          </div>
        )}

        <div className="flex gap-2">
          <CabalButton onClick={onClose} variant="secondary" className="w-1/2">
            <Typography fontSize="14">Cancel</Typography>
          </CabalButton>

          <CabalButton
            onClick={() => {
              onSave()
              onClose()
            }}
            variant="primary"
            className="w-1/2"
          >
            <Typography fontSize="14">Save</Typography>
          </CabalButton>
        </div>
      </div>
    </DropdownContainer>
  )
}

export default SavedSearchModal
