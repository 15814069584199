import React, { useState } from 'react'

import { useMutation } from 'react-query'

import OfferRow from 'containers/CompanyPage/OfferIntroModal/OfferRow'
import { ISalesCompany } from 'containers/ListIndexView/SalesList/type'
import CabalButton from 'global/CabalButton'
import { SearchInput, TextArea } from 'global/Input'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { VoteType } from 'utils/types'

interface IProps {
  resolve: () => void
  company: ISalesCompany
  connections: any
  teamSlug: string
  listUuid: string
}

const OfferIntroListModal = ({ resolve, company, connections, teamSlug, listUuid }: IProps) => {
  const [note, setNote] = useState('')
  const [search, setSearch] = useState('')
  const [selectedPeople, setSelectedPeople] = useState<number[]>([])

  const handleOffer = () => {
    if (selectedPeople.length === 0 && note !== '') {
      sendVote([1, undefined])
    }

    // Person votes
    if (selectedPeople.length > 0) {
      selectedPeople.forEach((person_id) => {
        sendVote([1, person_id])
      })
    }

    cabalToast({
      content: `Your offer has been logged. ${company.team_name} will follow up with you via email. `,
      style: 'success',
    })

    resolve()
  }

  const { mutate: sendVote, isLoading: sendingVote } = useMutation<
    any,
    Error,
    [VoteType, number | undefined]
  >(['createVote'], async ([vote, person_id]: [VoteType, number | undefined]) =>
    callApi(api.createVote, {
      team_slug: teamSlug,
      votable_type: person_id ? 'GlobalPerson' : 'Company',
      votable_id: person_id || company.id,
      vote,
      company_id: company.id,
      list_uuid: listUuid,
      notes: note,
    }),
  )

  return (
    <Modal
      header={`Offer help with ${company.name}`}
      show={true}
      onHide={resolve}
      rightActions={
        <CabalButton
          disabled={selectedPeople.length === 0 && note === ''}
          working={sendingVote}
          onClick={handleOffer}
        >
          Offer help {selectedPeople.length > 0 ? `(${selectedPeople.length})` : ''}
        </CabalButton>
      }
    >
      <div className="flex flex-col gap-1 mb-4">
        <Typography fontSize="12">Add a note on how you can help with {company.name}</Typography>
        <TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Add context for your offer to help"
          className="w-full"
          rows={4}
        />
      </div>

      {connections.length > 0 && (
        <div className="flex flex-col">
          <div className="flex flex-col gap-1">
            <Typography fontSize="12">Select {company.name} team members</Typography>
            <SearchInput
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={`Find ${company.name} members`}
            />
          </div>

          <div className="flex flex-col mt-2 max-h-[330px] overflow-y-auto">
            {connections.length > 0 &&
              connections.map((connection: any) => (
                <OfferRow
                  key={connection.id}
                  person={connection}
                  selected={selectedPeople.includes(connection.uuid)}
                  connected={true}
                  onSelect={() => {
                    setSelectedPeople((prev) =>
                      prev.includes(connection.uuid)
                        ? prev.filter((id) => id !== connection.uuid)
                        : [...prev, connection.uuid],
                    )
                  }}
                />
              ))}
          </div>
        </div>
      )}
    </Modal>
  )
}

export default OfferIntroListModal
