import React from 'react'

import { useMutation } from 'react-query'

import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import ResourceDataTags from 'containers/ListIndexView/ResourcesList/ResourceDataTags'
import AddResourcePersonModal from 'containers/ListIndexView/ResourcesList/ResourcePeopleList/ResourcePeopleAddWidget/AddResourcePersonModal'
import { IResourcePeople } from 'containers/ListIndexView/ResourcesList/types'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'
import { useHistory } from 'react-router-dom'

interface Props {
  selectedPeople: IResourcePeople[]
  setSelectedPeople: (people: IResourcePeople[]) => void
}

const ResourceItem = ({
  item,
  view,
  selectedItems,
  setSelectedItems,
}: {
  item: IResourcePeople
  view: 'grid' | 'list'
  selectedItems: IResourcePeople[]
  setSelectedItems: (people: IResourcePeople[]) => void
}) => {
  const history = useHistory()
  const selected = !!selectedItems.find((i) => i.uuid === item.uuid)
  const gridView = view === 'grid'
  const { companyListData, refetchResourceList, tags, refetchTags } = useResourcesListContext()
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()

  const { mutate: deleteResourceItem, isLoading: isDeleting } = useMutation(
    () => api.removeListItems(teamSlug, companyListData.uuid, [item.uuid]),
    {
      onSuccess: () => {
        refetchResourceList()
        cabalToast({ style: 'success', content: 'Item has been removed from the list' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to delete item' })
      },
    },
  )

  const { mutate: exportCompanyData } = useMutation(
    () => callApi(api.exportResourcePeopleList, [item.uuid], companyListData.uuid),
    {
      onSuccess: () => {
        cabalToast({
          style: 'success',
          content: 'Company data export initiated. Check your email for the file.',
        })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to export company data.' })
      },
    },
  )

  const { mutate: addListItem } = useMutation(
    ({
      personUuid,
      resourcePerson,
    }: {
      personUuid?: string
      resourcePerson?: {
        first_name: string
        last_name: string
        email: string
        linkedin_url: string
        avatar: string
        location: string
        title: string
        company: string
        about: string
        tags?: string[]
      }
    }) => api.addElementToList(companyListData.uuid, resourcePerson, personUuid),
    {
      onSuccess: () => {
        refetchResourceList()
        refetchTags()
        cabalToast({ style: 'success', content: 'Changes Saved' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to add item' })
      },
    },
  )

  const menuItems = [
    {
      label: 'Export',
      onSelect: () => exportCompanyData(),
    },
    {
      label: 'Edit',
      onSelect: () => {
        showModal(
          (resolve) => (
            <AddResourcePersonModal
              onHide={resolve}
              itemUuid={item.uuid}
              tags={tags}
              onSave={(personUuid?: string, resourcePerson) => {
                addListItem({ personUuid, resourcePerson })
              }}
            />
          ),
          'add-person-modal',
        )
      },
    },
    {
      label: 'Delete',
      onSelect: () => {
        if (window.confirm(`Are you sure you want to delete ${item.name}?`)) {
          deleteResourceItem()
        }
      },
    },
  ]

  const handleSelection = (item: IResourcePeople, selected: boolean) => {
    if (selected) {
      setSelectedItems([...selectedItems, item])
    } else {
      setSelectedItems(selectedItems.filter((a) => a.uuid !== item.uuid))
    }
  }

  return (
    <DataCardV2
      title={item.name}
      avatar={
        <Avatar src={item.avatar || item.logo_url} name={item.name} size={gridView ? '72' : '32'} />
      }
      checkbox={{
        checked: selected,
        onChange: (e) => {
          handleSelection(item, e.target.checked)
        },
      }}
      description={item.position || item.description || item.about}
      descriptionBottom={
        (item.company_name || item.location) && (
          <div className="flex flex-col items-start gap-2.5 mb-2">
            {item.company_name && (
              <Typography lineHeight={1}>
                <i className="far fa-building" /> {item.company_name}
              </Typography>
            )}

            {item.location && (
              <Typography lineHeight={1}>
                <i className="far fa-location-dot" /> {item.location}
              </Typography>
            )}
          </div>
        )
      }
      dropDownMenu={menuItems.length > 0 ? { menuItems: menuItems } : undefined}
      cta={
        <CabalButton
          onClick={() => history.push(`/user/${item.global_person_uuid}`)}
          variant={gridView ? 'primary' : 'link'}
          working={isDeleting}
          className="w-full"
        >
          {isDeleting ? 'Deleting' : 'View'}
        </CabalButton>
      }
      view={view}
      gridPillsSection={<ResourceDataTags tags={item.tags} />}
      className={cn({
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded':
          !gridView,
      })}
    />
  )
}

const ResourcePeopleAdminView: React.FC<Props> = ({ selectedPeople, setSelectedPeople }) => {
  const { view, resourcePeople } = useResourcesListContext()
  const itemRow = (view: 'grid' | 'list') => {
    return (
      <>
        {resourcePeople.map((person) => (
          <ResourceItem
            key={person.uuid}
            item={person}
            view={view}
            selectedItems={selectedPeople}
            setSelectedItems={setSelectedPeople}
          />
        ))}
      </>
    )
  }

  return (
    <div>
      {view === 'grid' && <GridWrapper>{itemRow('grid')}</GridWrapper>}
      {view === 'list' && <>{itemRow('list')}</>}
    </div>
  )
}

export default ResourcePeopleAdminView
