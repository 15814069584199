import axios from 'axios'

import { SavedSearch } from 'utils/types/savedSearch'

export default {
  V2createSavedSearch: (params: { saved_search: SavedSearch }) => {
    return axios.post('/api/v2/saved_searches', params)
  },
  V2updateSavedSearch: (uuid: string, saved_search: SavedSearch) => {
    return axios.put(`/api/v2/saved_searches/${uuid}`, saved_search)
  },
  V2destroySavedSearch: (uuid: string) => {
    return axios.delete(`/api/v2/saved_searches/${uuid}`)
  },
  V2showSavedSearch: (uuid: string) => {
    return axios.get(`/api/v2/saved_searches/${uuid}`)
  },
  V2indexSavedSearches: (params: { saved_search: SavedSearch }) => {
    return axios.get(`/api/v2/saved_searches`, { params })
  },
  V2getEmailFrequencies: () => {
    return axios.get(`/api/v2/saved_searches/email_frequencies`)
  },
}
