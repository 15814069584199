import React, { HTMLProps } from 'react'

import { Merge, SetOptional } from 'type-fest'

import { DropDownProps } from 'global/DropDownMenu'
import { CheckBox, CheckBoxProps } from 'global/Input'
import Typography from 'global/Typography'

import { cn } from 'utils/styles'

import CTA from './CTA'
import CardHeader from './CardHeader'
import CardInfo from './CardInfo'
import PrimaryInfo from './PrimaryInfo'

export type UpdatedDropDownProps = SetOptional<DropDownProps, 'trigger'>

interface DataCardType {
  title: React.ReactNode
  titleIcon?: React.ReactNode
  description?: React.ReactNode
  descriptionBottom?: React.ReactNode
  descriptionWithoutTruncate?: React.ReactNode
  avatar?: React.ReactNode
  onClick?: () => void
  checkbox?: Merge<CheckBoxProps, { dataTestId?: string }>
  dropDownMenu?: UpdatedDropDownProps
  isLoading?: boolean
  topRightData?: React.ReactNode
  cta?: React.ReactNode
  view?: 'list' | 'grid'
  gridPillsSection?: React.ReactNode
  scrollTitleOnHover?: boolean
}

export type DataCardV2Props = DataCardType & Omit<HTMLProps<HTMLElement>, 'ref' | 'title'>

const DataCardV2: React.FC<DataCardV2Props> = ({
  title,
  description,
  descriptionBottom,
  descriptionWithoutTruncate,
  avatar,
  onClick,
  titleIcon,
  checkbox,
  dropDownMenu,
  isLoading,
  cta,
  className,
  view = 'list',
  gridPillsSection,
  topRightData,
  scrollTitleOnHover = false,
  ...restProps
}) => {
  const props: Omit<HTMLProps<HTMLElement>, 'ref' | 'type'> = {
    className: cn(
      'flex w-full',
      {
        'h-[54px] items-center justify-between p-2.5 hover:bg-[#f1f1f2] dark:hover:bg-[#1b232f]':
          view === 'list',
      },
      {
        'min-h-[186px] flex-col justify-end p-3.5 transition-all duration-200 hover:bg-[#f1f1f2] dark:hover:bg-[#1b232f] dark:border-[#2d3748] border-[#E3E5E8] border-[1px] rounded group/title':
          view === 'grid',
      },
      { 'cursor-pointer rounded-md': !!onClick },
      className,
    ),
    ...restProps,
    children:
      view === 'list' ? (
        <>
          <div className="flex space-x-2.5 truncate">
            {checkbox && (
              <Typography className="flex" onClick={(e) => onClick && e.stopPropagation()}>
                <CheckBox
                  data-testId={checkbox.dataTestId}
                  checked={checkbox.checked}
                  onChange={checkbox.onChange}
                  className="cursor-pointer"
                />
              </Typography>
            )}
            <PrimaryInfo
              title={title}
              description={description}
              avatar={avatar}
              titleIcon={titleIcon}
              scrollOnHover={scrollTitleOnHover}
            />
          </div>
          <CTA
            dropDownMenu={dropDownMenu}
            cta={cta}
            onClick={(e) => onClick && e.stopPropagation()}
          />
        </>
      ) : (
        <>
          <div className="w-full h-full flex flex-col relative">
            <CardHeader avatar={avatar} dropDownMenu={dropDownMenu} topRightData={topRightData} />
            <CardInfo
              title={title}
              titleIcon={titleIcon}
              description={description}
              descriptionBottom={descriptionBottom}
              descriptionWithoutTruncate={descriptionWithoutTruncate}
              gridPillsSection={gridPillsSection}
              cta={cta}
            />
          </div>
        </>
      ),
  }

  if (onClick) {
    return <button data-testid={'data-card-wrapper'} {...props} onClick={onClick} />
  }

  return <div data-testid={'data-card-wrapper'} {...props} />
}

export default React.memo(DataCardV2)
