import * as React from 'react'

import axios from 'axios'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { FooterWrapper } from 'containers/App/styles'
import Avatar from 'global/Avatar'
import DropDownMenu from 'global/DropDownMenu'
import Typography from 'global/Typography'
import { useCurrentUser, useTeams } from 'store/hooks'

import profileApi from 'utils/api/profile'
import team from 'utils/api/team'
import useColorMode from 'utils/hooks/useColorMode'
import { useSlug } from 'utils/hooks/useSlug'
import { CurrentUserProfile, Team } from 'utils/types'

export const Wrapper = styled.div`
  &:hover {
    color: ${({ theme }) => theme.side_nav.active_item_text_color};
  }
`

const getCandidateSlugAndUuid = (user: CurrentUserProfile) => {
  const advisors = user?.advisors
  const advisor = advisors?.find((advisor) => advisor?.candidate_uuid)
  return { candidate_slug: advisor?.team_slug, candidate_uuid: advisor?.candidate_uuid }
}

const UserDropDown = () => {
  const { user } = useCurrentUser()
  const { theme, name, avatar_url, email, user_emails, flags, has_synced_gcontacts, permissions } =
    user
  const { teams } = useTeams()
  const history = useHistory()
  const { toggleTheme } = useColorMode()
  const prodImpersonating = window.II && !window.IS_DEV
  const menuItems = []
  const { canUseExtensionConnections } = permissions || {}
  const { userSlug } = useSlug()

  const { candidate_slug, candidate_uuid } = getCandidateSlugAndUuid(user)

  const switchAccount = (email: string) => {
    profileApi.switchAccount(email).then(({ data }) => {
      if (data.profile) {
        location.assign('/')
      }
    })
  }

  menuItems.push({
    label: (
      <Typography fontSize="14">
        <i className="far fa-user fa-fw mr-3"></i>
        <span>
          {'My Profile'} <br />
          <span className="text-xs" style={{ marginLeft: 30 }}>
            {email}
          </span>
        </span>
      </Typography>
    ),
    onSelect: () => (userSlug ? history.push(`/${userSlug}`) : history.push('/profile')),
  })

  menuItems.push({
    label: (
      <Typography fontSize="14">
        <i className="far fa-sliders fa-fw mr-3"></i>
        <span>{'Add Connections'}</span>
      </Typography>
    ),
    onSelect: () => history.push('/addconnections'),
  })
  
  if (!!candidate_slug && !!candidate_uuid) {
    menuItems.push({
      label: (
        <Typography fontSize="14" component="div">
          <i className="far fa-user-tag fa-fw mr-3"></i>
          {'Profile'}
        </Typography>
      ),
      onSelect: () =>
        user.global_person_uuid
          ? history.push(`/user/${user.global_person_uuid}`)
          : history.push(`/${candidate_slug}/candidates/${candidate_uuid}`),
    })
  }

  menuItems.push({
    label: (
      <Typography fontSize="14" component="div">
        <i className="far fa-adjust fa-fw mr-3"></i>
        {theme == 'light' ? 'Dark Mode (beta)' : 'Light Mode'}
      </Typography>
    ),
    onSelect: () => toggleTheme(),
  })

  if (canUseExtensionConnections) {
    menuItems.push({
      label: (
        <Typography fontSize="14" component="div">
          <i className="far fa-puzzle-piece fa-fw mr-3"></i>
          {'Chrome Extension'}
        </Typography>
      ),
      onSelect: () =>
        window.open(`/api/redirects?to=chrome-extension&from=${window.location.pathname}`),
    })
  }

  menuItems.push({
    label: (
      <Typography fontSize="14" component="div" id="intercom-launcher">
        <i className="far fa-comment fa-fw mr-3"></i>
        {'Help'}
      </Typography>
    ),
    onSelect: () => window.Intercom('show'),
  })

  menuItems.push({
    label: (
      <Typography fontSize="14" component="div">
        <i className="far fa-sign-out fa-fw mr-3"></i>
        {'Logout'}
      </Typography>
    ),
    onSelect: () => {
      if (location.pathname.includes('deal_board')) {
        localStorage.setItem('postLoginReturnTo', location.pathname)
      }
      window.location.assign('/logout')
    },
  })

  if (window.SUPERADMIN) {
    menuItems.push({
      label: (
        <Typography fontSize="14" component="div">
          <i className="far fa-user-crown fa-fw mr-3"></i>
          {'Super Admin'}
        </Typography>
      ),
      onSelect: () => (window.location.href = '/superadmin'),
    })

    if (!window.II && window.SUPERADMIN) {
      menuItems.push({
        label: (
          <Typography fontSize="14" component="div">
            <i className="far fa-bolt fa-fw mr-3"></i>
            {'Reset Connections'}
          </Typography>
        ),
        onSelect: () => {
          if (confirm('Are you sure you want to reset your connections?'))
            profileApi.resetConnections().then(({ data }) => {
              document.location.reload()
            })
        },
      })
    }
  }

  if (window.II) {
    menuItems.push({
      label: (
        <Typography fontSize="14" component="div">
          <i className="far fa-ghost fa-fw mr-3"></i>
          {'Exit test mode'}
        </Typography>
      ),
      onSelect: () => {
        axios.post('/superadmin/users/stop_impersonating').then(() => {
          location.assign('/')
        })
      },
    })
  }

  user_emails?.map((secondaryEmail) => {
    if (email != secondaryEmail)
      menuItems.push({
        label: (
          <Typography fontSize="14" component="div">
            <i className="far fa-repeat fa-fw mr-3"></i>
            {`Switch Account: ${secondaryEmail}`}
          </Typography>
        ),
        onSelect: () => switchAccount(secondaryEmail),
      })
  })

  return (
    <Wrapper className="flex flex-shrink-0 flex-col">
      <DropDownMenu
        portal={false}
        trigger={
          <FooterWrapper className="flex items-center rounded-full px-2 py-1.5 w-full truncate">
            <Avatar name={name} src={avatar_url} size={'24'} round className="flex-shrink-0 mr-2" />
            <div className="text-left flex items-center justify-between w-full truncate">
              {/* <div className="truncate">
                <Typography
                  component="p"
                  fontSize="12"
                  lineHeight="1.2"
                  fontWeight={600}
                  className="truncate"
                  color={prodImpersonating ? 'red' : ''}
                >
                  {name}
                </Typography>
                <Typography
                  component="p"
                  fontSize="12"
                  lineHeight="1.2"
                  fontWeight={400}
                  className="truncate"
                  color={prodImpersonating ? 'red' : ''}
                >
                  {email}
                </Typography>
              </div> */}
              <Typography component="p" className="ml-1" color={'fog'}>
                <i className="fal fa-angle-down fa-sm"></i>
              </Typography>
            </div>
          </FooterWrapper>
        }
        fullWidth={true}
        menuItems={menuItems}
      />
    </Wrapper>
  )
}

export default UserDropDown
