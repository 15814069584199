import React from 'react'

import { useQuery } from 'react-query'

import { useAccessControl } from 'global/AccessControl'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import PillV2 from 'ui-components/PillV2'

import api, { callApi } from 'utils/api'
import useTeamSetting from 'utils/hooks/useTeamSetting'
import { CompanyListBlueprint } from 'utils/types'
import { InvestorCompany } from 'utils/types/investor'

interface Props {
  // onEditProfile?: () => void
  // reloadProfile: () => void
  refetch: () => void
  companyUuid: string
  teamSlug: string
  filter: string
  onSetFilter: (filter: string) => void
  hideConnections?: boolean
  company: InvestorCompany
  companyList: CompanyListBlueprint
}

const PortfolioFilters: React.FC<Props> = ({
  // onEditProfile,
  // reloadProfile,
  refetch,
  companyUuid,
  teamSlug,
  filter,
  onSetFilter,
  hideConnections,
  company,
  companyList,
}) => {
  const { canViewPortfolio } = useAccessControl(teamSlug)
  const { showModal } = useModal()
  const { user } = useCurrentUser()

  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  let filters = {
    portfolio_company_uuids: [company.uuid],
  }
  if (!isAdminOrEmployee) {
    filters = {
      ...filters,
      visibility: 'team_and_members',
    }
  }
  const { data } = useQuery(['getCompanyJobs', company.uuid], () =>
    callApi(api.getJobsV2, teamSlug, null, 1, filters),
  )

  const showingJobs = data?.items?.map((c: CompanyListBlueprint) => c.job)
  const owningTeam = user?.team?.slug === teamSlug

  return (
    <div className="flex justify-between items-center mt-6">
      <div className="flex flex-wrap gap-2">
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              All
            </Typography>
          }
          active={filter === ''}
          onClick={() => onSetFilter('')}
        />
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              About
            </Typography>
          }
          active={filter === 'About'}
          onClick={() => onSetFilter('About')}
        />
        {!hideConnections && (
          <PillV2
            title={
              <Typography fontSize="12" lineHeight={1}>
                Connections
              </Typography>
            }
            active={filter === 'Connections'}
            onClick={() => onSetFilter('Connections')}
          />
        )}

        {((canViewPortfolio && owningTeam && isAdminOrEmployee) ||
          (canViewPortfolio && showingJobs?.length > 0)) && (
          <PillV2
            title={
              <Typography fontSize="12" lineHeight={1}>
                Jobs
              </Typography>
            }
            active={filter === 'Jobs'}
            onClick={() => onSetFilter('Jobs')}
          />
        )}
      </div>
    </div>
  )
}

export default PortfolioFilters
