import React, { useState } from 'react'
import moment from 'moment'

import { WorkHistoryEntry } from 'containers/CandidateProfileModal/WorkHistory'
import { IExperience } from 'containers/GlobalUserProfile/types'
import CabalButton from 'global/CabalButton'
import Modal from 'global/Modal'
import { ModalSectionHeader } from 'global/Modal/styles'
import { CandidateWorkExperience } from 'utils/types'
import { useMutation, useQueryClient } from 'react-query'
import api, { callApi } from 'utils/api'
import { cabalToast } from 'ui-components/Toast'

interface ExperienceModalProps {
  onHide: () => void
  experience?: CandidateWorkExperience[]
  slug: string
}

const ExperienceModal = ({ onHide, experience: initialExperience, slug }: ExperienceModalProps) => {
  const queryClient = useQueryClient()
  const [workHistory, setWorkHistory] = useState<IExperience[]>(initialExperience?.map(exp => ({
    ...exp,
    fromMonth: parseInt(exp.starts_at.month),
    fromYear: parseInt(exp.starts_at.year),
    toMonth: parseInt(exp.ends_at.month),
    toYear: parseInt(exp.ends_at.year),
  })) || [])


  const updateExperience = useMutation<CandidateWorkExperience, Error>(
    () => callApi(api.updateWorkExperience, { slug, updateData: workHistory }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['globalPersonWorkExperiences', slug])
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to update experience' })
      }
    }
  )

  return (
    <Modal
      header="Experience"
      show={true}
      onHide={onHide}
      dangerouslyBypassFocusLock={true}
      rightActions={
        <CabalButton
          type="button"
          variant="primary"
          onClick={() => updateExperience.mutate()}
          working={updateExperience.isLoading}
        >
          Save
        </CabalButton>
      }
    >
      <div className="flex justify-between items-center">
        <ModalSectionHeader>Work Experience</ModalSectionHeader>
        <CabalButton
          variant="link"
          type="button"
          onClick={() => {
            setWorkHistory([
              {
                company: '',
                title: '',
                summary: '',
                uuid: crypto.randomUUID(),
                fromMonth: null,
                fromYear: null,
                toMonth: null,
                toYear: null,
              },
              ...workHistory,
            ])
          }}
        >
          + Add More
        </CabalButton>
      </div>
      {workHistory.map((entry, i) => (
        <WorkHistoryEntry
          key={entry.uuid || i}
          entry={entry}
          onUpdate={(newEntry) => {
            const newWorkHistory = [...workHistory]
            newWorkHistory[i] = {
              ...newWorkHistory[i],
              ...newEntry,
            }
            setWorkHistory(newWorkHistory)
          }}
          hasMultipleEntries={workHistory.length > 1}
          onRemove={() => {
            setWorkHistory(workHistory.filter((_, index) => index !== i))
          }}
          isLast={i === workHistory.length - 1}
        />
      ))}
    </Modal>
  )
}

export default ExperienceModal
