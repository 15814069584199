import React, { useEffect } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import capitalize from 'lodash/capitalize'
import pluralize from 'pluralize'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import useCrmStatus from 'components/CrmStatus'
import { EditListModal } from 'components/EditList'
import FilterPills from 'containers/Connections/Lists/FilterPills'
import { useAccessControl } from 'global/AccessControl'
import Loading from 'global/Loading'
import { RenderModal, useModal } from 'global/Modal/Context'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'

import api, { callApi } from 'utils/api'
import useTeamSetting from 'utils/hooks/useTeamSetting'
import { CompanyListBlueprint } from 'utils/types'
import { formatCompact } from 'utils/ui'

import ListRow from './ListRow'

interface Props {}

const FilterArea = styled.div`
  ${tw`sticky top-0 z-10`}
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
`

const Lists: React.VFC<Props> = () => {
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()
  const location = useLocation()
  const history = useHistory()
  const { user } = useCurrentUser()
  useCrmStatus(teamSlug)
  const { isAdminOrEmployee, canViewPortfolio } = useAccessControl(teamSlug)
  const { team, isLoading: teamLoading } = useTeam(teamSlug)
  const [parent] = useAutoAnimate()
  const [filter, setFilter] = React.useState({})
  const { data, refetch, isLoading } = useQuery(
    ['company_list', teamSlug],
    ({ pageParam: page = 1 }) => callApi(api.getCompanyLists, teamSlug, false, false),
    {
      keepPreviousData: true,
    },
  )

  const companyLists = data?.company_lists || []

  const renderCompanyListModal: RenderModal = (resolve) => (
    <EditListModal
      dynamic={false}
      onHide={() => resolve(false)}
      reload={refetch}
      teamSlug={teamSlug}
      onDelete={refetch}
    />
  )

  const renderDynamicListModal: RenderModal = (resolve) => (
    <EditListModal
      teamSlug={teamSlug}
      dynamic={true}
      onHide={() => resolve(false)}
      reload={refetch}
      listType="companies"
      listCategory="sales"
    />
  )

  const hideDeletedList = (uuid: string) => {
    refetch()
  }

  useEffect(() => {
    if (location.hash === '#new') {
      window.location.hash = ''
      showModal(renderCompanyListModal, 'render_company_list_modal')
    }

    if (location.search.includes('from_salesforce=1')) {
      history.push({ search: location.search.replace('from_salesforce=1', '') })
      showModal(renderDynamicListModal, 'render_dynamic_list_modal')
    }

    if (location.search.includes('from_hubspot=1')) {
      history.push({ search: location.search.replace('from_hubspot=1', '') })
      showModal(renderDynamicListModal, 'render_dynamic_list_modal')
    }
  }, [])

  const teamOptions = Array.from(
    new Map(
      companyLists.map((list) => [
        list.owning_team.slug,
        { value: list.owning_team.slug, label: list.owning_team.name },
      ]),
    ).values(),
  )

  const filteredLists = companyLists
    .filter((pl) => {
      if (filter.listType?.length === 0 || !filter.listType) {
        return true
      }
      if (filter.listType?.includes('my_lists') && pl?.user?.uuid === user.uuid) {
        return true
      }
      if (filter.listType?.includes('sales') && pl.list_category === 'sales') {
        return true
      }
      if (filter.listType?.includes('fundraise') && pl.list_category === 'fundraise') {
        return true
      }
      if (filter.listType?.includes('talent') && pl.list_category === 'talent') {
        return true
      }
      if (filter.listType?.includes('resources') && pl.list_category === 'resources') {
        return true
      }
    })
    .filter((pl) => {
      if (filter.query) {
        return (
          pl.name.toLowerCase().includes(filter.query.toLowerCase()) ||
          pl.owning_team.name.toLowerCase().includes(filter.query.toLowerCase())
        )
      }
      return true
    })
    .filter((pl) => {
      return (
        !filter.teams || filter.teams.length === 0 || filter.teams.includes(pl.owning_team.slug)
      )
    })

  const publishedLists = filteredLists.filter((list) => {
    if (
      list.internal_default_type === 'default_sales_company' ||
      !list.owned ||
      list.portfolio ||
      list.is_all_jobs ||
      list.is_all_candidates ||
      list.is_team_connections
    ) {
      return false
    } else {
      return list.published
    }
  })
  const privateLists = filteredLists.filter((list) => {
    if (
      list.internal_default_type === 'default_sales_company' ||
      !list.owned ||
      list.portfolio ||
      list.is_all_jobs ||
      list.is_all_candidates ||
      list.is_team_connections
    ) {
      return false
    } else {
      return !list.published
    }
  })

  const sharedLists = filteredLists.filter((list) => !list.owned)
  const portfolioList = filteredLists.find((list) => list.portfolio && list.owned)
  const allJobsList = filteredLists.find((list) => list.is_all_jobs)
  const allCandidatesList = filteredLists.find((list) => list.is_all_candidates)
  const teamConnectionsList = filteredLists.find((list) => list.is_team_connections)
  const defaultSalesLists = filteredLists.filter(
    (list) => list.internal_default_type === 'default_sales_company',
  )

  if (teamLoading || isLoading) return <Loading className="mt-6" />

  const renderAllCompaniesCard = () => (
    <DataCardV2
      title="All Companies"
      onClick={() => history.push(`/${teamSlug}/lists/companies`)}
      cta={
        <Typography
          color="purple"
          fontSize="12"
          onClick={() => history.push(`/${teamSlug}/lists/companies`)}
        >
          View
        </Typography>
      }
      className="border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded"
    />
  )

  return (
    <PageWrapper
      title="Lists"
      crumbs={[
        { title: 'Asks', url: `/${teamSlug}/asks` },
        { title: 'Lists', url: `/${teamSlug}/lists` },
      ]}
    >
      {isAdminOrEmployee && (
        <FilterArea>
          <FilterPills
            list={companyLists}
            teamSlug={teamSlug}
            filter={filter}
            setFilter={setFilter}
            teamOptions={teamOptions}
          />
        </FilterArea>
      )}

      <div ref={parent}>
        {!!filter.query && publishedLists.length === 0 && privateLists.length === 0 && (
          <div className="flex flex-col items-center justify-center gap-4 mt-40">
            <Typography color="fog" fontSize="20">
              <i className="far fa-arrows-left-right"></i>
            </Typography>
            <Typography fontSize="14" lineHeight={1}>
              No lists with &quot;{filter.query}&quot; found
            </Typography>
          </div>
        )}

        {user?.team?.slug == teamSlug && !filter.listType && !filter.query && !filter.teams && (
          <>
            <div className="flex flex-col items-left pt-6 pb-2">
              <Typography component="div" fontSize="14" fontWeight={600}>
                Default
              </Typography>
            </div>
            {!canViewPortfolio && renderAllCompaniesCard()}

            {!!portfolioList && canViewPortfolio && (
              <DataCardV2
                title={portfolioList.name}
                onClick={() => history.push(`/${teamSlug}/lists/${portfolioList.slug}`)}
                className="border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded"
                description={[
                  capitalize(portfolioList.list_type),
                  `${formatCompact(portfolioList.item_count)} ${pluralize(
                    'items',
                    portfolioList.item_count,
                  )} `,
                ]}
                cta={
                  <Typography
                    color="purple"
                    fontSize="12"
                    onClick={() => history.push(`/${teamSlug}/lists/${portfolioList.slug}`)}
                  >
                    View
                  </Typography>
                }
              />
            )}
            <DataCardV2
              title="All Connections"
              onClick={() => history.push(`/${teamSlug}/lists/connections`)}
              cta={
                <Typography
                  color="purple"
                  fontSize="12"
                  onClick={() => history.push(`/${teamSlug}/lists/connections`)}
                >
                  View
                </Typography>
              }
              className="border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded"
            />

            {teamConnectionsList && (
              <DataCardV2
                title={teamConnectionsList?.name || 'Team Connections'}
                onClick={() => history.push(`/${teamSlug}/lists/${teamConnectionsList?.slug}`)}
                description={[
                  capitalize(teamConnectionsList.list_type),
                  `${formatCompact(teamConnectionsList.item_count)} ${pluralize(
                    'items',
                    teamConnectionsList.item_count,
                  )} `,
                ]}
                cta={
                  <Typography
                    color="purple"
                    fontSize="12"
                    onClick={() => history.push(`/${teamSlug}/lists/${teamConnectionsList?.slug}`)}
                  >
                    View
                  </Typography>
                }
                className="border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded"
              />
            )}
            {team?.features?.talent_marketplace && !!canViewPortfolio && (
              <DataCardV2
                title={allCandidatesList?.name || 'All Candidates'}
                onClick={() => history.push(`/${teamSlug}/lists/all-candidates`)}
                cta={
                  <Typography
                    color="purple"
                    fontSize="12"
                    onClick={() => history.push(`/${teamSlug}/lists/all-candidates`)}
                  >
                    View
                  </Typography>
                }
                className="border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded"
              />
            )}
            {!!canViewPortfolio && !team?.features?.new_jobs_list && (
              <DataCardV2
                title="All Jobs"
                onClick={() => history.push(`/${teamSlug}/lists/jobs`)}
                cta={
                  <Typography
                    color="purple"
                    fontSize="12"
                    onClick={() => history.push(`/${teamSlug}/lists/jobs`)}
                  >
                    View
                  </Typography>
                }
                className="border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded"
              />
            )}
            {!!canViewPortfolio && team?.features?.new_jobs_list && (
              <DataCardV2
                title={allJobsList?.name || 'All Jobs'}
                onClick={() => history.push(`/${teamSlug}/lists_v2/all-jobs`)}
                cta={
                  <Typography
                    color="purple"
                    fontSize="12"
                    onClick={() => history.push(`/${teamSlug}/lists_v2/all-jobs`)}
                  >
                    View
                  </Typography>
                }
                className="border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded"
              />
            )}

            {!canViewPortfolio && defaultSalesLists.length > 0 && (
              <>
                {defaultSalesLists.map((list) => (
                  <ListRow
                    key={list.uuid}
                    list={list}
                    teamSlug={teamSlug}
                    reload={refetch}
                    hideDeleteOption={true}
                    view={'list'}
                  />
                ))}
              </>
            )}

            {canViewPortfolio && renderAllCompaniesCard()}
          </>
        )}

        {publishedLists.length > 0 && (
          <>
            <div className="flex flex-col items-left pt-6 pb-2">
              <Typography component="div" fontSize="14" fontWeight={600}>
                Published
              </Typography>
            </div>
            {publishedLists.map((c) => (
              <ListRow key={c.uuid} list={c} teamSlug={teamSlug} reload={refetch} view={'list'} />
            ))}
          </>
        )}

        {sharedLists.length > 0 && (
          <>
            <div className="flex flex-col items-left pt-6 pb-2">
              <Typography component="div" fontSize="14" fontWeight={600}>
                Shared
              </Typography>
            </div>
            {sharedLists.map((c) => (
              <ListRow key={c.uuid} list={c} teamSlug={teamSlug} reload={refetch} view={'list'} />
            ))}
          </>
        )}

        {privateLists.length > 0 && (
          <>
            <div className="flex flex-col items-left pt-6 pb-2">
              <Typography component="div" fontSize="14" fontWeight={600}>
                Private
              </Typography>
            </div>
            {privateLists.map((c) => (
              <ListRow key={c.uuid} list={c} teamSlug={teamSlug} reload={refetch} />
            ))}
          </>
        )}
      </div>
    </PageWrapper>
  )
}

export default Lists
