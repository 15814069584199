import React, { useState } from 'react'

import { sanitize } from 'dompurify'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Card from 'global/Card'
import DropDownMenu from 'global/DropDownMenu'
import { CheckBox } from 'global/Input'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { htmlTextContent } from 'utils/html'
import { InvestorCompanyJob } from 'utils/types/investor'

interface Props {
  job: InvestorCompanyJob
  onEdit: (job: InvestorCompanyJob) => void
  cloneJob: (job: InvestorCompanyJob) => void
  refresh: (job: InvestorCompanyJob) => void
  isInline?: boolean
  selectedUuids: Set<string>
  toggleSelectedJob: (uuid: string, selected: boolean) => void
}

interface DescriptionProps {
  description: string
}

const Wrapper = styled.div`
  ${tw`flex justify-between px-6 py-2`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border-bottom: 1px solid #e5e7eb;
`

export const JobDescription: React.VFC<DescriptionProps> = ({ description }) => {
  const [viewMore, setViewMore] = useState(false)

  let descriptionText = <div dangerouslySetInnerHTML={{ __html: sanitize(description) }} />

  if (description && description.length > 200) {
    if (viewMore) {
      descriptionText = (
        <>
          {descriptionText}
          &nbsp;
          <Typography
            fontSize="12"
            color="fog_rain"
            className="underline cursor-pointer"
            onClick={() => setViewMore(!viewMore)}
          >
            view less
          </Typography>
        </>
      )
    } else {
      descriptionText = (
        <>
          {htmlTextContent(description)?.slice(0, 200)}...
          <Typography
            fontSize="12"
            color="fog_rain"
            className="underline cursor-pointer"
            onClick={() => setViewMore(!viewMore)}
          >
            view more
          </Typography>
        </>
      )
    }
  }

  return (
    <Typography
      color="fog_rain"
      component="div"
      fontSize="12"
      className="my-1"
      data-testid="portfolio-job-description"
    >
      {descriptionText}
    </Typography>
  )
}

const JobCard: React.VFC<Props> = ({
  job,
  onEdit,
  isInline,
  refresh,
  cloneJob,
  selectedUuids,
  toggleSelectedJob,
}) => {
  const teamSlug = useTeamSlug()
  const [viewDropdown, setViewDropdown] = useState(false)

  const { mutate: archiveInvestorCompanyJob } = useMutation(
    (job: InvestorCompanyJob) =>
      callApi(api.archiveInvestorCompanyJob, job.investor_company?.uuid, job.uuid, teamSlug),
    {
      onSuccess: ({ job }) => {
        cabalToast({ style: 'success', content: 'Job was archived.' })
        refresh(job)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const { mutate: unarchiveInvestorCompanyJob } = useMutation(
    (job: InvestorCompanyJob) =>
      callApi(api.unarchiveInvestorCompanyJob, job.investor_company?.uuid, job.uuid, teamSlug),
    {
      onSuccess: ({ job }) => {
        cabalToast({ style: 'success', content: 'Job was unarchived.' })
        refresh(job)
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const items = [
    {
      label: job.archived ? 'Unarchive' : 'Archive',
      onSelect: job.archived
        ? () => unarchiveInvestorCompanyJob(job)
        : () => archiveInvestorCompanyJob(job),
    },
    {
      label: 'Clone',
      onSelect: () => cloneJob(job),
    },
  ]

  const menu = (
    <div onClick={() => setViewDropdown(!viewDropdown)} className="self-center ">
      <DropDownMenu
        trigger={
          <CabalButton
            variant="tertiary"
            className="py-0 -mb-1"
            leftIcon={<i className="far fa-ellipsis-h" />}
            component="div"
          />
        }
        menuItems={items}
      />
    </div>
  )

  return (
    <Wrapper key={job.uuid} className="flex justify-between items-center cursor-pointer">
      <div className="flex">
        <div className="flex items-center mr-3" onClick={(e) => e.stopPropagation()}>
          <CheckBox
            checked={selectedUuids.has(job.uuid)}
            onChange={(e) => {
              toggleSelectedJob(job.uuid, e.target.checked)
            }}
            data-testid="portfolio-jobs-checkbox"
          />
        </div>
        {!isInline && (
          <div data-testid="portfolio-jobs-company-logo">
            <Avatar
              name={job.investor_company.company_name}
              src={job.investor_company.logo_url}
              round
              size="32"
              className="mr-2"
            />
          </div>
        )}
        <div>
          <Typography
            fontWeight={600}
            fontSize="16"
            className="mr-2"
            data-testid="portfolio-job-title"
          >
            {job.title}
          </Typography>
          {job.investor_company.data?.linkedin_url && (
            <Typography color={'fog_rain'} fontSize="14" data-testid="portfolio-job-linkedin-url">
              <a
                href={job.investor_company.data.linkedin_url || job.investor_company.linkedin_url}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin mr-3"></i>
              </a>
            </Typography>
          )}
          {job.url && (
            <Typography color={'fog_rain'} fontSize="14" data-testid="portfolio-job-url">
              <a href={job.url} target="_blank" rel="noreferrer">
                <i className="fas fa-globe mr-3"></i>
              </a>
            </Typography>
          )}
          <Typography
            color={'fog_rain'}
            fontSize="14"
            onClick={() => onEdit(job)}
            data-testid="portfolio-job-edit-btn"
          >
            <i className="fas fa-pencil fa-fw"></i>
          </Typography>
          <Typography
            textTransform="uppercase"
            fontSize="10"
            component="div"
            letterSpacing={'0.05em'}
            fontWeight={600}
            color="widget_title"
            data-testid="portfolio-job-location"
          >
            {!isInline && job.investor_company.company_name}
            {job.location && (
              <Typography color="fog_rain" textTransform="capitalize" fontWeight={300}>
                {!isInline && ' '}
                <i className="fas fa-map-pin" />
                &nbsp;
                {`${job.location}`}
              </Typography>
            )}
          </Typography>
          {job.description && <JobDescription description={job.description} />}
        </div>
      </div>
      {menu}
    </Wrapper>
  )
}

export default JobCard
