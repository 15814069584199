import React, { useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'
import { SeeMore } from 'containers/CandidateProfile'
import EducationModal from './Modals/EducationModal'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import Widget from 'ui-components/Widget'
import api, { callApi } from 'utils/api'
import { CandidateEducation } from 'utils/types'
import { useParams } from 'react-router-dom'

const EducationRow = ({ education }: { education: CandidateEducation }) => {
  const limit = 80
  const [isExpanded, setIsExpanded] = useState(!!education.summary ? education.summary.length < limit : false)
  const description = []
  const field = isExpanded ? education.summary : education.summary ? `${education.summary.slice(0, limit)}` : ''

  if (education.degree) {
    description.push(
      <div>
        <i className="far fa-graduation-cap mr-1" /> {education.degree}
      </div>,
    )
  }

  if (education.start_at && education.end_at) {
    description.push(
      <div>
        {moment.utc(education.start_at).format('MMM YYYY')} -{' '}
        {moment.utc(education.end_at).format('MMM YYYY')}
      </div>,
    )
  } else if (education.start_at) {
    description.push(<div>{moment.utc(education.start_at).format('MMM YYYY')} </div>)
  }

  return (
    <div className="flex flex-col">
      <DataCardV2
        avatar={<i className="far fa-university" />}
        title={education.school}
        description={description}
        className="hover:bg-transparent dark:hover:bg-transparent"
      />
      {field && (
        <p className="ml-12 pl-1 leading-5 -mt-2.5 mb-2">
          <Typography color="rain_fog" fontSize="12" lineHeight={1}>
            {field}
          </Typography>
          {education.summary && education.summary.length > limit && (
            <SeeMore
              className="ml-1 cursor-pointer"
              color="fog"
              fontSize="12"
              lineHeight={1}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? '...see less' : '...see more'}
            </SeeMore>
          )}
        </p>
      )}
    </div>
  )
}

const Education = ({canEdit}: {canEdit: boolean}) => {
  const { slug } = useParams()
  const { showModal } = useModal()
  const [limit, setLimit] = React.useState(3)

  const { data: educations = [] } = useQuery<CandidateEducation[]>(
    ['globalPersonEducations', slug],
    () => callApi(api.getEducations, { slug }),
    {
      enabled: !!slug,
    }
  )

  const sortedEducationHistory = React.useMemo(
    () => educations.sort((a, b) => 
      new Date(b.start_at).getTime() - new Date(a.start_at).getTime()
    ),
    [educations]
  )

  const educationHistory = React.useMemo(
    () => sortedEducationHistory.slice(0, limit),
    [sortedEducationHistory, limit],
  )

  const renderEducationModal = (resolve: () => void) => (
    <EducationModal 
      onHide={resolve} 
      education={educations}
      slug={slug}
    />
  )

  if (sortedEducationHistory.length === 0) {
    return null
  }

  return (
    <Widget
      title="Education"
      className="group"
      cta={
        canEdit && (
          <div className="group-hover:flex hidden flex-row gap-8">
            <CabalButton
              variant="link"
            padding={'0'}
            leftIcon={<i className="far fa-edit" />}
            onClick={() => showModal(renderEducationModal, 'education_modal')}
          >
            Edit
          </CabalButton>
          </div>
        )
      }
    >
      <div className="-mx-2 flex flex-col gap-3">
        {educationHistory.map((education) => (
          <EducationRow key={education.uuid} education={education} />
        ))}
      </div>
      {sortedEducationHistory.length > 3 && (
        <div className="flex justify-center pt-1">
          <CabalButton
            variant="link"
            onClick={() => (limit === 3 ? setLimit(sortedEducationHistory.length) : setLimit(3))}
          >
            See all ({sortedEducationHistory.length})
          </CabalButton>
        </div>
      )}
    </Widget>
  )
}

export default Education
