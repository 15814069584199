import React, { useState } from 'react'

import { placeholder } from 'lodash/Partial'
import { useMutation } from 'react-query'

import { Divider } from 'components/SendMessage/styles'
import OfferRow, { IPerson } from 'containers/CompanyPage/OfferIntroModal/OfferRow'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { SearchInput, TextArea } from 'global/Input'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanyBlueprintWithConnections, VoteType } from 'utils/types'

interface IProps {
  resolve: () => void
  company: CompanyBlueprintWithConnections
  people: IPerson[]
  peopleVotes: Record<number, any>
  teamSlug: string
  listUuid: string
}

const OfferIntroPageModal = ({
  resolve,
  company,
  people,
  peopleVotes,
  teamSlug,
  listUuid,
}: IProps) => {
  const [note, setNote] = useState('')
  const [search, setSearch] = useState('')
  const [selectedPeople, setSelectedPeople] = useState<number[]>([])
  const { isAdminOrEmployee } = useAccessControl()

  const filteredCompany = company.global_people_ids.filter((person_id) => {
    const person = people[person_id]
    return person?.name?.toLowerCase().includes(search.toLowerCase())
  })

  const yourConnections = React.useMemo(
    () =>
      filteredCompany.filter(
        (person_id) =>
          people[person_id] && (company.connected_global_people_ids || []).includes(person_id),
      ),
    [filteredCompany, people, company.connected_global_people_ids],
  )

  const otherConnections = React.useMemo(
    () =>
      filteredCompany.filter(
        (person_id) =>
          people[person_id] && !(company.connected_global_people_ids || []).includes(person_id),
      ),
    [filteredCompany, people, company.connected_global_people_ids],
  )

  const handleOffer = () => {
    if (selectedPeople.length === 0 && note !== '') {
      sendVote([1, undefined])
    }

    // Person votes
    if (selectedPeople.length > 0) {
      selectedPeople.forEach((person_id) => {
        sendVote([1, person_id])
      })
    }

    cabalToast({
      content: `Your offer has been logged. ${company.team_name} will follow up with you via email. `,
      style: 'success',
    })

    resolve()
  }

  const { mutate: sendVote, isLoading: sendingVote } = useMutation<
    any,
    Error,
    [VoteType, number | undefined]
  >(['createVote'], async ([vote, person_id]: [VoteType, number | undefined]) =>
    callApi(api.createVote, {
      team_slug: teamSlug,
      votable_type: person_id ? 'GlobalPerson' : 'Company',
      votable_id: person_id || company.id,
      vote,
      company_id: company.id,
      list_uuid: listUuid,
      notes: note,
    }),
  )

  return (
    <Modal
      header={`Offer help with ${company.name}`}
      show={true}
      onHide={resolve}
      rightActions={
        <CabalButton
          disabled={selectedPeople.length === 0 && note === ''}
          working={sendingVote}
          onClick={handleOffer}
        >
          Offer help {selectedPeople.length > 0 ? `(${selectedPeople.length})` : ''}
        </CabalButton>
      }
    >
      <div className="flex flex-col gap-1">
        <Typography fontSize="12">Add a note on how you can help with {company.name}</Typography>
        <TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Add context for your offer to help"
          className="w-full"
          rows={4}
        />
      </div>

      <div className="flex flex-col mt-4">
        <div className="flex flex-col gap-1">
          <Typography fontSize="12">Select {company.name} team members</Typography>
          <SearchInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={`Find ${company.name} members`}
          />
        </div>

        <div className="flex flex-col mt-2 max-h-[330px] overflow-y-auto">
          {yourConnections.length > 0 && (
            <>
              {yourConnections.map((person_id: number) => (
                <OfferRow
                  key={person_id}
                  person={people[person_id]}
                  selected={selectedPeople.includes(person_id)}
                  connected={true}
                  onSelect={() => {
                    setSelectedPeople((prev) =>
                      prev.includes(person_id)
                        ? prev.filter((id) => id !== person_id)
                        : [...prev, person_id],
                    )
                  }}
                />
              ))}
            </>
          )}

          {isAdminOrEmployee && otherConnections.length > 0 && (
            <>
              {otherConnections.map((person_id: number) => (
                <OfferRow
                  key={person_id}
                  person={people[person_id]}
                  selected={selectedPeople.includes(person_id)}
                  onSelect={() => {
                    setSelectedPeople((prev) =>
                      prev.includes(person_id)
                        ? prev.filter((id) => id !== person_id)
                        : [...prev, person_id],
                    )
                  }}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default OfferIntroPageModal
