import React from 'react'

import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Props } from 'react-select'
import styled from 'styled-components'

import Avatar from 'global/Avatar'
import { AvatarUploadRef } from 'global/AvatarUpload'
import CabalButton from 'global/CabalButton'
import { TextArea, TextInput, UploadButton } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'
import Widget from 'ui-components/Widget'

import { MutationFunc, Team } from 'utils/types'

const PillWrapper = styled(Typography)<{ active: boolean }>`
  &:hover {
    * > i,
    p {
      color: ${({ theme }) => theme.colors.purple};
    }
  }
`

const NavPill: React.FC<{ title: string; onClick: () => void; active: boolean }> = ({
  title,
  onClick,
  active,
}) => {
  return (
    <PillWrapper
      style={{ width: 140 }}
      className="flex flex-col items-center justify-center cursor-pointer rounded py-1 relative"
      onClick={onClick}
      active={active}
    >
      <Typography
        fontSize="12"
        color={active ? 'purple' : 'fog_rain'}
        fontWeight={400}
        component="p"
      >
        {title}
      </Typography>
      {active && (
        <motion.span
          layoutId="company-tab"
          transition={{ type: 'spring', duration: 0.5 }}
          className="absolute inset-0 z-0 bg-[#5c69d11f] rounded-md"
        ></motion.span>
      )}
    </PillWrapper>
  )
}

const CompanyInfo: React.FC<{ updateTeamMutation: MutationFunc<Partial<Team>> }> = ({
  updateTeamMutation,
}) => {
  const teamSlug = useTeamSlug()

  const { team, reloadTeam } = useTeam(teamSlug)
  const avatarUploadRef = React.useRef<AvatarUploadRef>(null)
  const [updatedTeam, setUpdatedTeam] = React.useState({
    name: team?.name ?? '',
    website: team?.website ?? '',
    twitter_url: team?.twitter_url ?? '',
    description: team?.description ?? '',
    linkedin_url: team?.linkedin_url ?? '',
    logo: team?.logo ?? '',
    enable_portfolio: team?.enable_portfolio ?? false,
  })

  const handleSubmit = () => {
    updateTeamMutation
      .mutateAsync({
        name: updatedTeam.name,
        website: updatedTeam.website,
        twitter_url: updatedTeam.twitter_url,
        description: updatedTeam.description,
        linkedin_url: updatedTeam.linkedin_url,
        logo: updatedTeam.logo,
        enable_portfolio: updatedTeam.enable_portfolio,
        enable_jobs: updatedTeam.enable_portfolio,
      })
      .then(() => {
        avatarUploadRef.current?.upload()
      })
      .finally(() => {
        reloadTeam()
      })
  }

  const hasTeamChanges = () => {
    return (
      updatedTeam.name !== team?.name ||
      updatedTeam.website !== team?.website ||
      updatedTeam.twitter_url !== team?.twitter_url ||
      updatedTeam.description !== team?.description ||
      updatedTeam.linkedin_url !== team?.linkedin_url ||
      updatedTeam.logo !== team?.logo ||
      updatedTeam.enable_portfolio !== team?.enable_portfolio
    )
  }

  return (
    <>
      <Widget title="Team Info">
        <ModalSectionWrapper>
          <div className="flex flex-col gap-2">
            <ModalInputWrapper>
              <ModalInputLabel>Company Logo</ModalInputLabel>

              <div className="mb-4">
                <UploadButton
                  allowedExtensions={['png', 'jpg', 'jpeg']}
                  onUpload={(uuid) =>
                    setUpdatedTeam({ ...updatedTeam, logo: `/api/uploads/${uuid}` })
                  }
                  showPencil={true}
                  text=""
                  triggerProps={{
                    variant: 'tertiary',
                    leftIcon: (
                      <Typography className="flex items-end justify-end">
                        <Avatar src={updatedTeam.logo} size={'60'} />
                        <i className="far fa-pencil"></i>
                      </Typography>
                    ),
                    padding: '0',
                  }}
                />
              </div>
            </ModalInputWrapper>

            <ModalInputWrapper>
              <ModalInputLabel>Company Name</ModalInputLabel>
              <TextInput
                value={updatedTeam.name}
                placeholder="Company Name"
                className="w-full mb-4"
                onChange={(e) => setUpdatedTeam({ ...updatedTeam, name: e.target.value })}
                data-testid="company-name"
              />
            </ModalInputWrapper>

            <ModalInputWrapper>
              <ModalInputLabel>Team Type</ModalInputLabel>
              <div className="flex gap-2 w-min border border-gray-200 rounded-lg p-1 mb-4">
                <NavPill
                  title="Company"
                  onClick={() => setUpdatedTeam({ ...updatedTeam, enable_portfolio: false })}
                  active={!updatedTeam.enable_portfolio}
                />
                <NavPill
                  title="VC Firm"
                  onClick={() => setUpdatedTeam({ ...updatedTeam, enable_portfolio: true })}
                  active={updatedTeam.enable_portfolio}
                />
              </div>
            </ModalInputWrapper>

            <ModalInputWrapper>
              <ModalInputLabel>LinkedIn URL</ModalInputLabel>
              <TextInput
                value={updatedTeam.linkedin_url}
                placeholder="LinkedIn URL"
                className="w-full mb-4"
                onChange={(e) => setUpdatedTeam({ ...updatedTeam, linkedin_url: e.target.value })}
              />
            </ModalInputWrapper>

            <ModalInputWrapper>
              <ModalInputLabel>X URL</ModalInputLabel>
              <TextInput
                value={updatedTeam.twitter_url}
                placeholder="Twitter URL"
                className="w-full mb-4"
                onChange={(e) => setUpdatedTeam({ ...updatedTeam, twitter_url: e.target.value })}
                data-testid="twitter-url"
              />
            </ModalInputWrapper>

            <ModalInputWrapper>
              <ModalInputLabel>Description</ModalInputLabel>
              <TextArea
                value={updatedTeam.description}
                placeholder="Company Description"
                className="w-full"
                data-testid="company-description"
                onChange={(e) => setUpdatedTeam({ ...updatedTeam, description: e.target.value })}
                rows={6}
              />
            </ModalInputWrapper>
          </div>
        </ModalSectionWrapper>
        <div className="flex justify-end mt-2">
          <CabalButton
            variant="primary"
            disabled={updateTeamMutation.isLoading || !hasTeamChanges()}
            working={updateTeamMutation.isLoading}
            onClick={handleSubmit}
            data-testid="save-public-dir"
          >
            Save
          </CabalButton>
        </div>
      </Widget>
    </>
  )
}

export default CompanyInfo
