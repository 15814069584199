import React, { useState } from 'react'

import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'

import {
  COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS,
  LEVEL_OPTIONS,
  ROLE_FUNCTION_OPTIONS,
} from 'containers/ListIndexView/constants'
import { MultiSelect, Select, TextArea, TextInput } from 'global/Input'
import CityInput from 'global/Input/CityInput'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import { IAboutOptions } from 'containers/GlobalUserProfile/Modals/PreferencesModal'

const EditDetails = ({ options, setPrefOptions }: { options: IAboutOptions, setPrefOptions: (options: IAboutOptions) => void }) => {

  return (
    <>
      <ModalInputWrapper className="py-2 text-left">
        <ModalInputLabel>Function</ModalInputLabel>
        <MultiSelect
          placeholder="Primary job function(s)"
          options={ROLE_FUNCTION_OPTIONS}
          value={options.functions}
          onChange={(e) => setPrefOptions({ ...options, functions: e })}
        />
      </ModalInputWrapper>
      <ModalInputWrapper className="py-2">
        <ModalInputLabel>Level</ModalInputLabel>
        <SelectWrapper>
          <Select
            placeholder="Experience level"
            value={options.level}
            onChange={(e) => setPrefOptions({ ...options, level: e })}
            options={LEVEL_OPTIONS}
          />
        </SelectWrapper>
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Size</div>
        </ModalInputLabel>

        <MultiSelect
          placeholder="Preferred company size"
          options={COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS}
          value={options.ideal_company_size}
          onChange={(companySize) => setPrefOptions({ ...options, ideal_company_size: companySize })}
        />
      </ModalInputWrapper>
 
      <ModalInputWrapper className="py-2 text-left">
        <ModalInputLabel>Location</ModalInputLabel>
        <CityInput
          onChange={(opt) => {
            setPrefOptions({ ...options, location: opt?.value })
          }}
          value={{ value: options.location, label: options.location }}
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Compensation</div>
        </ModalInputLabel>
        <TextInput
          value={options.minimum_salary}
          placeholder="Compensation expectation"
          onChange={(e) => setPrefOptions({ ...options, minimum_salary: e.target.value })}
        />
      </ModalInputWrapper>

      <ModalInputWrapper className="py-2">
        <ModalInputLabel>
          <div className="flex items-center justify-start h-full">Work Status</div>
        </ModalInputLabel>
        <SelectWrapper>
          <Select
            placeholder="Work Status"
            options={[
              { value: 'legal_to_work_in_us', label: 'Legal to Work in US' },
              { value: 'visa_requirement', label: 'Visa Requirement' },
            ]}
            value={options.work_status}
            onChange={(workStatus) =>
              setPrefOptions({ ...options, work_status: workStatus || undefined })
            }
          />
        </SelectWrapper>
      </ModalInputWrapper>
      {/* <ModalInputWrapper className="py-2 text-left">
    <ModalInputLabel>Stage Experience</ModalInputLabel>
    <MultiSelect
      placeholder="Company stage experience"
      value={prefOptions.funding_stage_experience}
      onChange={(e) => setPrefOptions({ ...prefOptions, funding_stage_experience: e })}
      options={STAGE_OPTIONS}
    />
  </ModalInputWrapper> */}

      <div className="mb-4">
        <ModalInputWrapper className="py-2">
          <ModalInputLabel>Bio</ModalInputLabel>
          <TextArea
            placeholder="Add a one-line bio"
            value={options.bio}
            onChange={(e) => setPrefOptions({ ...options, bio: e.target.value })}
            rows={6}
          />
        </ModalInputWrapper>
      </div>
    </>
  )
}

export default EditDetails
