import React, { useState } from 'react'

import { Field, FieldProps, Formik } from 'formik'

import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'
import { STAGE_OPTIONS } from 'containers/ListIndexView/constants'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { MultiSelectField, SelectField, TextArea, TextInputField } from 'global/Input'
import CityInput from 'global/Input/CityInput'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'

import { InvestorCompany } from 'utils/types/investor'

interface Props {
  onHide: () => void
  portfolioCompany: InvestorCompany
  updateCompanyMutation: any
}

const CompanyInfo: React.FC<Props> = ({ onHide, portfolioCompany, updateCompanyMutation }) => {
  const [initialValues] = useState(portfolioCompany)

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          updateCompanyMutation.mutate({ portfolioCompany: values, ownerUuids: {} })
        }}
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
              }
            }}
          >
            <ModalSectionWrapper>
              <ModalInputWrapper className="">
                <ModalInputLabel>Photo</ModalInputLabel>

                <Avatar
                  src={
                    portfolioCompany.domain
                      ? `https://logo.clearbit.com/${portfolioCompany.domain}`
                      : portfolioCompany.logo_url
                  }
                  size="96"
                />
              </ModalInputWrapper>
              <ModalInputWrapper className="">
                <ModalInputLabel>Name</ModalInputLabel>

                <Field name="company_name">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Company name"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="company-name"
                      autoFocus={props.field.value ? false : true}
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>Website</ModalInputLabel>

                <Field name="domain">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Enter website"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="website"
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <Field name="description">
                  {(props: FieldProps) => (
                    <>
                      <ModalInputLabel>Description</ModalInputLabel>
                      <TextArea
                        {...props.field}
                        className="w-full"
                        placeholder="Description"
                        data-testid="description"
                        maxLength={160}
                      />
                    </>
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper>
                <Field name="employee_count">
                  {(props: FieldProps) => (
                    <>
                      <ModalInputLabel>Employees</ModalInputLabel>
                      <TextInputField
                        {...props.field}
                        className="w-full"
                        placeholder="Employee count"
                        data-testid="employees"
                        error={props.meta.touched ? props.meta.error : undefined}
                      />
                    </>
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>Location</ModalInputLabel>
                <Field name="location">
                  {(props: FieldProps) => (
                    <SelectWrapper>
                      <CityInput
                        {...props.field}
                        onBlur={() => {
                          props.form.setFieldTouched(props.field.name, true)
                        }}
                        onChange={(opt) => {
                          props.form.setFieldValue(props.field.name, opt?.value)
                        }}
                        value={
                          props.field.value
                            ? { value: props.field.value, label: props.field.value }
                            : null
                        }
                        error={props.meta.touched ? props.meta.error : undefined}
                      />
                    </SelectWrapper>
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Stage </div>
                </ModalInputLabel>
                <Field name="stage">
                  {(props: FieldProps) => (
                    <SelectField
                      placeholder="Company stage"
                      fieldProps={props}
                      options={STAGE_OPTIONS}
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Tags</div>
                </ModalInputLabel>
                <Field name="tags">
                  {(props: FieldProps) => (
                    <MultiSelectField
                      placeholder="Tags"
                      creatable
                      fieldProps={props}
                      options={[]}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper>
                <ModalInputLabel>
                  <div className="flex flex-col">
                    <span className="block">Linkedin URL</span>
                    <CabalButton
                      variant="link"
                      padding="4px 0 0 0"
                      className="self-start"
                      onClick={(e) => {
                        e.stopPropagation()
                        window.open('https://linkedin.com/in/', '_blank', 'noopener noreferrer')
                      }}
                    >
                      Get link
                    </CabalButton>
                  </div>
                </ModalInputLabel>
                <Field name="linkedin_url">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Linkedin URL"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="linkedin-url"
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>
                  <div className="flex flex-col">
                    <span className="block">X Profile URL</span>
                    <CabalButton
                      variant="link"
                      padding="4px 0 0 0"
                      className="self-start"
                      onClick={(e) => {
                        e.stopPropagation()
                        window.open('https://x.com', '_blank', 'noopener noreferrer')
                      }}
                    >
                      Get link
                    </CabalButton>
                  </div>
                </ModalInputLabel>
                <Field name="data.twitter_url">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="X.com URL"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="twitter-url"
                    />
                  )}
                </Field>
              </ModalInputWrapper>
            </ModalSectionWrapper>

            <div className="flex justify-end items-center mt-7 mb-6">
              <CabalButton type="submit" working={updateCompanyMutation.isLoading}>
                Save
              </CabalButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CompanyInfo
