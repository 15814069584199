import React from 'react'

import styled from 'styled-components'

import Typography from 'global/Typography'

import { cn } from 'utils/styles'

const WidgetWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.cardBackground};
  box-shadow: 0px 6px 18px -17px rgba(92, 105, 209, 0.75);
  border: 1px solid ${({ theme }) => theme.colors.widget_border};
  border-radius: 10px;
`

interface WidgetProps {
  children: React.ReactNode
  title?: React.ReactNode
  description?: React.ReactNode
  cta?: React.ReactNode
  avatar?: React.ReactNode
  classNames?: string
  className?: string
  dataTestId?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onTitleClick?: () => void
}

const Widget: React.FC<WidgetProps> = ({
  children,
  title,
  cta,
  classNames,
  className,
  description,
  dataTestId,
  onMouseEnter,
  onMouseLeave,
  onTitleClick,
  avatar,
}) => {
  return (
    <WidgetWrapper
      className={cn('p-4 rounded-md w-full hover:shadow-md', classNames, className)}
      data-testid={dataTestId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="mb-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {avatar && <div className="mr-3">{avatar}</div>}
            <Typography
              className={cn({ 'cursor-pointer': !!onTitleClick })}
              fontSize="20"
              fontWeight={600}
              lineHeight={'1'}
              onClick={onTitleClick}
            >
              {title}
            </Typography>
          </div>

          {cta}
        </div>
        {description && (
          <Typography
            className="mt-1"
            fontSize="12"
            fontWeight={400}
            color="fog"
            lineHeight={'1.2'}
            component="p"
          >
            {description}
          </Typography>
        )}
      </div>

      {children}
    </WidgetWrapper>
  )
}

export default Widget
