import React, { useState } from 'react'

import { Field, FieldProps, Formik } from 'formik'
import { object, string } from 'yup'

import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'
import { STAGE_OPTIONS } from 'containers/ListIndexView/constants'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { MultiSelectField, SelectField, TextArea, TextInputField, UploadButton } from 'global/Input'
import CityInput from 'global/Input/CityInput'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'

import { cn } from 'utils/styles'
import { InvestorCompany } from 'utils/types/investor'

interface Props {
  onHide: () => void
  companyData?: InvestorCompany
  setCompanyData: (companyData: InvestorCompany) => void
  updateCompanyMutation: any
  deleteInvestorCompanyMutation: any
  isDeleting: boolean
}

const CompanyInfo: React.FC<Props> = ({
  onHide,
  companyData,
  setCompanyData,
  updateCompanyMutation,
  deleteInvestorCompanyMutation,
  isDeleting,
}) => {
  const [initialValues] = useState(companyData)

  const portfolioInfoSchema = object({
    company_name: string().required('This field is required'),
    domain: string().required('This field is required'),
  })

  return (
    <div>
      <Formik
        initialValues={initialValues ?? {}}
        validationSchema={portfolioInfoSchema}
        onSubmit={(values) => {
          updateCompanyMutation.mutate({ portfolioCompany: values, ownerUuids: {} })
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
              }
            }}
          >
            <ModalSectionWrapper>
              {(companyData?.domain || companyData?.logo_url) && (
                <ModalInputWrapper className="">
                  <ModalInputLabel>Photo</ModalInputLabel>
                  <UploadButton
                    allowedExtensions={['png', 'jpg', 'jpeg']}
                    onUpload={(uuid) => {
                      const logo_url = `/api/uploads/${uuid}`
                      setCompanyData({ ...companyData, logo_url: logo_url })
                      setFieldValue('logo_url', logo_url)
                    }}
                    text=""
                    triggerProps={{
                      variant: 'tertiary',
                      leftIcon: (
                        <Typography className="flex items-start justify-start">
                          <Avatar
                            src={
                              companyData.logo_url
                                ? companyData.logo_url
                                : `https://logo.clearbit.com/${companyData.domain}`
                            }
                            size="96"
                          />
                          <p className="pt-4 pl-3">Edit</p>
                        </Typography>
                      ),
                      padding: '0',
                    }}
                  />
                </ModalInputWrapper>
              )}

              <ModalInputWrapper className="">
                <ModalInputLabel>Name</ModalInputLabel>

                <Field name="company_name">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Company name"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="company-name"
                      autoFocus={props.field.value ? false : true}
                      onChange={(e) => {
                        props.form.setFieldValue('company_name', e.target.value)
                        setCompanyData({ ...companyData, company_name: e.target.value })
                      }}
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>Website</ModalInputLabel>

                <Field name="domain">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Enter website"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="website"
                      onChange={(e) => {
                        props.form.setFieldValue('domain', e.target.value)
                        setCompanyData({ ...companyData, domain: e.target.value })
                      }}
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <Field name="description">
                  {(props: FieldProps) => (
                    <>
                      <ModalInputLabel>Description</ModalInputLabel>
                      <TextArea
                        {...props.field}
                        className="w-full"
                        placeholder="Description"
                        data-testid="description"
                        onChange={(e) => {
                          props.form.setFieldValue('description', e.target.value)
                          setCompanyData({ ...companyData, description: e.target.value })
                        }}
                      />
                    </>
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper>
                <Field name="employee_count">
                  {(props: FieldProps) => (
                    <>
                      <ModalInputLabel>Employees</ModalInputLabel>
                      <TextInputField
                        {...props.field}
                        className="w-full"
                        placeholder="Employee count"
                        data-testid="employees"
                        error={props.meta.touched ? props.meta.error : undefined}
                        onChange={(e) => {
                          props.form.setFieldValue('employee_count', e.target.value)
                          setCompanyData({ ...companyData, employee_count: e.target.value })
                        }}
                      />
                    </>
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>Location</ModalInputLabel>
                <Field name="location">
                  {(props: FieldProps) => (
                    <SelectWrapper>
                      <CityInput
                        {...props.field}
                        onBlur={() => {
                          props.form.setFieldTouched(props.field.name, true)
                        }}
                        onChange={(opt) => {
                          props.form.setFieldValue(props.field.name, opt?.value)
                          setCompanyData({ ...companyData, location: opt?.value })
                        }}
                        value={
                          props.field.value
                            ? { value: props.field.value, label: props.field.value }
                            : null
                        }
                        error={props.meta.touched ? props.meta.error : undefined}
                      />
                    </SelectWrapper>
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Stage </div>
                </ModalInputLabel>
                <Field name="stage">
                  {(props: FieldProps) => (
                    <SelectField
                      placeholder="Company stage"
                      fieldProps={props}
                      options={STAGE_OPTIONS}
                      onChange={(opt) => {
                        props.form.setFieldValue('stage', opt)
                        setCompanyData({ ...companyData, stage: opt })
                      }}
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Tags</div>
                </ModalInputLabel>
                <Field name="tags">
                  {(props: FieldProps) => (
                    <MultiSelectField
                      placeholder="Tags"
                      creatable
                      fieldProps={props}
                      options={[]}
                      onCreateOption={(value) => {
                        setCompanyData({ ...companyData, tags: [...companyData?.tags, value] })
                      }}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper>
                <ModalInputLabel>
                  <div className="flex flex-col">
                    <span className="block">Linkedin URL</span>
                    <CabalButton
                      variant="link"
                      padding="4px 0 0 0"
                      className="self-start"
                      onClick={(e) => {
                        e.stopPropagation()
                        window.open('https://linkedin.com/in/', '_blank', 'noopener noreferrer')
                      }}
                    >
                      Get link
                    </CabalButton>
                  </div>
                </ModalInputLabel>
                <Field name="linkedin_url">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="Linkedin URL"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="linkedin-url"
                      onChange={(e) => {
                        props.form.setFieldValue('linkedin_url', e.target.value)
                        setCompanyData({
                          ...companyData,
                          data: { ...companyData?.data, linkedin_url: e.target.value },
                        })
                      }}
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper>
                <ModalInputLabel>
                  <div className="flex flex-col">
                    <span className="block">X Profile URL</span>
                    <CabalButton
                      variant="link"
                      padding="4px 0 0 0"
                      className="self-start"
                      onClick={(e) => {
                        e.stopPropagation()
                        window.open('https://x.com', '_blank', 'noopener noreferrer')
                      }}
                    >
                      Get link
                    </CabalButton>
                  </div>
                </ModalInputLabel>
                <Field name="data.twitter_url">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      className="w-full"
                      placeholder="X.com URL"
                      error={props.meta.touched ? props.meta.error : undefined}
                      data-testid="twitter-url"
                      onChange={(e) => {
                        props.form.setFieldValue('data.twitter_url', e.target.value)
                        setCompanyData({
                          ...companyData,
                          data: { ...companyData?.data, twitter_url: e.target.value },
                        })
                      }}
                    />
                  )}
                </Field>
              </ModalInputWrapper>
            </ModalSectionWrapper>

            <div
              className={cn('flex justify-between items-center mt-7 mb-6', {
                'justify-end': !companyData,
              })}
            >
              {!!companyData && (
                <CabalButton
                  variant="tertiary"
                  disabled={isDeleting}
                  padding="0"
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteInvestorCompanyMutation()
                  }}
                >
                  Delete company
                </CabalButton>
              )}
              <CabalButton type="submit" working={updateCompanyMutation.isLoading}>
                Save
              </CabalButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CompanyInfo
