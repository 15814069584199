import React, { useState } from 'react'
import { useMutation } from 'react-query'
import CabalButton from 'global/CabalButton'
import RichTextInput from 'global/Input/RichTextInput'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import { cabalToast } from 'ui-components/Toast'
import api, { callApi } from 'utils/api'
import BorderedBox from 'components/BorderedBox'
import { PersonBlueprint } from 'utils/types'
import { TextInput } from 'global/Input'

interface Props {
  person: PersonBlueprint
  onHide: () => void
}

const InviteGlobalPersonModal: React.FC<Props> = ({ person, onHide }) => {
  const [note, setNote] = useState<string>('')
  const [email, setEmail] = useState<string>(person.email || '')

  const sendInviteMutation = useMutation(
    () => callApi(api.inviteGlobalPerson, {
      global_person_uuid: person.uuid,
      note,
      email
    }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Invite sent successfully' })
        onHide()
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Failed to send invite'
        })
      }
    }
  )

  return (
    <Modal
      onHide={onHide}
      show
      header="Invite to Cabal"
      rightActions={
        <CabalButton
          onClick={() => sendInviteMutation.mutate()}
          working={sendInviteMutation.isLoading}
          disabled={!email}
        >
          Send Invite
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Email</ModalInputLabel>
          <TextInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            required
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Message</ModalInputLabel>
          <div>
            <BorderedBox className="rounded overflow-clip">
              <RichTextInput
                value={note}
                onChange={setNote}
                minHeight={'75px'}
                placeholder="Add a personal message to your invite"
              />
            </BorderedBox>
          </div>
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default InviteGlobalPersonModal 