import React from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'
import { PersonBlueprint, CompanyListBlueprint } from 'utils/types'
import api, { callApi } from 'utils/api'
import Avatar from 'global/Avatar'
import DataCardV2 from 'ui-components/DataCardV2'
import Loading from 'global/Loading'
import Skeleton from 'react-loading-skeleton'

const OptionWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.mist};
  border: ${({ theme }) => theme.border};
`

interface AppearsInProps {
  person: PersonBlueprint
}

const AppearsIn: React.FC<AppearsInProps> = ({ person }) => {
  const history = useHistory()
  const { data: lists, isLoading } = useQuery<CompanyListBlueprint[]>(
    ['globalPersonLists', person.uuid],
    () => callApi(api.getGlobalPersonLists, { slug: person.uuid }),
    {
      enabled: !!person.uuid
    }
  )

  const handleListClick = (list: CompanyListBlueprint) => {
    history.push(`/${list.owning_team.slug}/lists/${list.slug}`)
  }

  if (isLoading) {
    return (
      <Widget title="Appears In">
        <div className="flex flex-col gap-2">
          <Skeleton className="flex-grow-0" height={37} count={3} />
        </div>
      </Widget>
    )
  }

  if (!lists?.length) {
    return (
      <Widget title="Appears In">
        <Typography fontSize="14" color="fog">
          This person does not appear in any lists.
        </Typography>
      </Widget>
    )
  }

  return (
    <Widget title="Appears In">
      <div className="flex flex-col gap-2">
        {lists.map((list) => (
          <DataCardV2
            key={list.uuid}
            onClick={() => handleListClick(list)}
            title={list.name}
            scrollTitleOnHover={true}
            avatar={
              <Avatar 
                name={list.owning_team?.name} 
                src={list.owning_team?.logo_url || list.owning_team?.avatar_url} 
                size="32" 
              />
            }
            description={
              <Typography
                color="rain_fog"
                fontSize="12"
                lineHeight={1}
                className="flex items-center gap-1.5"
              >
                {list.owning_team?.name}
              </Typography>
            }
          />
        ))}
      </div>
    </Widget>
  )
}

export default AppearsIn
