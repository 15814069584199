import React, { useEffect, useMemo, useState } from 'react'

import { faFlag } from '@fortawesome/pro-regular-svg-icons/faFlag'

import axios from 'axios'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import { useAskContextModal } from 'components/AsksContextModal'
import { useComposer } from 'components/Composer'
import { useComposeDealBoardDraft } from 'components/Composer/hooks'
import GetIntroModal from 'components/GetIntro/GetIntroModal'
import { CreateSalesListModal } from 'containers/IntroRequestModal/components/Modals/Sales/CreateSalesListModal'
import { RequestIntroConnections } from 'containers/Portfolio/MemberView/RequestIntroConnections'
import { useAccessControl } from 'global/AccessControl'
import AnimatedVoteCTA from 'global/AnimatedVoteCTA'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import { CheckBox } from 'global/Input'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useAdvisors, useTeam, useTeamSlug } from 'store/hooks'
import Icon from 'ui-components/Icon'

import api, { callApi } from 'utils/api'
import advisor from 'utils/api/advisor'
import useColorMode from 'utils/hooks/useColorMode'
import {
  CompanyBlueprint,
  ConnectionBlueprint,
  PersonBlueprint,
  RequestModel,
  UserBlueprint,
} from 'utils/types'

export const linkedinSearchUrl = (name: string) => {
  return `https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(
    name,
  )}&origin=GLOBAL_SEARCH_HEADER`
}

const ActionArea = styled.div`
  ${tw`items-center`}
`

const StyledCard = styled.div`
  ${tw`flex justify-between items-center rounded mt-0 p-0 sm:p-2`};
  background-color: ${({ theme }) => theme.colors.cardBackground};

  &:not(:hover) ${ActionArea} {
    display: none;
  }
`

interface PersonProps {
  person: PersonBlueprint
}

interface Props extends PersonProps {
  users: UserBlueprint[]
  requestable?: Pick<CompanyBlueprint, 'id' | 'uuid'>
  teamSlug: string
  request?: RequestModel
  refetch?: () => void
  hidePerson?: (person_id: number) => void
  unhidePerson?: (person_id: number) => void
  hidden?: boolean
  connections: ConnectionBlueprint[]
  personSelected: boolean
  togglePersonCheckbox?: (person: PersonBlueprint) => void
  showCheckbox?: boolean
  listUuid?: string
}

const CardLink: React.VFC<PersonProps> = ({ person }) => (
  <a
    href={person.linkedin_url}
    target="_blank"
    rel="nofollow noopener noreferrer"
    className="cursor-pointer "
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      window.open(person.linkedin_url, '_blank')
    }}
  >
    <i className="fab fa-linkedin"></i>
  </a>
)

const GlobalPerson: React.VFC<Props> = ({
  users: allUsers,
  person,
  requestable,
  teamSlug: _teamSlug,
  hidePerson,
  unhidePerson,
  hidden,
  connections,
  personSelected,
  togglePersonCheckbox,
  listUuid,
  showCheckbox = false,
}) => {
  const teamSlug = useTeamSlug() || _teamSlug
  const { compose } = useComposer(teamSlug)
  const { advisorsMapByUserUuid } = useAdvisors({ teamSlug })
  const { composeDealBoardDraft } = useComposeDealBoardDraft()
  const { isMobile } = useColorMode()
  const { showModal, closeModal } = useModal()
  const { team } = useTeam(teamSlug)
  const { isAdminOrEmployee } = useAccessControl(teamSlug)

  const showAskContextModal = useAskContextModal({
    teamSlug: teamSlug,
    source: person,
    sourceId: person.id,
    sourceType: 'GlobalPerson',
  })

  const [creatingDraft, setCreatingDraft] = useState<boolean>(false)
  const [upvoted, setUpvoted] = useState(!!person.current_user_upvoted)

  const users = useMemo(
    () =>
      Object.fromEntries(
        allUsers
          .filter(
            (u) => !!connections.find((conn) => conn.user_type == 'User' && conn.user_id === u.id),
          )
          .map((u) => [u.id, u]),
      ),
    [allUsers, connections],
  )
  const advisors = useMemo(
    () => Object.values(users).map((user) => advisorsMapByUserUuid[user.uuid]),
    [advisorsMapByUserUuid, users],
  )

  useEffect(() => {
    setUpvoted(!!person.current_user_upvoted)
  }, [person.current_user_upvoted])

  const { mutate: sendVote, isLoading: sendingVote } = useMutation(
    ['createVote'],
    (vote: 1 | 0) =>
      callApi(api.createVote, {
        team_slug: teamSlug,
        votable_type: 'GlobalPerson',
        votable_id: person.id,
        vote,
        company_uuid: requestable?.uuid,
        list_uuid: listUuid,
      }),
    {
      onSuccess: () => {
        setUpvoted(!upvoted)
      },
    },
  )

  const shareIntroDraftToAdvisor = (advisor: UserBlueprint) => {
    requestable &&
      composeDealBoardDraft({
        user_uuid: advisor.uuid,
        company_id: requestable?.id,
        teamSlug: teamSlug,
        person: person,
      })
  }

  const onPersonUpvote = () => {
    sendVote(upvoted ? 0 : 1)
  }

  const renderIntroRequestFlow: RenderModal = (resolve) => (
    <GetIntroModal
      advisors={advisors}
      person={person}
      onHide={() => resolve()}
      teamSlug={teamSlug}
      requestable={requestable}
    />
  )

  const sortedConnections = useMemo(() => {
    return connections.sort((a, b) => {
      let aw = 0
      let bw = 0

      if (a.vote != null) aw -= 10
      if (b.vote != null) bw -= 10

      if (a.sent_messages.length > 0) aw -= 5
      if (b.sent_messages.length > 0) bw -= 5

      if (a.draft_messages.length > 0) aw -= 3
      if (b.draft_messages.length > 0) bw -= 3

      if (a.user_id > b.user_id) aw -= 1
      else bw -= 1

      return aw > bw ? 1 : -1
    })
  }, [connections])
  const firstFew = useMemo(() => sortedConnections.slice(0, 3), [sortedConnections])
  const leftOver = useMemo(() => sortedConnections.slice(3), [sortedConnections])

  const companyList = useQuery(['companyList', listUuid], () =>
    callApi(api.getCompanyList, teamSlug, listUuid, false, false),
  ).data?.company_list

  const openRequestIntroConnectionsModal = (advisor?: PersonBlueprint) => {
    const personObject = {
      ...person,
      global_person_uuid: person._uuid,
      item_type: 'GlobalPerson',
    }

    showModal(
      (resolve) => (
        <RequestIntroConnections
          companyListObject={companyList}
          requestable={personObject}
          resolve={resolve}
          gatekeeperId={companyList?.gatekeeper_id}
          teamSlug={teamSlug}
          selectedAdvisor={advisor}
          companyList={companyList}
          facilitators={advisors}
        />
      ),
      'request-intro-connections-modal',
    )
  }

  const canUseInternalRequestIntro = team?.features?.internal_intro_request

  return (
    <>
      <StyledCard>
        <div style={{ opacity: hidden ? 0.5 : 'unset' }} className="w-full">
          <div
            className="flex flex-col sm:flex-row gap-4 items-top justify-between"
            data-testid="global-person-block"
          >
            <div className="flex flex-col sm:flex-row gap-4">
              {showCheckbox && (
                <div className="mt-2">
                  <CheckBox
                    checked={personSelected}
                    onChange={() => togglePersonCheckbox?.(person)}
                    data-testid="portfolio-jobs-checkbox"
                  />
                </div>
              )}

              <div className="flex flex-col gap-1">
                <div className="flex flex-col gap-2 sm:gap-0">
                  <div className="flex items-center gap-3">
                    <Typography
                      color="primary"
                      fontSize="13"
                      fontWeight={600}
                      component="button"
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        openRequestIntroConnectionsModal()
                      }}
                      className="hover:underline"
                    >
                      {person.name || person.email}
                    </Typography>
                    <Typography
                      className="flex items-center gap-2"
                      color="fog_rain"
                      fontSize="10"
                      component="div"
                    >
                      {person.current_user_connected && <span>1st</span>}
                      {person.linkedin_url && <CardLink person={person}></CardLink>}

                      {!person.linkedin_url && (
                        <Typography fontSize="10" color="fog_rain" className="cursor-pointer">
                          <a
                            href={linkedinSearchUrl(person.name)}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            className={'cursor-pointer'}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <i className="fab fa-linkedin fa-fw"></i>
                          </a>
                        </Typography>
                      )}

                      {person.email && <i className="fab fa-google"></i>}
                    </Typography>
                    {person.has_unreviewed_revision && (
                      <Tooltip label="This record has been updated and is under review.">
                        <Typography color="fog_rain" fontSize="10" component="div">
                          <Icon
                            icon={faFlag}
                            className="w-2 h-2"
                            data-testid="person-flagged-as-inaccurate-icon"
                          />
                        </Typography>
                      </Tooltip>
                    )}
                  </div>
                  {person.headline && (
                    <Typography color="fog_rain" fontSize="13" component="div">
                      {person.headline}
                    </Typography>
                  )}
                </div>

                <div>
                  <div className="flex flex-wrap gap-1">
                    <Typography
                      component="button"
                      color="link"
                      data-testid="connection-advisors-link"
                    >
                      <i className="far fa-arrows-left-right" />{' '}
                      {firstFew.map((conn, i) => {
                        const advisor = users[conn.user_id]
                        return (
                          <span
                            key={`connection-${conn.uuid}`}
                            onClick={() => openRequestIntroConnectionsModal(advisor)}
                          >
                            {advisor &&
                              (advisor.last_name && advisor.first_name
                                ? `${advisor.first_name} ${advisor.last_name?.charAt(0)}.`
                                : `${advisor.name}`)}
                            {!!leftOver.length && i < firstFew.length - 1 && ', '}
                            {!leftOver.length && i < firstFew.length - 2 && ', '}
                            {!leftOver.length && i === firstFew.length - 2 && ' and '}
                          </span>
                        )
                      })}
                      {leftOver.length === 1 && <>, and {leftOver.length} other</>}
                      {leftOver.length > 1 && <>, and {leftOver.length} others</>}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-shrink-0 gap-1">
              {person.current_user_connected && (
                <>
                  <AnimatedVoteCTA
                    selectedText="Offered"
                    unselectedText="Offer Intro"
                    selected={upvoted}
                    onClick={onPersonUpvote}
                  />

                  {isAdminOrEmployee && (
                    <DropDownMenu
                      menuItems={[
                        {
                          label: 'Request Intro',
                          onSelect: () => openRequestIntroConnectionsModal(),
                        },
                      ]}
                      trigger={
                        <Typography
                          color={'fog'}
                          className="px-3"
                          data-testid="datacard-dropdown-menu-trigger"
                        >
                          <i className="far fa-ellipsis-v"></i>
                        </Typography>
                      }
                      dataTestId="get-intro-dropdown"
                    />
                  )}
                </>
              )}

              {!person.current_user_connected && (
                <div>
                  {isAdminOrEmployee && canUseInternalRequestIntro ? (
                    <CabalButton
                      variant="link"
                      working={creatingDraft}
                      onClick={() => openRequestIntroConnectionsModal()}
                      data-testid="person-get-intro-button"
                      padding="0"
                    >
                      Request Intro
                    </CabalButton>
                  ) : (
                    <CabalButton
                      variant="link"
                      working={creatingDraft}
                      onClick={() => showModal(renderIntroRequestFlow, 'render_intro_request_flow')}
                      data-testid="person-get-intro-button"
                      padding="0"
                    >
                      Get intro
                    </CabalButton>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </StyledCard>
    </>
  )
}

export default GlobalPerson
