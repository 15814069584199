import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { useQuery } from 'react-query'
import Skeleton from 'react-loading-skeleton'

import PageWrapper from 'global/PageWrapper'
import { useGlobalPerson } from '../../hooks/useGlobalPerson'
import api, { callApi } from 'utils/api'

import About from './About'
import Activity from './Activity'
import Agreements from './Agreements'
import AppearsIn from './AppearsIn'
import Connections from './Connections'
import Education from './Education'
import Experience from './Experience'
import Files from './Files'
import Header from './Header'
import Notes from './Notes'
import Portfolio from './Portfolio'
import Preferences from './Preferences'
import ProfileFilters from './ProfileFilters'
import Loading from 'global/Loading'

interface Permissions {
  profile_owner: boolean
  has_candidate_profile: boolean
  has_about: boolean
}

const GlobalUserProfile = () => {
  const { slug } = useParams()
  const [filter, setFilter] = useState('')
  const [parent] = useAutoAnimate()
  
  const { data: person, isLoading: personLoading, error: personError } = useGlobalPerson(slug)
  const { data: permissions, isLoading: permissionsLoading, error: permissionsError } = useQuery<Permissions>(
    ['globalPersonPermissions', slug],
    () => callApi(api.getGlobalPersonPermissions, { slug }),
    {
      enabled: !!slug
    }
  )

  const isLoading = personLoading || permissionsLoading

  const isVisible = (sectionFilter: string) => {
    return filter === '' || filter === sectionFilter
  }

  if (isLoading) {
    return (
      <PageWrapper fullWidth={true} title="Profile" className="mb-12">
        <div className="flex flex-col gap-6">
          {/* Header skeleton */}
          <div className="flex items-center justify-between">
            <div className="flex">
              <Skeleton circle width={72} height={72} />
              <div className="flex flex-col ml-3">
                <Skeleton width={200} height={36} />
                <Skeleton width={300} height={20} className="mt-1" />
              </div>
            </div>
          </div>
          
          {/* Filters skeleton */}
          <div className="flex gap-2">
            <Skeleton width={80} height={32} count={4} inline />
          </div>
        </div>

        {/* Content skeleton */}
        <div className="mt-4 flex flex-col lg:flex-row gap-4">
          <div className="flex flex-col flex-1 gap-4 max-w-[630px]">
            <Skeleton height={200} count={3} className="mb-4" />
          </div>
          <div className="lg:w-80 flex-shrink-0 flex flex-col gap-4">
            <Skeleton height={150} count={2} />
          </div>
        </div>
      </PageWrapper>
    )
  }

  if (personError || permissionsError) {
    return <div>Error loading profile</div>
  }

  return (
    <PageWrapper fullWidth={true} title="Profile" className="mb-12">
      <div className="flex flex-col gap-6">
        <Header person={person} canEdit={permissions?.profile_owner || false} />
        <ProfileFilters filter={filter} onSetFilter={setFilter} />
      </div>
      <div className="mt-4 flex flex-col lg:flex-row gap-4">
        <div ref={parent} className="flex flex-col flex-1 gap-4 max-w-[630px]">
          {isVisible('about') && (permissions?.has_about || permissions?.profile_owner) && <About canEdit={permissions?.profile_owner || false} />}
          {isVisible('prefrences') && (permissions?.has_candidate_profile || permissions?.profile_owner) 
          && <Preferences canEdit={permissions?.profile_owner || false} hasCandidateProfile={permissions?.has_candidate_profile || false}/>}
          {isVisible('experience') && <Experience canEdit={permissions?.profile_owner || false}/>}
          {isVisible('education') && <Education canEdit={permissions?.profile_owner || false}/>}
          {/* {isVisible('agreements') && <Agreements />} */}
          {isVisible('notes') && !permissions?.profile_owner && <Notes />}
          {isVisible('files') && <Files canEdit={permissions?.profile_owner || false} />}
          {/* {isVisible('connections') && <Connections />} */}
          {/* {isVisible('portfolio') && <Portfolio />} */}
        </div>
        <div className="lg:w-80 flex-shrink-0 flex flex-col gap-4">
          {permissions?.profile_owner && <Activity personUuid={person?.uuid} />}
          <AppearsIn person={person} />
        </div>
      </div>
    </PageWrapper>
  )
}

export default GlobalUserProfile
