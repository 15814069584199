import React, { useEffect } from 'react'

import { History } from 'history'
import Cookies from 'js-cookie'
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
  useParams,
} from 'react-router-dom'
import { useSearchParam } from 'react-use'

import Activity from 'components/Activity'
import AppLoading from 'components/AppLoading'
import CompanyHome from 'components/CompanyHome'
import LikesTrackerPage from 'components/LikesTracker/Page'
import MemberOnboardingPage from 'components/MemberOnboardingPage'
import { Winsite } from 'components/Winsite'
import { CompanyLandingPage } from 'components/Winsite/CompanyLandingPage'
import AcceptJoinRequest from 'containers/AcceptJoinRequest'
import AddConnectionsPage from 'containers/AddConnectionsPage'
import AdvisorInviteAccept from 'containers/AdvisorInviteAccept'
import AdvisorOffer from 'containers/AdvisorOffer'
import Analytics from 'containers/Analytics'
import Apps from 'containers/Apps'
import AddConnections from 'containers/Asks/ConnectionStatus/AddConnections'
import Blocks from 'containers/Blocks'
import Calendar from 'containers/Calendar'
import CandidateProfile from 'containers/CandidateProfile'
import CandidateApply from 'containers/CandidateProfileModal/CandidateApply'
import CompanyAdmin from 'containers/CompanyAdmin'
import CompanyHomeV2 from 'containers/CompanyHomeV2'
import CompanyPage from 'containers/CompanyPage'
import Compose from 'containers/Compose'
import Lists from 'containers/Connections/Lists'
import Contacts from 'containers/Contacts'
import Demo from 'containers/Demo'
import Documents from 'containers/Documents'
import Explore from 'containers/Explore'
import FAQ from 'containers/FAQ'
import Feed from 'containers/Feed'
import GlobalMessagesPage from 'containers/GlobalMessagesPage'
import GlobalProfile2 from 'containers/GlobalProfile'
import GlobalUserProfile from 'containers/GlobalUserProfile'
import Groups from 'containers/Groups'
import Home from 'containers/Home'
import ImportMembers from 'containers/ImportMembers'
import IntroRequestPublic from 'containers/IntroRequestPublic'
import AcceptRequest from 'containers/IntroRequests/AcceptRequest'
import AdvisorRequestConfirmation from 'containers/IntroRequests/AdvisorRequestConfirmation'
import DeclineRequest from 'containers/IntroRequests/DeclineRequest'
import RequestAccepted from 'containers/IntroRequests/RequestAccepted'
import RequestConfirmation from 'containers/IntroRequests/RequestConfirmation'
import RequestDeclined from 'containers/IntroRequests/RequestDeclined'
import RequestSent from 'containers/IntroRequests/RequestSent'
import RerouteRequest from 'containers/IntroRequests/RerouteRequest'
import ItemDetailPage from 'containers/ItemDetailPage'
import ListIndexView from 'containers/ListIndexView'
import DiscountDetailPage from 'containers/ListIndexView/ResourcesList/ResourceDiscountList/DiscountDetailPage'
import ListInvite from 'containers/ListInvite'
import ListView from 'containers/ListView'
import LoginPage from 'containers/LoginPage'
import ManageDocument from 'containers/ManageDocument'
import MemberAddList from 'containers/MemberAddList'
import Members from 'containers/Members'
import MessagesPage from 'containers/MessagesPage'
import EditTemplate from 'containers/MessagesPage/Templates/EditTemplate'
import MyConnections from 'containers/MyConnections'
import NavHome from 'containers/NavHome'
import OnboardingV2 from 'containers/OnboardingV2'
import PersonDetailPage from 'containers/PersonDetailPage'
import Portfolio from 'containers/Portfolio'
import PrepareDocument from 'containers/PrepareDocument'
import Profile from 'containers/Profile'
import Referral from 'containers/Referral'
import ReferralPage from 'containers/ReferralPage'
import { ManageRequestCenter as RequestCenterIndex } from 'containers/RequestCenter/Index'
import { Index as IntroRequestDetails } from 'containers/RequestCenter/IntroRequestDetails/index'
import Resources from 'containers/Resources'
import ProposedResources from 'containers/Resources/ProposedResources'
import ResourceListViewPage from 'containers/Resources/ResourceListViewPage'
import ResourceViewPage from 'containers/Resources/ResourceViewPage'
import SendAgreement from 'containers/SendAgreement'
import Settings from 'containers/Settings'
import Sign from 'containers/Sign'
import SignSuccessAdvisor from 'containers/SignSuccessAdvisor'
import SignSuccessFounder from 'containers/SignSuccessFounder'
import SignupPage from 'containers/SignupPage'
import LearnIndex from 'containers/SignupScreen/Content'
import PostPage from 'containers/SignupScreen/Content/postPage'
import MarketingSite from 'containers/SignupScreen/MarketingSite'
import CompaniesPage from 'containers/SignupScreen/MarketingSite/CompaniesPage'
import InvestorsPage from 'containers/SignupScreen/MarketingSite/InvestorsPage'
import Privacy from 'containers/SignupScreen/Policy/privacy'
import Terms from 'containers/SignupScreen/Policy/terms'
import GetResourcePage from 'containers/SignupScreen/ResourcesPage/GetResourcePage'
import ResourceV2 from 'containers/SignupScreen/ResourcesPage/ResourceV2'
import SolutionsPage from 'containers/SignupScreen/SolutionsPage'
import UniqueAccessLink from 'containers/UniqueAccessLinks'
import ViewAdvisor from 'containers/ViewAdvisor'
import IndeterminantTeamPage from 'containers/ViewCompany/IndeterminantTeamPage'
import ViewDocument from 'containers/ViewDocument'
import ViewExternalSource from 'containers/ViewExternalSource'
import ViewInboxThread from 'containers/ViewInboxThread'
import ViewMessage from 'containers/ViewMessage'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import Modal, { useModal } from 'global/Modal'
import { BeforeInstallPromptEvent } from 'global/window'
import { useAppSelector, useCurrentUser, useCurrentUserSettings, useTeam } from 'store/hooks'
import { useUniqueAccessLink } from 'store/hooks/uniqueAccessLink'
import {
  selectCurrentUserLoading,
  selectCurrentUserSettingLoading,
} from 'store/reducers/currentUserReducer'
import { selectTeams } from 'store/reducers/teamsReducer'

import { handleExtensionReferral } from 'utils/extension'
import useAnalyticsIdentify from 'utils/hooks/useAnalyticsIdentify'
import useColorMode from 'utils/hooks/useColorMode'
import usePageTracking from 'utils/hooks/usePageTracking'
import { useSlug } from 'utils/hooks/useSlug'
import useUtmSource from 'utils/hooks/useUtmSource'
import { jwtManager } from 'utils/jwtManager'
import { CompanySlugParam, CurrentUserProfile } from 'utils/types'

import Layout from './Layout'
import MainContainer from './MainContainer'

const getRootPath = (user: Partial<CurrentUserProfile>, isMobile: boolean) => {
  if (!user) return '/'

  const postLoginReturnTo = localStorage.getItem('postLoginReturnTo')

  if (postLoginReturnTo) {
    localStorage.removeItem('postLoginReturnTo')
    return postLoginReturnTo
  }

  if (!user.name || !user.did_onboarding) {
    return '/onboarding_v2'
  } else if (user.did_onboarding) {
    if (user.team) {
      return `/${user.team.slug}`
    } else {
      return '/home'
    }
  } else {
    if (isMobile) {
      return '/feed'
    } else {
      return `/profile`
    }
  }
}

const slugRegex = `[a-zA-Z0-9-]+`
const uuidRegex = `[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}`

interface ProtectedRouteProps {
  wrapWithMainContainer?: boolean
  redirectToLoginIfNotLoggedIn?: boolean
  redirectToPathIfNotLoggedIn?: string | ((pathname: string) => string | null) | null
}

function RenderProtectedRoute(props: {
  routeComponentProps: RouteComponentProps
  restProps: RouteProps & ProtectedRouteProps
}) {
  const { user, isLoggedIn } = useCurrentUser()
  const { isUniqueAccessLink, isLoadingUniqueAccessLink } = useUniqueAccessLink()
  const { company_slug } = useParams<CompanySlugParam>()
  const { teamSlug, slugType } = useSlug()
  const { team } = useTeam(teamSlug)
  const { canViewTeam } = useAccessControl(teamSlug)

  const {
    restProps: {
      component: Component,
      render,
      wrapWithMainContainer = !!isLoggedIn,
      redirectToLoginIfNotLoggedIn = true,
      redirectToPathIfNotLoggedIn = null,
    },
    routeComponentProps,
  } = props

  let children: React.ReactElement = <></>
  if (Component || render) {
    // set children as the children or render prop result
    children = (
      Component ? <Component {...routeComponentProps} /> : render!(routeComponentProps)
    ) as React.ReactElement
  }

  const redirectPath =
    typeof redirectToPathIfNotLoggedIn === 'function'
      ? redirectToPathIfNotLoggedIn(routeComponentProps.location.pathname)
      : redirectToPathIfNotLoggedIn

  if (
    isLoggedIn &&
    user.candidate_onboarding_url &&
    user.force_onboarding &&
    !user.has_candidate_profile &&
    window.location.href !== user.candidate_onboarding_url
  ) {
    window.location.href = user.candidate_onboarding_url
    return
  } else if (!isLoggedIn && redirectPath) {
    // cannot use the redirect component because it will not work with the ahoy messages tracking url.
    // the redirect component does not work with some browsers (e.g. chrome on mac)
    // after the first server-side redirect. instead we just set the window location to start from a clean state.
    window.location.href = redirectPath
    return
  } else if (company_slug) {
    const isIntroRequestPage =
      routeComponentProps.match.path === '/:company_slug/lists/:list_id/:item_id' &&
      new URLSearchParams(routeComponentProps.location.search).get('intro') === 'true'

    if (
      team &&
      slugType === 'Team' &&
      !canViewTeam &&
      !isIntroRequestPage &&
      redirectToLoginIfNotLoggedIn &&
      !isUniqueAccessLink
    ) {
      children = <IndeterminantTeamPage teamSlug={team.slug} />
    }
  } else if (!isLoggedIn && !isUniqueAccessLink && !isLoadingUniqueAccessLink) {
    children = (
      <Redirect to={{ pathname: '/login', state: { from: routeComponentProps.location } }} />
    )
  }

  if (wrapWithMainContainer) {
    children = <MainContainer>{children}</MainContainer>
  }

  return children
}

const ProtectedRoute: React.FC<RouteProps & ProtectedRouteProps> = ({
  component,
  render,
  ...rest
}) => {
  const { isLoggedIn } = useCurrentUser()

  if (!isLoggedIn && !Cookies.get('return_to'))
    Cookies.set('return_to', window.location.href, { expires: 365 })

  return (
    <Route
      {...rest}
      render={(props) => (
        <RenderProtectedRoute
          routeComponentProps={props}
          restProps={{ ...rest, component, render }}
        />
      )}
    />
  )
}

interface Props {
  history: History
}

const Routes: React.FC<Props> = (prop) => {
  const { history } = prop
  const { user, isLoggedIn } = useCurrentUser()
  const { settingsLoaded } = useCurrentUserSettings()
  const userLoading = useAppSelector(selectCurrentUserLoading)
  const { isUniqueAccessLink } = useUniqueAccessLink()
  const settingsLoading = useAppSelector(selectCurrentUserSettingLoading)
  const { isMobile } = useColorMode()
  const { utmSource, clearUtmSource } = useUtmSource()
  const deferredAddToHomePrompt = React.useRef<BeforeInstallPromptEvent | null>(null)
  const { showModal } = useModal()
  const teams = useAppSelector(selectTeams)
  const candidateInviteLink = useSearchParam('candidates_invite_link')
  const candidateOwnerId = useSearchParam('owner_uuid')
  const domain = window.location.hostname
  const winsiteDomainRegex = /^win\.getcabal\.(com|xyz|test)$/
  const isDomainMatch = winsiteDomainRegex.test(domain)

  const rootPath =
    (isLoggedIn && settingsLoaded ? getRootPath(user, isMobile) : '') + history.location.search

  usePageTracking(history)
  useAnalyticsIdentify(user, isLoggedIn)

  useEffect(() => {
    if (user && isLoggedIn) {
      window.analytics?.track('app_loaded')
    }
  }, [user, isLoggedIn])

  useEffect(() => {
    if (!utmSource || utmSource !== 'app-page' || !isMobile) return

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault()
      // Stash the event so it can be triggered later.
      deferredAddToHomePrompt.current = e

      clearUtmSource()

      showModal(
        (resolve) => (
          <Modal
            show
            size="sm"
            onHide={resolve}
            rightActions={
              <CabalButton
                onClick={() => {
                  deferredAddToHomePrompt.current?.prompt()
                }}
                leftIcon={<i className="fas fa-cloud-download-alt" />}
              >
                Install
              </CabalButton>
            }
            header={'Add Cabal to your home screen'}
          >
            Offer intros, boost big news, and help close influential hires. All from one app.
          </Modal>
        ),
        'pwa-install-prompt',
      )
    })
  }, [utmSource])

  useEffect(() => {
    if (user?.uuid) jwtManager.init()
    if (location.search.includes('extension')) handleExtensionReferral(history, showModal)
  }, [user?.uuid])

  if (history.location.pathname == '/add_connections') {
    return <AddConnections />
  }

  if (userLoading || settingsLoading) {
    // if you remove this make sure you fire `pageLoaded` event to react native webview somewhere
    return <AppLoading />
  }

  if (isDomainMatch) {
    return (
      <Layout history={history}>
        <Switch>
          <Route exact path="/" component={Winsite} />
          <Route exact path="/:company_slug" component={CompanyLandingPage} />
        </Switch>
      </Layout>
    )
  }

  if (!isLoggedIn) {
    const is_splash_screen = history.location.pathname == '/' || history.location.pathname == ''

    if (is_splash_screen) {
      return <MarketingSite />
    }
  }

  if (history.location.pathname == '/signup' || history.location.pathname == '/signup/') {
    return <SignupPage />
  }

  const viewingTeam = teams.filter((team) =>
    history.location.pathname.startsWith(`/${team.slug}`),
  )[0]

  const isCandidateAdvisor = viewingTeam?.advisor?.role === 'candidate'
  const showCandidateOnboarding =
    (isCandidateAdvisor || candidateInviteLink || candidateOwnerId) && !user.has_candidate_profile

  return (
    <Layout history={history} onHome={() => rootPath && history.push(rootPath)}>
      <Switch>
        {isLoggedIn && <Redirect exact from="/" to={rootPath} />}
        <Redirect exact from="/members" to={user.team ? `/${user.team?.slug}/members` : '/home'} />
        <Redirect exact from="/lists" to={user.team ? `/${user.team?.slug}/lists` : '/home'} />
        <Redirect
          exact
          from="/intros"
          to={user.team ? `/${user.team?.slug}/intro-center` : '/home'}
        />
        {isLoggedIn && showCandidateOnboarding && (
          <ProtectedRoute
            path={['/:company_slug/*', '/:company_slug']}
            component={MemberOnboardingPage}
            wrapWithMainContainer={false}
          />
        )}

        <Route exact path="/demo" component={Demo} />
        <Route exact path="/referrals/:id" component={ReferralPage} />
        <Route exact path="/refer/:id" component={ReferralPage} />

        <ProtectedRoute exact path="/invite/:id" component={AdvisorInviteAccept} />
        <ProtectedRoute exact path="/sign/:id" component={Sign} />
        <Route exact path="/docs/:id" component={ViewDocument} />
        <ProtectedRoute exact path="/sign/:id/complete" component={SignSuccessAdvisor} />
        <ProtectedRoute exact path="/sign/:id/founder_complete" component={SignSuccessFounder} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/signup" component={SignupPage} />

        <Route exact path="/users/sign_in" component={LoginPage} />
        <Route exact path="/onboarding_v2" component={OnboardingV2} />

        <Route exact path={['/learn', '/blog']} component={LearnIndex} />
        <Route exact path={['/learn/:slug', '/blog/:slug']} component={PostPage} />
        <Route exact path="/solutions" component={SolutionsPage} />
        <Route exact path="/resources" component={ResourceV2} />

        <Route exact path="/companies" component={CompaniesPage} />
        <Route exact path="/investors" component={InvestorsPage} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/links/:uuid" component={UniqueAccessLink} />
        <Route exact path="/intro-requests/:secret_uuid/accepted" component={RequestAccepted} />
        <Route exact path="/intro-requests/:secret_uuid/declined" component={RequestDeclined} />

        <ProtectedRoute
          exact
          path="/:company_slug/intro-requests/:uuid"
          component={IntroRequestDetails}
          redirectToLoginIfNotLoggedIn={true}
        />
        <ProtectedRoute
          exact
          path="/:company_slug/intro-center"
          component={RequestCenterIndex}
          redirectToLoginIfNotLoggedIn={true}
        />
        <ProtectedRoute
          exact
          path="/intro-requests/:secret_uuid/reroute"
          component={RerouteRequest}
          wrapWithMainContainer={false}
        />
        <ProtectedRoute
          exact
          path="/intro-requests/:secret_uuid/accept"
          component={AcceptRequest}
          wrapWithMainContainer={false}
        />
        <ProtectedRoute
          exact
          path="/intro-requests/:secret_uuid/decline"
          component={DeclineRequest}
          wrapWithMainContainer={false}
        />
        <ProtectedRoute
          exact
          path="/v2/intro-requests/:secret_uuid/reroute"
          component={RerouteRequest}
          wrapWithMainContainer={false}
        />
        <ProtectedRoute
          exact
          path="/v2/intro-requests/:secret_uuid/accept"
          component={AcceptRequest}
          wrapWithMainContainer={false}
        />
        <ProtectedRoute
          exact
          path="/v2/intro-requests/:secret_uuid/decline"
          component={DeclineRequest}
          wrapWithMainContainer={false}
        />
        <Route exact path="/intro-requests/:secret_uuid/sent" component={RequestSent} />
        <ProtectedRoute
          exact
          path="/investor-companies/:company_uuid/intro-requests/confirm"
          component={RequestConfirmation}
          wrapWithMainContainer={false}
        />
        <ProtectedRoute
          exact
          path="/advisors/:advisor_uuid/intro-requests/confirm"
          component={AdvisorRequestConfirmation}
          wrapWithMainContainer={false}
        />
        <ProtectedRoute exact path="/get-resource/:category/:index" component={GetResourcePage} />
        <ProtectedRoute exact path="/blocks/:block_id?" component={Blocks} />
        <ProtectedRoute exact path="/feed" component={Feed} />
        <ProtectedRoute exact path="/updates" component={Feed} />
        <ProtectedRoute exact path="/dealboards" component={Feed} />
        <ProtectedRoute exact path="/user/:slug" component={GlobalUserProfile} />

        <ProtectedRoute exact path="/not-found" render={() => <>404: Not found</>} />
        <ProtectedRoute exact path="/faq" component={FAQ} />
        <ProtectedRoute exact path="/home" component={Home} />

        <ProtectedRoute exact path="/referral" component={Referral} />
        <ProtectedRoute exact path="/refer" component={Referral} />
        <ProtectedRoute exact path={['/profile/edit']} component={Settings} />
        <ProtectedRoute exact path={['/p', '/profile']} component={GlobalProfile2} />
        <Route exact path="/add-connections" component={AddConnectionsPage} />
        <Route exact path="/addconnections" component={AddConnectionsPage} />
        <Route exact path="/shareconnections" component={AddConnectionsPage} />
        <Route exact path="/:company_slug/add-connections" component={AddConnectionsPage} />
        <Route exact path="/:company_slug/addconnections" component={AddConnectionsPage} />
        <Route exact path="/:company_slug/shareconnections" component={AddConnectionsPage} />
        <Route exact path="/connections" component={AddConnectionsPage} />
        <ProtectedRoute exact path="/contacts" component={Contacts} />
        <ProtectedRoute exact path="/u/:id" component={Profile} />
        <ProtectedRoute
          exact
          path="/:company_slug/prepare/:id"
          component={PrepareDocument}
          wrapWithMainContainer={false}
        />
        <ProtectedRoute path="/:company_slug/admin/:tab?" component={CompanyAdmin} />
        <ProtectedRoute path="/:company_slug/external_sources/:id" component={ViewExternalSource} />

        <ProtectedRoute
          exact
          path={[
            '/messages/all',
            '/messages/archive',
            '/messages/inbox',
            '/messages/my_drafts',
            '/messages/sent',
            '/messages/templates',
            '/messages/groups',
          ]}
          component={GlobalMessagesPage}
          redirectToLoginIfNotLoggedIn={true}
        />
        <ProtectedRoute exact path="/explore" component={Explore} />

        <ProtectedRoute
          exact
          path="/:company_slug/messages/:message_id/:message_thread_id?"
          component={ViewMessage}
        />

        <ProtectedRoute exact path="/:company_slug/inbox/:thread_id" component={ViewInboxThread} />

        <ProtectedRoute
          exact
          path={[
            '/:company_slug/drafts/:tab?',
            '/:company_slug/messages',
            '/:company_slug/templates',
            '/:company_slug/inbox',
          ]}
          component={MessagesPage}
          redirectToLoginIfNotLoggedIn={true}
        />

        <ProtectedRoute
          exact
          path={['/:company_slug/templates/new', '/:company_slug/templates/:id']}
          component={EditTemplate}
        />

        <ProtectedRoute exact path="/:company_slug" component={NavHome} />
        <ProtectedRoute exact path="/:person_slug/connections" component={MyConnections} />
        <Route exact path="/share-list/:team_slug/:crmOrListId?" component={MemberAddList} />

        <ProtectedRoute exact path="/list-invite/:uuid" component={ListInvite} />

        <ProtectedRoute exact path="/:company_slug/people" component={Members} />
        <ProtectedRoute exact path="/:company_slug/apps" component={Apps} />
        <ProtectedRoute exact path="/:company_slug/apply" component={CandidateApply} />
        <ProtectedRoute exact path="/:company_slug/home" component={NavHome} />
        <ProtectedRoute exact path="/:company_slug/home_v2" component={CompanyHomeV2} />

        <ProtectedRoute exact path="/:company_slug/home/:tag" component={CompanyHome} />

        <ProtectedRoute exact path="/:company_slug/page" component={CompanyPage} />
        <ProtectedRoute exact path="/:company_slug/groups" component={Groups} />
        <ProtectedRoute exact path="/:company_slug/members" component={Members} />
        <ProtectedRoute exact path="/:company_slug/groups/:id" component={Groups} />
        <ProtectedRoute exact path="/:company_slug/groups/:id" component={IndeterminantTeamPage} />
        <ProtectedRoute exact path="/:company_slug/resources" component={Resources} />
        <ProtectedRoute
          exact
          path="/:company_slug/resources/proposed"
          component={ProposedResources}
        />
        <ProtectedRoute
          exact
          path={[`/:company_slug/resources/:slug`]}
          component={ResourceListViewPage}
        />
        <ProtectedRoute
          exact
          path={[`/:company_slug/resources/:resource_type/:id(${uuidRegex})`]}
          component={ResourceViewPage}
        />

        <ProtectedRoute path={`/:company_slug/portfolio`} component={Portfolio} />
        <ProtectedRoute path={`/:company_slug/portfolio/companies`} component={Portfolio} />
        <ProtectedRoute path={`/:company_slug/portfolio/jobs`} component={Portfolio} />
        <ProtectedRoute path={`/:company_slug/jobs`} component={Portfolio} />
        <ProtectedRoute path={`/:company_slug/lists/jobs`} component={Portfolio} />
        <ProtectedRoute path={`/:company_slug/portfolio/activity`} component={Portfolio} />

        <ProtectedRoute
          path={`/:company_slug/portfolio/:tab?`}
          redirectToLoginIfNotLoggedIn={!isUniqueAccessLink}
          component={Portfolio}
        />
        <ProtectedRoute
          exact
          path={['/:company_slug/join_requests/:id/accept', '/:company_slug/join_requests/:id']}
          component={AcceptJoinRequest}
        />
        <ProtectedRoute path={`/:company_slug/person/:id`} component={PersonDetailPage} />
        <ProtectedRoute exact path="/:company_slug/analytics" component={Analytics} />
        <ProtectedRoute exact path="/:company_slug/calendar" component={Calendar} />
        <ProtectedRoute exact path="/:company_slug/documents" component={Documents} />
        <ProtectedRoute exact path="/:company_slug/documents/:id" component={ManageDocument} />
        <ProtectedRoute exact path="/:company_slug/lists" component={Lists} />
        <ProtectedRoute exact path="/:company_slug/blocks/:block_id?" component={Blocks} />
        <ProtectedRoute exact path="/:company_slug/blocks/:block_id/:item_id" component={Blocks} />
        <ProtectedRoute exact path="/:company_slug/candidates/:uuid" component={CandidateProfile} />

        <ProtectedRoute exact path="/:company_slug/items/:item_id" component={ItemDetailPage} />
        <ProtectedRoute
          path="/:company_slug/lists/:list_id/:item_id"
          component={ItemDetailPage}
          redirectToPathIfNotLoggedIn={(pathname) => {
            const searchParams = new URLSearchParams(window.location.search)
            const showIntro = searchParams.get('intro')
            if (showIntro === 'true') {
              const facilitatorKey = searchParams.get('facilitator_key')
              if (facilitatorKey) {
                return `/intro-requests${pathname.replace(
                  '/lists',
                  '',
                )}?facilitator_key=${facilitatorKey}`
              } else {
                return `/intro-requests${pathname.replace('/lists', '')}`
              }
            }
            return null
          }}
        />
        <Route
          exact
          path={[
            `/intro-requests/:company_slug/:list_id/:item_id(${uuidRegex})`,
            `/intro-requests/:company_slug/:list_id/:item_id/success/:secret_uuid(${uuidRegex})`,
          ]}
          component={IntroRequestPublic}
        />
        <ProtectedRoute path={`/:company_slug/lists/:list_id(${slugRegex})`} component={ListView} />
        <ProtectedRoute
          path={`/:company_slug/lists_v2/:list_id(${slugRegex})`}
          component={ListIndexView}
        />
        <ProtectedRoute path="/:company_slug/lists_v2/item_detail" component={DiscountDetailPage} />
        <ProtectedRoute path="/:company_slug/activity" component={Activity} />
        <ProtectedRoute
          path={['/:company_slug/tracker', '/:company_slug/offers']}
          component={LikesTrackerPage}
        />

        <ProtectedRoute path="/:company_slug/asks/:tag?" component={CompanyHome} />
        <ProtectedRoute exact path="/:company_slug/compose/:id?" component={Compose} />

        <ProtectedRoute exact path="/:company_slug/agreements/new/:id?" component={SendAgreement} />
        <ProtectedRoute
          exact
          path={`/:company_slug/people/:id(${uuidRegex})/:tab?`}
          component={ViewAdvisor}
        />
        <ProtectedRoute
          exact
          path={`/:company_slug/members/:id(${uuidRegex})/:tab?`}
          component={ViewAdvisor}
        />

        <ProtectedRoute exact path="/:company_slug/import/:tab?" component={ImportMembers} />
        <ProtectedRoute exact path="/:company_slug/advisor-offer" component={AdvisorOffer} />
      </Switch>
    </Layout>
  )
}

export default Routes
