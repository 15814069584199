import React from 'react'

import cx from 'classnames'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import SearchBlock from 'components/CompanyHome/SearchBlock'
import { EditListModal } from 'components/EditList'
import ListSharingSettings from 'components/EditList/EditListAccess/ListSharingSettings'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { RenderModal, useModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

import { CompanyListType } from 'utils/types'

import CompanyCard from './CompanyCard'

interface Props {
  list: CompanyListType
  teamSlug: string
  refetchLists?: () => void
  data: any
  people: any
}

const Wrapper = styled.div`
  .title {
    &:hover {
      color: ${({ theme }) => theme.colors.purple};
    }
  }
`

const ListSection: React.FC<Props> = ({ list, teamSlug, refetchLists, data, people }) => {
  const gridView = false
  const history = useHistory()
  const { isAdmin } = useAccessControl(teamSlug)
  const { showModal } = useModal()
  const [limit, setLimit] = React.useState(gridView ? 4 : 3)
  const listConnectionType = list.default_type_identifier == 'connections'
  const queryClient = useQueryClient()

  const sortedData = (data?.items_with_matches || [])
    .concat(data?.items_without_matches || [])
    .sort((a, b) => b.connected_count - a.connected_count)
  const companies = sortedData.slice(0, limit)

  const useStaticCount = list.is_talent || list.list_category === 'resources' || list.portfolio

  const renderEditListModal: RenderModal = (resolve) => (
    <EditListModal
      teamSlug={teamSlug}
      onHide={() => resolve(false)}
      reload={() => refetchLists?.()}
      onSubmit={() => resolve(false)}
      listUuid={list.uuid}
      isPortfolioList={!!list.portfolio}
    />
  )

  const renderShareListModal: RenderModal = (resolve) => (
    <ListSharingSettings
      teamSlug={teamSlug}
      resolve={() => resolve(false)}
      uuid={list.uuid}
      reload={() => refetchLists?.()}
      header={`Share ${list.name}`}
    />
  )

  return (
    <Wrapper id={list.slug} className="group">
      <Widget
        key={list.uuid}
        title={
          <span className="flex flex-row items-center gap-2">
            <Typography
              className="flex items-center cursor-pointer title"
              component="button"
              fontWeight={600}
              onClick={() => {
                queryClient.removeQueries()
                history.push(`/${teamSlug}/lists/${list.slug}`)
              }}
            >
              {list.default_type_identifier == 'connections' ? 'Request Intro' : list.name}
            </Typography>
          </span>
        }
        cta={
          isAdmin && (
            <div className="items-center gap-4 hidden group-hover:flex transition-opacity duration-300">
              {!listConnectionType && !list.default_type_identifier && (
                <CabalButton
                  variant="link"
                  onClick={() => showModal(renderEditListModal, 'render_edit_list_modal')}
                  padding="0"
                >
                  Edit
                </CabalButton>
              )}

              <CabalButton
                variant="link"
                onClick={() => showModal(renderShareListModal, 'render_share_list_modal')}
                padding="0"
              >
                Share
              </CabalButton>
            </div>
          )
        }
      >
        {list.slug && list.default_type_identifier && listConnectionType && (
          <div className="-mx-2">
            <SearchBlock
              teamSlug={teamSlug}
              listSlug={list.slug}
              itemCount={list.companies_count}
              className="-my-2"
            />
          </div>
        )}
        {!listConnectionType && (
          <div className={cx('-mt-1 -mx-2.5', { 'grid grid-cols-4 gap-2 mx-2.5': gridView })}>
            {companies.map((company) => (
              <CompanyCard
                listUuid={list.uuid}
                icps={list.icps}
                key={company.id}
                company={company}
                teamSlug={teamSlug}
                people={people}
                peopleVotes={data.people_votes}
                companyVotes={data.company_votes}
                gridView={gridView}
                listSlug={list.slug}
                companyList={list}
                isTalent={list.is_talent}
                isPortfolio={list.list_category === 'portfolio'}
                refetchLists={() => refetchLists?.()}
              />
            ))}
          </div>
        )}

        {/* View all count section */}
        {(sortedData.length > limit || (useStaticCount && list.companies_count > limit)) && (
          <div className="mt-4 flex items-center justify-center w-full">
            <CabalButton
              variant="tertiary"
              onClick={() => history.push(`/${teamSlug}/lists/${list.slug}`)}
            >
              See all ({useStaticCount ? list.companies_count : sortedData.length})
            </CabalButton>
          </div>
        )}
      </Widget>
    </Wrapper>
  )
}

export default ListSection
