import React, { useState } from 'react'

import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import startCase from 'lodash/startCase'
import { useMutation } from 'react-query'
import { useSetState } from 'react-use'
import validator from 'validator'

import { useAccessControl } from 'global/AccessControl'
import AvatarUpload, { AvatarUploadRef } from 'global/AvatarUpload'
import CabalButton from 'global/CabalButton'
import { CheckBox, MultiSelect, Select, TextArea, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import {
  ModalDivider,
  ModalInputLabel,
  ModalInputWrapper,
  ModalSectionHeader,
  ModalSectionWrapper,
} from 'global/Modal/styles'
import { useAdvisors, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import company from 'utils/api/company'
import team from 'utils/api/team'
import useTeamSetting from 'utils/hooks/useTeamSetting'
import { InvestorCompany } from 'utils/types/investor'

interface Props {
  company: InvestorCompany
  resolve: () => void
  teamSlug: string
  allTags: string[]
  setAllTags: (tags: string[]) => void
  companies: InvestorCompany[]
  setCompanies: (c: InvestorCompany[]) => void
}

const EditInvestorCompanyModal: React.VFC<Props> = ({
  company,
  companies,
  setCompanies,
  resolve,
  teamSlug,
  allTags,
  setAllTags,
  allStages,
  setAllStages,
}) => {
  const { team } = useTeam(teamSlug)

  const avatarUploadRef = React.useRef<AvatarUploadRef>(null)

  const { advisors, isAdvisorLoaded } = useAdvisors({ teamSlug })
  const { canViewPortfolio } = useAccessControl(teamSlug)

  const [working, setWorking] = useState(false)
  const [companyDetails, setCompanyDetails] = useSetState<InvestorCompany>(company)

  const [owners, setOwners] = useState<string[]>(company.owners?.map((o) => o.uuid) || [])

  const { mutate: updateInvestorCompany } = useMutation(
    ['updateInvestorCompany', teamSlug],
    () => {
      const ownerUuids: { [key: string]: boolean } = {}
      company.owners?.forEach((o) => {
        ownerUuids[o.uuid] = false
      })
      owners.forEach((o) => {
        ownerUuids[o] = true
      })
      return callApi(api.updateInvestorCompany, teamSlug, companyDetails, ownerUuids)
    },
    {
      onSuccess: () => {
        const newOwners = team?.admins_and_members.filter((m) => owners.includes(m.uuid))
        const updatedCompanyDetails = { ...companyDetails, owners: newOwners || null }
        setCompanyDetails(updatedCompanyDetails)
        const updatedCompanies = companies.map((c) => {
          if (c.uuid === updatedCompanyDetails.uuid) {
            return updatedCompanyDetails
          }
          return c
        })
        setCompanies(updatedCompanies)
        cabalToast({ style: 'success', content: 'Company updated successfully!' })
        setWorking(false)
        resolve()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
        setWorking(false)
      },
    },
  )

  const { mutate: deleteInvestorCompany, isLoading: isDeleting } = useMutation(
    ['deleteInvestorCompany', company.uuid],
    () => callApi(api.deleteInvestorCompany, company.uuid, teamSlug),
    {
      onSuccess: ({ company }) => {
        const updatedCompanies = companies.filter((c) => c.uuid !== company.uuid)
        setCompanies(updatedCompanies)
        resolve()
        cabalToast({ style: 'success', content: 'Company successfully deleted' })
      },
    },
  )

  const onSave = () => {
    setWorking(true)
    const talentPOC = omitBy(companyDetails.talent_poc, isEmpty)
    delete talentPOC.linkedin_url
    if (talentPOC && Object.keys(talentPOC).length > 0 && Object.keys(talentPOC).length < 3) {
      cabalToast({
        style: 'error',
        content: 'Please enter all the required details for Hiring POC!',
      })
      return
    }

    if (avatarUploadRef.current) {
      avatarUploadRef.current.upload().then((data) => {
        if (data?.url) {
          setCompanyDetails({ ...companyDetails, logo_url: data.api_url })
        }
        updateInvestorCompany()
      })
    } else {
      updateInvestorCompany()
    }
  }

  const createEmailOption = (email: string) => {
    if (!validator.isEmail(email)) {
      cabalToast({ style: 'error', content: `Please enter a valid email address.` })
      return
    }
    setCompanyDetails({
      talent_poc: {
        ...companyDetails.talent_poc,
        email,
      },
    })
  }

  return (
    <Modal
      show
      onHide={resolve}
      header="Edit Company"
      rightActions={
        <CabalButton
          onClick={onSave}
          working={working}
          disabled={isDeleting}
          data-testid="edit-company-save-btn"
        >
          Save
        </CabalButton>
      }
      leftActions={
        <CabalButton
          variant="destructive"
          data-testid="edit-company-delete-btn"
          onClick={() => deleteInvestorCompany()}
          working={isDeleting}
          disabled={working}
        >
          Delete Company
        </CabalButton>
      }
    >
      <ModalSectionWrapper>
        <ModalSectionHeader>Company Info</ModalSectionHeader>

        <ModalInputWrapper>
          <ModalInputLabel>Logo</ModalInputLabel>
          <AvatarUpload
            ref={avatarUploadRef}
            imageUrl={companyDetails.logo_url}
            attachTo={`investor_company:${company.uuid}`}
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>Company Name</ModalInputLabel>
          <TextInput
            value={companyDetails.company_name}
            data-testid="edit-company-name-input"
            required
            className="w-full"
            placeholder="Company Name"
            onChange={(e) => setCompanyDetails({ ...companyDetails, company_name: e.target.value })}
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>Website</ModalInputLabel>
          <TextInput
            value={companyDetails.domain ?? undefined}
            className="w-full"
            data-testid="edit-company-website-input"
            placeholder="Website"
            onChange={(e) => setCompanyDetails({ ...companyDetails, domain: e.target.value })}
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>Tags</ModalInputLabel>
          <MultiSelect<string>
            className="w-full"
            data-testid="edit-company-tags-input"
            options={
              allTags.map((t) => ({
                label: t,
                value: t,
              })) || []
            }
            value={companyDetails.tags}
            onChange={(v) => setCompanyDetails({ ...companyDetails, tags: v })}
            creatable
            onCreateOption={(o) => {
              setAllTags([...allTags, o])
              setCompanyDetails({ ...companyDetails, tags: [...(companyDetails.tags || []), o] })
            }}
            placeholder="Tags"
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>Description</ModalInputLabel>
          <TextArea
            value={companyDetails.description}
            data-testid="edit-company-description-input"
            onChange={(e) => setCompanyDetails({ ...companyDetails, description: e.target.value })}
            maxLength={255}
            className="w-full"
            placeholder="Description"
          />
        </ModalInputWrapper>

        {canViewPortfolio && (
          <ModalInputWrapper>
            <ModalInputLabel>Owners</ModalInputLabel>
            <MultiSelect<string>
              className="w-full"
              placeholder="Owners"
              data-testid="edit-company-owners-input"
              options={
                team?.admins_and_members.map((f) => ({
                  label: f.name,
                  value: f.uuid,
                })) || []
              }
              value={owners}
              onChange={(v) => setOwners(v)}
              isLoading={!team?.admins_and_members}
            />
          </ModalInputWrapper>
        )}

        {canViewPortfolio && (
          <ModalInputWrapper>
            <ModalInputLabel>Employees</ModalInputLabel>
            <TextInput
              value={companyDetails.employee_count ?? undefined}
              className="w-full"
              data-testid="edit-company-employees-input"
              placeholder="Employees"
              onChange={(e) =>
                setCompanyDetails({ ...companyDetails, employee_count: e.target.value })
              }
            />
          </ModalInputWrapper>
        )}

        <ModalInputWrapper>
          <ModalInputLabel>Location</ModalInputLabel>
          <TextInput
            value={companyDetails.location ?? undefined}
            className="w-full"
            data-testid="edit-company-location-input"
            placeholder="Location"
            onChange={(e) => setCompanyDetails({ ...companyDetails, location: e.target.value })}
          />
        </ModalInputWrapper>

        {canViewPortfolio && (
          <ModalInputWrapper>
            <ModalInputLabel>Stage</ModalInputLabel>
            <Select
              value={companyDetails.stage ?? undefined}
              className="w-full"
              creatable
              onCreateOption={(o) => {
                setAllStages([...allStages, o.toLowerCase()])
                setCompanyDetails({ ...companyDetails, stage: o })
              }}
              data-testid="edit-company-stage-input"
              placeholder="Stage"
              formatCreateLabel={() => (
                <>
                  <i className="far fa-plus mr-1"></i>Create new stage
                </>
              )}
              options={allStages.map((s: string) => ({
                label: startCase(s),
                value: s,
              }))}
              onChange={(v) => setCompanyDetails({ stage: v ?? '' })}
            />
          </ModalInputWrapper>
        )}
      </ModalSectionWrapper>
      <ModalDivider />
      <ModalSectionWrapper>
        <ModalSectionHeader>Social</ModalSectionHeader>
        <ModalInputWrapper>
          <ModalInputLabel>Twitter Profile URL</ModalInputLabel>
          <TextInput
            value={companyDetails.data?.twitter_url}
            className="w-full"
            data-testid="edit-company-twitter-input"
            onChange={(e) =>
              setCompanyDetails({
                ...companyDetails,
                data: { ...companyDetails.data, twitter_url: e.target.value || undefined },
              })
            }
            placeholder="Twitter Profile URL"
          />
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>LinkedIn Profile URL</ModalInputLabel>
          <TextInput
            value={companyDetails.data?.linkedin_url}
            required
            className="w-full"
            data-testid="edit-company-linkedin-input"
            onChange={(e) =>
              setCompanyDetails({
                ...companyDetails,
                data: { ...companyDetails.data, linkedin_url: e.target.value || undefined },
              })
            }
            placeholder="LinkedIn Profile URL"
          />
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <ModalDivider />

      {canViewPortfolio && (
        <>
          <ModalSectionWrapper>
            <ModalSectionHeader>{`${team?.name} Talent Lead`}</ModalSectionHeader>
            <ModalInputWrapper>
              <ModalInputLabel>Name</ModalInputLabel>
              <Select
                value={companyDetails.talent_lead}
                className="w-full"
                data-testid="edit-company-talent-lead-input"
                onChange={(v) => {
                  setCompanyDetails({
                    talent_lead: v || undefined,
                  })
                }}
                defaultValue={team?.talent_coordinator}
                options={
                  team?.admins_and_members.map((f) => ({
                    label: f.name,
                    value: f.uuid,
                  })) || []
                }
                placeholder="Talent Lead"
              />
            </ModalInputWrapper>
          </ModalSectionWrapper>
          <ModalDivider />
        </>
      )}

      {canViewPortfolio && (
        <ModalSectionWrapper>
          <ModalSectionHeader>
            {companyDetails.company_name && `${companyDetails.company_name} `}
            Point of Contact
          </ModalSectionHeader>
          <ModalInputWrapper>
            <ModalInputLabel>Email</ModalInputLabel>
            <Select
              isLoading={!isAdvisorLoaded}
              value={companyDetails.talent_poc?.email}
              className="w-full"
              onChange={(v) => {
                const advisor = advisors?.find((m) => m.email === v)
                let details = { ...companyDetails.talent_poc, email: v || undefined }
                if (advisor) {
                  const { first_name, last_name, email, linkedin_url } = advisor
                  details = {
                    first_name: first_name || '',
                    last_name: last_name || '',
                    linkedin_url: linkedin_url || '',
                    email: email || '',
                  }
                }
                setCompanyDetails({
                  talent_poc: {
                    ...details,
                  },
                })
              }}
              onCreateOption={createEmailOption}
              options={
                advisors?.map((f) => ({
                  label: f.email,
                  value: f.email,
                })) || []
              }
              placeholder="Email"
              creatable
              data-testid="edit-company-poc-email-input"
              virtualized
            />
          </ModalInputWrapper>
          <ModalInputWrapper>
            <ModalInputLabel>First Name</ModalInputLabel>
            <TextInput
              value={companyDetails.talent_poc?.first_name}
              className="w-full"
              valueType="non-empty"
              data-testid="edit-company-poc-first-name-input"
              onChange={(e) =>
                setCompanyDetails({
                  talent_poc: {
                    ...companyDetails.talent_poc,
                    first_name: e.target.value || undefined,
                  },
                })
              }
              placeholder="First Name"
            />
          </ModalInputWrapper>
          <ModalInputWrapper>
            <ModalInputLabel>Last Name</ModalInputLabel>
            <TextInput
              value={companyDetails.talent_poc?.last_name}
              className="w-full"
              valueType="non-empty"
              data-testid="edit-company-poc-last-name-input"
              placeholder="Last Name"
              onChange={(e) =>
                setCompanyDetails({
                  talent_poc: {
                    ...companyDetails.talent_poc,
                    last_name: e.target.value || undefined,
                  },
                })
              }
            />
          </ModalInputWrapper>
          <ModalInputWrapper>
            <ModalInputLabel>LinkedIn Profile URL (Optional)</ModalInputLabel>
            <TextInput
              value={companyDetails.talent_poc?.linkedin_url}
              className="w-full mt-3"
              valueType="website"
              placeholder="LinkedIn Profile URL (Optional)"
              data-testid="edit-company-poc-linkedin-input"
              onChange={(e) =>
                setCompanyDetails({
                  talent_poc: {
                    ...companyDetails.talent_poc,
                    linkedin_url: e.target.value || undefined,
                  },
                })
              }
            />
          </ModalInputWrapper>
        </ModalSectionWrapper>
      )}
    </Modal>
  )
}

export default EditInvestorCompanyModal
