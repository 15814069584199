import React, { useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import memoizeOne from 'memoize-one'

import { FiltersValueType, renderFilters } from 'components/Filters'
import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import { SalesFilterTypes } from 'containers/ListIndexView/SalesList/type'
import { useAccessControl } from 'global/AccessControl'
import { CheckBox } from 'global/Input'
import Typography from 'global/Typography'
import { useAdvisors, useGroups, useTeamSlug } from 'store/hooks'
import Switch from 'ui-components/Switch'

interface Props {
  disabled?: boolean
  filters: any
  setFilters: (filters: SalesFilterTypes) => void
  // ownedCompanyTags?: any
  setSelectedItems: (selectedItems: any[]) => void
  selectedItems: any[]
  type?: string
}

const upcastFilters = memoizeOne((filters: SalesFilterTypes) => {
  const result: FiltersValueType = {}

  const search = filters.search
  if (!!search?.length) {
    result.search = search
  }

  const portfolioStages = filters.portfolio_stages
  if (!!portfolioStages?.length) {
    result.portfolio_stages = portfolioStages
  }

  const members = filters.advisor_uuids
  if (!!members?.length) {
    result.advisor_uuids = members
  }

  const groups = filters.group_uuids
  if (!!groups?.length) {
    result.group_uuids = groups
  }

  const sizes = filters.size
  if (!!sizes?.length) {
    result.size = sizes
  }

  const tags = filters.tags
  if (!!tags?.length) {
    result.tags = tags
  }

  const sort = filters.sort
  if (!!sort) {
    result.sort = sort
  }

  return result
}, isEqual)

const downcastFilters = memoizeOne((filters: FiltersValueType) => {
  const result: SalesFilterTypes = {}

  const search = filters['search'] as string[] | null
  if (!!search?.length) {
    result.search = search
  }

  const members = filters['advisor_uuids'] as string[] | null
  if (!!members?.length) {
    result.advisor_uuids = members
  }

  const groups = filters['group_uuids'] as string[] | null
  if (!!groups?.length) {
    result.group_uuids = groups
  }

  const sizes = filters['size'] as string[] | null
  if (!!sizes?.length) {
    result.size = sizes
  }

  const tags = filters['tags'] as string[] | null
  if (!!tags?.length) {
    result.tags = tags
  }

  const portfolioStages = filters['portfolio_stages'] as string[] | null
  if (!!portfolioStages?.length) {
    result.portfolio_stages = portfolioStages
  }

  const sort = filters['sort'] as string | null
  if (!!sort) {
    result.sort = sort
  }

  return result
}, isEqual)

const SalesListFilters: React.FC<Props> = ({
  disabled,
  filters,
  setFilters,
  selectedItems,
  setSelectedItems,
  type,
}) => {
  const teamSlug = useTeamSlug()
  const { companyListData, salesCompanies, salesPeople } = useSalesListContext()
  const { advisors = [] } = useAdvisors({ teamSlug })
  const { groups = [] } = useGroups(teamSlug)

  const item = salesPeople

  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  // const tagOptions = ownedCompanyTags?.tags || []
  // const stageOptions = ownedCompanyTags?.stages || []

  const adminView = !!isAdminOrEmployee && !!companyListData.owned
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [
      {
        key: 'search',
        type: 'search',
        icon: 'far fa-search',
        labelStr: 'Search',
        label: 'Search',
        skipLabelInAppliedPill: true,
        noPopover: true,
      },
      {
        key: 'sort',
        type: 'dropdown',
        label: (
          <Typography>
            <i className="far fa-sort fa-sm mr-1" />
            Sort
          </Typography>
        ),
        labelStr: 'Sort',
        options: [
          { label: 'Name (A-Z)', value: 'name-asc' },
          { label: 'Name (Z-A)', value: 'name-desc' },
        ],
      },
      type === 'company' &&
        adminView &&
        !filters.member_view && {
          key: 'advisor_uuids',
          type: 'dropdown[]',
          label: 'Members',
          options: advisors.map((advisor) => ({
            label: advisor.name,
            value: advisor.uuid,
            group: 'Members',
          })),
        },
      type === 'company' &&
        adminView &&
        !filters.member_view && {
          key: 'group_uuids',
          type: 'dropdown[]',
          label: 'Groups',
          options: groups.map((group) => ({
            label: group.name,
            value: `${group.uuid}`,
            group: 'Groups',
          })),
        },
    ].filter(Boolean),
    value: upcastFilters(filters),
    onChange: (f) => setFilters(downcastFilters(f)),
  })

  return (
    <div
      ref={parent}
      className={cx('flex-col w-full my-3', {
        'pointer-events-none opacity-50': disabled,
        'px-2.5': adminView,
      })}
    >
      <div className="flex flex-col sm:flex-row justify-between items-start">
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-wrap gap-2.5">
            {adminView && !filters.member_view && (
              <CheckBox
                checked={item.length > 0 && selectedItems.length === item.length}
                onChange={(e) => {
                  setSelectedItems(e.target.checked ? item : [])
                }}
              />
            )}
            {renderedFilters}
          </div>
          {adminView && (
            <div className="flex items-center gap-2">
              <Typography fontSize="12" color="fog">
                Member View
              </Typography>
              <Switch
                checked={filters.member_view}
                onCheckedChange={(e) => {
                  setFilters({ ...filters, member_view: e, advisor_uuids: null, group_uuids: null })
                }}
              />
            </div>
          )}
        </div>
      </div>
      {appliedFilters.length > 0 && (
        <div className="flex gap-1 flex-wrap mt-2">{appliedFilters}</div>
      )}
    </div>
  )
}

export default SalesListFilters
