import React from 'react'

import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import ResourceDataTags from 'containers/ListIndexView/ResourcesList/ResourceDataTags'
import { IResourcePeople } from 'containers/ListIndexView/ResourcesList/types'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'

import { cn } from 'utils/styles'

const ResourceItem = ({ item, view }: { item: IResourcePeople; view: 'grid' | 'list' }) => {
  const gridView = view === 'grid'
  const { companyListData } = useResourcesListContext()
  const { showModal } = useModal()

  const openRequestIntroModal = () => {
    showModal(
      (resolve) => (
        <FacilitateIntroModal
          requestable={item}
          companyList={companyListData}
          resolve={resolve}
          gatekeeperId={companyListData?.gatekeeper_id}
          facilitator={companyListData?.owning_team}
          includeInitialNote={false}
          requestType={'advisor'}
        />
      ),
      'facilitate-intro-modal',
    )
  }

  return (
    <DataCardV2
      onClick={() => window.open(`/users/${item.global_person_uuid}`, 'noreferrer')}
      title={item.name}
      description={item.position}
      avatar={
        <Avatar src={item.avatar || item.logo_url} name={item.name} size={gridView ? '72' : '32'} />
      }
      description={item.position || item.description || item.about}
      descriptionBottom={
        (item.company_name || item.location) && (
          <div className="flex flex-col items-start gap-2.5 mb-2">
            {item.company_name && (
              <Typography lineHeight={1}>
                <i className="far fa-building" /> {item.company_name}
              </Typography>
            )}

            {item.location && (
              <Typography lineHeight={1}>
                <i className="far fa-location-dot" /> {item.location}
              </Typography>
            )}
          </div>
        )
      }
      gridPillsSection={<ResourceDataTags tags={item.tags} />}
      cta={
        <CabalButton
          onClick={() => openRequestIntroModal()}
          variant={gridView ? 'primary' : 'link'}
          className="w-full"
        >
          {'Request Intro'}
        </CabalButton>
      }
      view={view}
      className={cn({
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded':
          !gridView,
      })}
    />
  )
}

const ResourcePeopleMemberView = () => {
  const { view, resourcePeople } = useResourcesListContext()

  const itemRow = (view: 'grid' | 'list') => {
    return (
      <>
        {resourcePeople.map((person) => (
          <ResourceItem key={person.uuid} item={person} view={view} />
        ))}
      </>
    )
  }

  return (
    <div>
      {view === 'grid' && <GridWrapper>{itemRow('grid')}</GridWrapper>}
      {view === 'list' && <>{itemRow('list')}</>}
    </div>
  )
}

export default ResourcePeopleMemberView
