import React, { useState } from 'react'

import styled from 'styled-components'

import Avatar from 'global/Avatar'
import DropDownMenu from 'global/DropDownMenu'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'

const Wrapper = styled.div`
  border: ${({ theme }) => theme.border};
`

const GeneralAccess = () => {
  const [selectedOption, setSelectedOption] = useState<'restricted' | 'public' | 'connect'>('restricted')
  const options = [
    {
      key: 'restricted',
      label: 'Restricted',
      description: 'Only specific people, groups or teams can view this list',
      icon: <i className="far fa-lock" />,
    },
    {
      key: 'public',
      label: 'Public',
      description: 'Anyone with the link can view and share this list',
      icon: <i className="far fa-globe" />,
    },
    {
      key: 'connect',
      label: 'Connections',
      description: 'Only people you are connected to can view this list',
      icon: <i className="far fa-arrows-left-right" />,
    },
  ]

  const getSelectedOption = (key: 'restricted' | 'public' | 'connect') => {
    return options.find((o) => o.key === key) || options[0]
  }

  return (
    <div className="flex flex-col gap-2 mt-2">
      <Typography fontSize="12" fontWeight={600}>
        General access
      </Typography>
      <Wrapper className="rounded-lg">
        <div className="flex items-center justify-between">
          <DataCardV2
            title={getSelectedOption(selectedOption).label}
            className="hover:bg-transparent"
            description={getSelectedOption(selectedOption).description}
            avatar={
              <Typography fontSize="20" color="purple">
                {getSelectedOption(selectedOption).icon}
              </Typography>
            }
          />
          <div className="flex shrink-0 pr-2.5">
            {/* Update setting on select */}
            <DropDownMenu
              menuItems={[
                {
                  label: 'Restricted',
                  onSelect: () => setSelectedOption('restricted'),
                },
                {
                  label: 'Public',
                  onSelect: () => setSelectedOption('public'),
                },
                {
                  label: 'Connections',
                  onSelect: () => setSelectedOption('connect'),
                },
              ]}
              trigger={
                <Typography color={'fog'} fontSize={'12'}>
                  Can view
                  <i className="fas fa-chevron-down fa-sm ml-1" />
                </Typography>
              }
            />
          </div>
        </div>
      </Wrapper>
    </div>
  )
}

export default GeneralAccess
