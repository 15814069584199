const formatter = new Intl.NumberFormat('en-US')
const compactFormatter = new Intl.NumberFormat('en', { notation: 'compact' })

function formatNum(num: any, decimals = 2) {
  return formatter.format(num.toFixed(decimals))
}

function formatCompact(num: any, decimal = 2) {
  return compactFormatter.format(num.toFixed(decimal)).toLowerCase()
}

function formatDecimal(num: any) {
  return formatter.format(num)
}

function formatThreshold(num: number): string {
  const thresholds = [
    { value: 100000000, label: '100M+' },
    { value: 10000000, label: '10M+' },
    { value: 1000000, label: '1M+' },
    { value: 100000, label: '100k+' },
    { value: 10000, label: '10k+' },
    { value: 1000, label: '1k+' },
    { value: 100, label: '100+' },
  ]
  const threshold = thresholds.find((t) => num >= t.value)
  return threshold?.label ?? num.toString()
}

function numWithCommas(string_or_num: string | number) {
  if (string_or_num && string_or_num != '') {
    if (String(string_or_num).includes(',')) {
      return string_or_num
    } else {
      const num = cleanNum(string_or_num)
      return num.toLocaleString('en-US')
    }
  }
}

function cleanNum(str: string | number) {
  return Number(String(str).replace(/[\$,]/g, ''))
}

function gt(a: string | number | undefined = '', b: string | number | undefined = '') {
  if (a === b) return 0
  if (a > b) return 1
  return -1
}

const promiseMemoize = (fn: (...props: unknown[]) => Promise<unknown>) => {
  const cache: { [key: string]: unknown } = {}
  return (...args: unknown[]) => {
    const strX = JSON.stringify(args)
    return strX in cache
      ? cache[strX]
      : (cache[strX] = fn(...args).catch((x) => {
          delete cache[strX]
          return x
        }))
  }
}

const snakeCaseToTitleCase = (str: string) => {
  const sentence = str.toLowerCase().split('_')
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
  }

  return sentence.join(' ')
}

const mobileMediaQuery = '(max-width: 640px)'
const tabletMediaQuery = '(max-width: 768px)'
const darkModeQuery = '(prefers-color-scheme: dark)'

const getSystemTheme = () => {
  if (window.matchMedia?.(darkModeQuery).matches) {
    return 'dark'
  } else {
    return 'light'
  }
}

export {
  gt,
  mobileMediaQuery,
  tabletMediaQuery,
  darkModeQuery,
  getSystemTheme,
  formatNum,
  numWithCommas,
  cleanNum,
  formatDecimal,
  formatCompact,
  formatThreshold,
  promiseMemoize,
  snakeCaseToTitleCase,
}
