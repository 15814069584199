import React, { useMemo } from 'react'

import { useAccessControl } from 'global/AccessControl'
import { useTeamSlug } from 'store/hooks'

import { List, Team } from 'utils/types'

import ListSection from './ListSection'

interface Props {
  team?: Team
  companyLists: List[]
  refetchLists?: () => void
  companyData?: any
  people?: any
  filter: string
  isVcTeam: boolean
}
const SharedListsSection: React.FC<Props> = ({
  team,
  companyLists,
  refetchLists,
  companyData,
  people,
  filter,
  isVcTeam,
}) => {
  const teamSlug = useTeamSlug()
  const listOrder = team?.home_list_uuids
  const advisor = team?.advisor
  const candidate = advisor?.role && advisor?.role === 'candidate'
  const { canViewPortfolio } = useAccessControl(teamSlug)

  const sortedLists = useMemo(() => {
    let result = companyLists

    if (!canViewPortfolio) {
      result = result?.filter((list) => list.list_category !== 'portfolio')
    }

    if (filter === 'Network') {
      result = result.filter(
        (list) => list.default_type_identifier && list.default_type_identifier === 'connections',
      )
      return result
    }

    if (filter !== '') {
      const [type, category] = filter.split('-')

      if (type && category) {
        result = result.filter((list) => list.list_category === category && list.list_type === type)
      } else {
        result = []
      }
    }

    if (!!listOrder && listOrder.length > 0) {
      result = result.sort((a, b) => listOrder?.indexOf(a.uuid) - listOrder?.indexOf(b.uuid))
    }

    if (candidate) {
      result = (result || companyLists).filter(
        (list) => list.default_type_identifier !== 'connections',
      )

      if (team?.slug === 'craft-ventures') {
        result = result.filter((list) => list.list_category !== 'resources')
      }
    }

    if (isVcTeam) {
      result = result.sort((a, b) => {
        const categoryOrder = ['portfolio', 'resources_people', 'talent', 'jobs', 'resources']

        const getCategoryOrder = (list) => {
          if (list.list_category === 'resources' && list.list_type === 'people') {
            return 'resources_people'
          }
          return list.list_category
        }

        const aCategoryOrder = getCategoryOrder(a)
        const bCategoryOrder = getCategoryOrder(b)

        if (aCategoryOrder === bCategoryOrder) {
          return a.name.localeCompare(b.name)
        }

        return categoryOrder.indexOf(aCategoryOrder) - categoryOrder.indexOf(bCategoryOrder)
      })
    }

    return result
  }, [listOrder, companyLists, filter])

  return (
    <>
      {sortedLists &&
        sortedLists.map((list) => (
          <div key={list.uuid} className="flex flex-col gap-8">
            <ListSection
              list={list}
              teamSlug={teamSlug}
              refetchLists={refetchLists}
              data={companyData[list.slug]}
              people={people}
            />
          </div>
        ))}
    </>
  )
}

export default SharedListsSection
