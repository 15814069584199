import React, { useState } from 'react'

import { useMutation } from 'react-query'

import FacilitatedBy from 'containers/IntroRequestModal/components/FacilitatedBy'
import { DraftEmailAttachment } from 'containers/IntroRequestModal/components/Modals/Sales/DraftEmailAttachment'
import UsersInfo from 'containers/IntroRequestModal/components/UsersInfo'
import {
  createDraftEmailObject,
  createMessageDraft,
} from 'containers/IntroRequestModal/components/UtilityFunctions'
import { closeModals } from 'containers/IntroRequestModal/components/UtilityFunctions'
import { ICreateMessageDraftPayload } from 'containers/IntroRequestModal/components/types'
import {
  IRCompanyListObject,
  IRPayload,
  IRUpdatePayload,
  IRequestor,
  ISalesTarget,
} from 'containers/IntroRequestModal/types'
import { IntroConnection } from 'containers/Portfolio/MemberView/types/IntroConnection'
import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Modal, { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CurrentUserProfile } from 'utils/types'

interface IProps {
  requestable: ISalesTarget
  companyListObject: IRCompanyListObject
  facilitator: IntroConnection
  requestor: IRequestor
  initiator: IRequestor | CurrentUserProfile
  networkingOnly?: boolean
  messageUuid?: string | null | undefined
  introRequestUuid?: string | null
  adminsAndMembers: IRequestor[]
}

export function ReviewSalesListModal({
  requestable,
  companyListObject,
  facilitator,
  requestor,
  initiator,
  networkingOnly = false,
  messageUuid,
  introRequestUuid,
  adminsAndMembers,
}: IProps) {

  const [noteToFacilitator, setNoteToFacilitator] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(true)
  const { user } = useCurrentUser()
  const teamSlug = companyListObject?.owning_team?.slug || user.team?.slug
  const { team } = useTeam(teamSlug)
  const listSlug = companyListObject?.slug
  const [messageDraftUuid, setMessageDraftUuid] = useState<string | null | undefined>(messageUuid)
  const modalName = 'review-sales-list-handler'
  const { mutate: introRequestCreateMutate } = useMutation({
    mutationFn: (payload: IRPayload) => callApi(api.createIntroRequestV2, payload),
  })

  const { mutate: introRequestUpdateMutate } = useMutation({
    mutationFn: (payload: IRUpdatePayload) => callApi(api.sendToFacilitatorV2, payload),
  })

  const { mutate: deleteMessageMutate } = useMutation({
    mutationFn: (messageUuid: string) => callApi(api.deleteMessageDraft, messageUuid),
  })

  const { closeModal: closeModalFunction } = useModal()
  closeModalFunction('request-intro-connections-modal')

  const messageDraftCreate: ICreateMessageDraftPayload = createMessageDraft(
    companyListObject?.uuid || '',
    facilitator.uuid,
    requestable.global_person_uuid || requestable.uuid || '',
    requestor.uuid,
  )

  const createRequestPayload = () => {
    return {
      team_slug: team!.slug,
      company_list_id: listSlug,
      note: noteToFacilitator,
      draft_email_uuid: null,
      requestable_id: requestable.global_person_uuid || requestable.uuid || '',
      facilitator_user_uuid: facilitator.uuid,
      requestable_type: requestable.item_type || requestable.type || '',
      additional_note: null,
      request_type: companyListObject.owned === true ? 'internal' : 'advisor',
      note_to_gatekeeper: null,
      note_to_facilitator: noteToFacilitator || null,
      requestor_uuid: requestor.uuid,
      initiator_uuid: initiator.uuid,
      message_draft_uuid: messageDraftUuid,
    }
  }

  const updateRequestPayload = () => {
    return {
      uuid: introRequestUuid,
      noteToFacilitator: noteToFacilitator,
      status: 'requested',
    }
  }

  const successErrorCallbacks = {
    onSuccess: () => {
      cabalToast({ style: 'success', content: 'Intro requested!', fadeOut: false })
      closeModals({ closeModalFunction, modalName })
    },
    onError: () => {
      cabalToast({ style: 'error', content: 'Intro request could not be sent' })
      console.log('Error')
    },
  }

  const createIntroRequest = (payload: IRPayload) => {
    introRequestCreateMutate(payload, successErrorCallbacks)
  }

  const updateIntroRequest = (payload: IRUpdatePayload) => {
    introRequestUpdateMutate(payload, successErrorCallbacks)
  }

  const { mutate: archiveIRMutate, isLoading: isArchiving } = useMutation({
    mutationFn: (uuid: string) => callApi(api.archiveIntroRequestV2, uuid),
  })

  const handleArchive = () => {
    if (introRequestUuid) {
      archiveIRMutate(introRequestUuid, {
        onSuccess: () => {
          cabalToast({ style: 'success', content: 'Intro archived!' })
          closeModals({ closeModalFunction, modalName })
        },
        onError: () => {
          console.log('Error')
        },
      })
    }
  }

  const handleSubmit = () => {
    if (introRequestUuid) {
      const payload: IRUpdatePayload = updateRequestPayload()
      updateIntroRequest(payload)
    } else {
      const payload: IRPayload = createRequestPayload()
      createIntroRequest(payload)
    }
  }

  const buttonText = (
    <div style={{ maxWidth: '300px' }} className="w-full block truncate">
      Send request to {facilitator.name}
    </div>
  )

  const handleClose = () => {
    closeModals({ closeModalFunction, modalName })
    if (messageDraftUuid && !introRequestUuid) {
      deleteMessageMutate(messageDraftUuid)
    }
  }

  const draftEmailObject = createDraftEmailObject(
    requestor,
    facilitator,
    requestable,
    messageDraftUuid,
    setMessageDraftUuid,
    teamSlug,
    messageDraftCreate,
    introRequestUuid,
    setShowModal,
  )

  return (
    <Modal
      className={`max-w-lg ${showModal ? 'block' : 'hidden'}`}
      onHide={() => handleClose()}
      header={
        <div className="flex flex-col gap-y-4 items-start w-full">
          <Typography fontSize="16" fontWeight={600} lineHeight="1.2" className="w-full">
            {`Request intro to ${requestable.name}`}
          </Typography>
          {introRequestUuid && (
            <Typography fontSize="13" fontWeight={400} lineHeight="1.2" className="w-full">
              {`Created by ${initiator.name}`}
            </Typography>
          )}
        </div>
      }
      centerActions={
        <div className="pb-2">
          <CabalButton onClick={handleSubmit} className="mr-2">
            {buttonText}
          </CabalButton>
          {introRequestUuid && (
            <CabalButton
              onClick={handleArchive}
              working={isArchiving}
              disabled={isArchiving}
              variant="secondary"
              data-testid="review-sales-list-archive-btn"
            >
              Archive request
            </CabalButton>
          )}
        </div>
      }
    >
      <UsersInfo requestor={requestor} target={requestable}></UsersInfo>

      <FacilitatedBy facilitator={facilitator} />

      {networkingOnly && (
        <div className="mt-6 mb-6 text-center">
          <Typography fontSize={'16'} color={'rain'} fontWeight={600}>
            <i className="fa fa-info-circle mr-1" /> {requestable?.first_name || requestable.name}{' '}
            is only looking to network
          </Typography>
        </div>
      )}
      <div className="space-y-2 mb-2">
        <div className="w-full max-w-[444px] block truncate">
          <Typography fontSize="12" fontWeight={400} lineHeight="1" className="text-center">
            Note for {facilitator.name}
          </Typography>
        </div>
        <TextArea
          value={noteToFacilitator}
          className="w-full"
          rows={3}
          data-testid="request-talent-intro-modal-context"
          onChange={(e) => setNoteToFacilitator(e.target.value)}
        />
      </div>
      <DraftEmailAttachment draftEmailObject={draftEmailObject} />
    </Modal>
  )
}
