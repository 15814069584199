import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { INote } from 'containers/GlobalUserProfile/types'
import CabalButton from 'global/CabalButton'
import { TextArea, TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import api, { callApi } from 'utils/api'
import { cabalToast } from 'ui-components/Toast'

interface Props {
  onHide: () => void
  note?: Partial<INote>
  viewOnly?: boolean
  slug: string
}

const NotesModal = ({ onHide, note: _note, viewOnly, slug }: Props) => {
  const queryClient = useQueryClient()
  const [noteData, setNoteData] = useState(
    _note || {
      subject: '',
      body: '',
    },
  )

  const createNote = useMutation(
    () => callApi(api.createNote, { 
      slug, 
      note: {body: noteData.body || '', subject: noteData.subject || ''} 
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['globalPersonNotes', slug])
        cabalToast({ style: 'success', content: 'Note created successfully' })
        onHide()
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to create note' })
      }
    }
  )

  return (
    <Modal
      header="Notes"
      show={true}
      onHide={onHide}
      dangerouslyBypassFocusLock={true}
      rightActions={
        !viewOnly && (
          <CabalButton
            type="button"
            variant="primary"
            onClick={() => createNote.mutate()}
            working={createNote.isLoading}
            disabled={!noteData?.subject || !noteData?.body}
          >
            Save
          </CabalButton>
        )
      }
    >
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Subject</ModalInputLabel>
          {!viewOnly && (
            <TextInput
              value={noteData?.subject}
              onChange={(e) => setNoteData({ ...noteData, subject: e.target.value })}
              placeholder="Subject"
            />
          )}
          {viewOnly && <Typography fontSize="14">{noteData?.subject}</Typography>}
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Note</ModalInputLabel>
          {!viewOnly && (
            <TextArea
              value={noteData?.body}
              onChange={(e) => setNoteData({ ...noteData, body: e.target.value })}
              placeholder="Note"
              rows={6}
            />
          )}
          {viewOnly && (
            <Typography fontSize="14" className="mb-4">
              {noteData?.body}
            </Typography>
          )}
        </ModalInputWrapper>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default NotesModal
