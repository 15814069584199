import React from 'react'

import cx from 'classnames'
import { useMutation, useQueryClient } from 'react-query'

import AddPersonInput from 'components/AddPerson/Input'
import { Divider } from 'components/SendMessage/styles'
import ConnectionRow from 'containers/Asks/DealBoard/ConnectionRow'
import AnimatedVoteCTA from 'global/AnimatedVoteCTA'
import Avatar from 'global/Avatar'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUserSettings, useTeam } from 'store/hooks'
import { DataCard } from 'ui-components/DataCard'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanyBlueprintWithConnections, VoteType } from 'utils/types'

import { CardWrapper, GridCard, ViewMore } from './styles'

interface Props {
  company: CompanyBlueprintWithConnections
  teamSlug: string
  peopleVotes?: Record<number, any>
  companyVotes?: Record<number, any>
  people: any[]
  listUuid: string
  icps: string[]
  gridView?: boolean
  ssid?: string
}

const CompanyCard: React.VFC<Props> = ({
  people,
  company,
  teamSlug,
  peopleVotes = {},
  companyVotes = {},
  listUuid,
  icps = [],
  gridView = false,
  ssid,
}) => {
  const queryClient = useQueryClient()
  const { team } = useTeam(teamSlug)
  const [viewMore, setViewMore] = React.useState(false)
  const [vote, setVote] = React.useState<VoteType>(companyVotes[company.id] || 0)
  const people_ids = company.global_people_ids || []
  const cutoff = viewMore ? people_ids.length : 3
  const [animateLike, setAnimateLike] = React.useState(false)
  const { settings, updateSetting } = useCurrentUserSettings()
  const { showModal } = useModal()

  const { mutate: sendVote, isLoading: sendingVote } = useMutation(
    ['createVote'],
    (vote: 1 | -1 | 0) =>
      callApi(api.createVote, {
        team_slug: teamSlug,
        votable_type: 'Company',
        votable_id: company.id!,
        vote,
        company_id: company.id,
        ssid: ssid,
      }),
    {
      onSuccess: (_, variables) => {
        setVote(variables)

        if (variables == 1 && !settings.like_toast_showed) {
          cabalToast({
            content: `Your offer to introduce ${company.name} has been logged. ${team?.name} will follow up with you via email. `,
            style: 'success',
          })
          updateSetting('like_toast_showed', true)
        }
      },
    },
  )

  React.useEffect(() => {
    setTimeout(() => {
      setAnimateLike(false)
    }, 1000)
  }, [animateLike])

  const handleLike = () => {
    sendVote(vote === 0 ? 1 : 0)
    setAnimateLike(vote === 0 ? true : false)
  }

  const renderPeople = () => {
    const peopleToShow = people_ids.slice(0, cutoff)
    return peopleToShow.map((person_id: number, index: number) => {
      if (!people[person_id]) {
        return
      } else {
        const connected = (company.connected_global_people_ids || []).includes(person_id)
        return (
          <>
            <ConnectionRow
              key={person_id}
              connection={{
                source_type: people[person_id].source || '',
              }}
              company={company}
              person={{
                ...people[person_id],
                id: person_id,
              }}
              teamSlug={team!.slug}
              existingVote={peopleVotes[person_id]}
              icps={icps}
              connected={connected}
              listUuid={listUuid}
              data-testid="connection-row"
              ssid={ssid}
            />

            {index === company.connected_global_people_ids.length - 1 &&
              connected &&
              index !== cutoff - 1 && (
                <div>
                  <Divider />
                </div>
              )}
          </>
        )
      }
    })
  }

  const checkShowAvatar = () => {
    if (company.type !== 'persona' && company.type !== 'job' && company.type !== 'other') {
      return true
    } else if (company.type === 'other' && company.avatar_upload_uuid) {
      return true
    } else {
      return false
    }
  }

  const getAvatarUrl = () => {
    if (company.logo_url) {
      return company.logo_url
    } else if (company.avatar_upload_uuid) {
      return `/api/uploads/${company.avatar_upload_uuid}`
    } else {
      return undefined
    }
  }

  const showAvatar = checkShowAvatar()

  const avatarUrl = getAvatarUrl()

  let onCardClick = undefined

  if (company.type == 'other' && !!company.link) {
    onCardClick = () => {
      const link = company.link.startsWith('http') ? company.link : `https://${company.link}`
      if (company.link.includes('getcabal.com')) {
        window.open(link, '_self')
      } else {
        window.open(link, '_blank', 'noopener, noreferrer')
      }
    }
  }

  if (gridView) {
    return (
      <GridCard
        className={cx('h-[120px] items-center ', { 'cursor-pointer': onCardClick })}
        onClick={onCardClick}
      >
        {showAvatar && (
          <div className="flex items-center justify-center w-full my-2">
            <Avatar size="40" name={company.name} src={avatarUrl} className="flex-shrink-0" />
          </div>
        )}
        {!showAvatar && (
          <div className="flex items-center justify-center w-full mt-6 mb-2">
            <i className="fa fa-link" />
          </div>
        )}
        <Typography
          className="text-center truncate pt-1 "
          component="div"
          fontSize="12"
          fontWeight={600}
          data-testid="team-home-company-name"
        >
          {company.name}
        </Typography>

        {company.description && (
          <Typography color="fog" className="text-center truncate" component="p" fontSize="12">
            {company.description}
          </Typography>
        )}
      </GridCard>
    )
  }

  return (
    <CardWrapper className="flex-col" onClick={onCardClick}>
      <div className="flex justify-between items-center w-full select-text cursor-pointer">
        <div className="flex items-center">
          {showAvatar && (
            <Avatar size="32" name={company.name} src={avatarUrl} className="mr-3 flex-shrink-0" />
          )}
          {!showAvatar && company.type == 'other' && (
            <div className="flex items-center justify-center w-8 h-8 mr-3">
              <i className="fa fa-link" />
            </div>
          )}
          <div className="flex flex-col">
            <Typography
              fontSize="14"
              fontWeight={600}
              lineHeight={1.2}
              data-testid="team-home-company-name"
            >
              {company.name}
            </Typography>
            {company.description && (
              <Typography color="fog" fontSize="12" lineHeight={1.2}>
                {company.description}
              </Typography>
            )}
          </div>
        </div>
        <div className="flex-1" />
        {team?.features?.show_add_person && (
          <div>
            <AddPersonInput
              company={company}
              teamSlug={teamSlug}
              onAddPerson={() => {
                queryClient.refetchQueries(['get_home_lists', teamSlug])
              }}
            />
          </div>
        )}
        <div className="flex items-center flex-shrink-0">
          {company.type == 'other' && company.cta != '' && company.link != '' && (
            <div>
              <Typography
                color={'purple'}
                lineHeight="1"
                fontSize="12"
                className="flex items-center"
              >
                <span className="">{company.cta}</span>
                <i className="far fa-chevron-right ml-1" />
              </Typography>
            </div>
          )}
          {people_ids.length == 0 && company.type != 'other' && (
            <div className="flex items-baseline">
              {vote === 1 && sendingVote && (
                <div className="mr-1">
                  <Loading size={12} strokeWidth={5} />
                </div>
              )}
              <AnimatedVoteCTA
                selected={vote === 1}
                selectedText="Offered"
                unselectedText="Offer intro"
                onClick={() => handleLike()}
              />
            </div>
          )}
        </div>
      </div>

      {team?.enable_asks && (
        <>
          {people_ids.length > 0 && <div className="pl-9">{renderPeople()}</div>}

          {people_ids.length > cutoff && (
            <ViewMore component="button" onClick={() => setViewMore(!viewMore)}>
              View More ({people_ids.length - cutoff})
            </ViewMore>
          )}
        </>
      )}
    </CardWrapper>
  )
}

export default CompanyCard
