import React from 'react'

import capitalize from 'lodash/capitalize'
import compact from 'lodash/compact'
import pluralize from 'pluralize'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import CrmIcon from 'components/CrmIcon'
import { EditListModal } from 'components/EditList'
import { useAccessControl } from 'global/AccessControl'
import { RenderModal, useModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { listTypeNames } from 'utils/constants/list'
import copyToClipboard from 'utils/copyToClipboard'
import { cn } from 'utils/styles'
import { CompanyListBlueprint } from 'utils/types'
import { formatCompact } from 'utils/ui'

import ListSharingSettings from '../../../components/EditList/EditListAccess/ListSharingSettings'

interface Props {
  list: CompanyListBlueprint
  teamSlug: string
  reload: () => void
  hideDeleteOption?: boolean
  view?: 'list' | 'grid'
}

const ListRow: React.FC<Props> = ({ list, teamSlug, reload, view = 'list', hideDeleteOption }) => {
  const { showModal } = useModal()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const history = useHistory()

  const title = list.owned ? list.name : `${list.owning_team?.name} - ${list.name}`
  const listUrl = `/${teamSlug}/lists/${list.slug}`
  const description: React.ReactNode[] = []

  const canExport =
    isAdminOrEmployee &&
    !list.default_type_identifier &&
    !(list.list_type === 'people') &&
    !(list.list_type === 'jobs') &&
    !(list.list_type === 'mail') &&
    !(list.list_type === 'other')
  // ^ these list types don't support export yet

  if (isAdminOrEmployee) {
    description.push(
      <>
        {list.dynamic && <CrmIcon className="mr-2" crm={list.provider} />}
        {capitalize(
          listTypeNames[list.list_type === 'people' && list.is_talent ? 'talent' : list.list_type],
        )}
      </>,
    )
  }

  description.push(
    <>
      {formatCompact(list.item_count)} {pluralize('items', list.item_count)}
    </>,
  )

  if (isAdminOrEmployee) {
    let shareIcon = null

    if (list.published) {
      shareIcon = <i className="far fa-users" />
    }

    if (!list.published && list.access_via_link) {
      shareIcon = <i className="far fa-link" />
    }

    if (!list.published && !list.access_via_link) {
      shareIcon = <i className="far fa-lock" />
    }

    if (shareIcon) {
      description.push(
        <span
          className="cursor-pointer"
          onClick={(e: React.SyntheticEvent) => {
            showModal(renderShareListModal, 'render_share_list_modal')
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          {shareIcon}
          {'  Shared'}
        </span>,
      )
    }
  }

  const deleteCompanyListMutation = useMutation(
    ['deleteCompanyList', list.uuid],
    () => callApi(api.deleteCompanyList, teamSlug, list.uuid!),
    {
      onSuccess: () => {
        reload()
        cabalToast({ style: 'success', content: 'List deleted' })
      },
    },
  )

  const renderEditListModal: RenderModal = (resolve) => (
    <EditListModal
      teamSlug={teamSlug}
      onHide={() => resolve(false)}
      reload={() => reload()}
      listUuid={list.uuid}
      onDelete={reload}
      ctaType={list?.cta_type}
      enableGatekeeper={list?.enable_gatekeeper}
      gatekeeper_id={list?.gatekeeper_id}
      dynamicGatekeeper={list?.dynamic_gatekeeper}
    />
  )

  const renderShareListModal: RenderModal = (resolve) => (
    <ListSharingSettings
      teamSlug={teamSlug}
      resolve={() => resolve(false)}
      uuid={list.uuid}
      reload={reload}
      header={`Share ${list.name}`}
    />
  )

  const menuItems = compact([
    !list.default_type_identifier || list.default_type_identifier != 'all_connections'
      ? {
          type: 'action',
          onSelect: () => showModal(renderShareListModal, 'render_share_list_modal'),
          label: 'Share',
        }
      : null,
    {
      type: 'action',
      onSelect: () => {
        copyToClipboard(list.list_url)
        cabalToast({ style: 'success', content: 'Copied to clipboard!' })
      },
      label: 'Copy link',
    },
    list.owned && !list.default_type_identifier && isAdminOrEmployee
      ? {
          type: 'action',
          onSelect: () => showModal(renderEditListModal, 'render_edit_list_modal'),
          label: 'Edit',
        }
      : null,
    list.owned && !list.default_type_identifier && isAdminOrEmployee && !hideDeleteOption
      ? {
          type: 'action',
          onSelect: () => confirm('Are you sure?') && deleteCompanyListMutation.mutate(),
          label: 'Delete',
        }
      : null,
    canExport && {
      type: 'action',
      onSelect: () => {
        api.exportList(list.uuid)

        cabalToast({
          content: 'Your export will be emailed to you shortly',
          style: 'success',
        })
      },
      label: 'Export',
    },
  ])

  return (
    <>
      <DataCardV2
        onClick={() => history.push(listUrl)}
        avatar={
          view === 'grid' && (
            <Typography color="purple" fontSize="28">
              <i className="fal fa-list-alt" />
            </Typography>
          )
        }
        title={title}
        titleIcon={
          list.published && (
            <Typography fontSize="10" className="ml-1" color="fog_rain">
              <i className="fa fa-home" />
            </Typography>
          )
        }
        description={description}
        dropDownMenu={{ menuItems: menuItems }}
        className={cn(' dark:border-[#2d3748] border-[#E3E5E8] ', {
          'border-b-[1px] rounded-none hover:rounded': view === 'list',
        })}
        cta={
          <Typography
            color="purple"
            fontSize="12"
            component="button"
            onClick={() => history.push(listUrl)}
          >
            View
          </Typography>
        }
        view={view}
      />
    </>
  )
}

export default ListRow
