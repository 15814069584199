import React, { useEffect, useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'

import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import CabalButton from 'global/CabalButton'
import UploadZone from 'global/Input/UploadZone'
import Pill from 'global/Pill'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { UploadModel } from 'utils/types'

interface IImportError {
  csv_line: number
  error_full_messages: string[]
}

export interface IImportData {
  completed_steps: number | null
  import_errors: IImportError[] | null
  status: 'failed' | 'running' | 'completed'
  total_steps: number | null
  unexpected_errors: string | null
  import_job_id: number | null
}

interface Props {
  setOnClose: () => void
  isPeople?: boolean
}

const ProgressBarInner = styled.div`
  background-color: ${({ theme }) => theme.buttons.primary.bg_color};
`

const ProgressBar = ({ percent }: any) => (
  <div className="w-full bg-gray-300 rounded-full h-2.5">
    <ProgressBarInner
      className="bg-blue-600 h-2.5 rounded-full"
      style={{ width: percent }}
    ></ProgressBarInner>
  </div>
)

const ImportSection: React.FC<Props> = ({ setOnClose, isPeople = false }) => {
  // const [importData, setImportData] = useState<IImportData>()
  const [syncStatus, setSyncStatus] = useState<
    'idle' | 'running' | 'completed' | 'error' | 'failed'
  >('idle')
  // const { cable } = useContext(ActionCableContext)
  const { companyListData: companyList, refetchCompanyList } = useSalesListContext()
  const [progressPercent, setProgressPercent] = useState('5%')
  const [importJobId, setImportJobId] = useState<number | null>(null)

  useEffect(() => {
    if (!importJobId) {
      if (companyList.running_imports && companyList.running_imports.length > 0) {
        const latestImportJob = companyList.running_imports.reduce((latest, job) => {
          return new Date(job.updated_at) > new Date(latest.updated_at) ? job : latest
        })
        setImportJobId(latestImportJob.id)
        setSyncStatus('running')
      }
    }
  }, [companyList.running_imports])

  const { mutate: importFile } = useMutation(
    (upload: UploadModel) => callApi(api.import, upload.url, upload.uuid, companyList.uuid),
    {
      onSuccess: (data) => {
        setImportJobId(data.import_job_id)
        refetchCompanyList()
      },
    },
  )

  const { data: importStatus } = useQuery(
    ['connectionStatus', importJobId],
    () => callApi(api.importStatus, importJobId!, companyList.uuid),
    {
      refetchInterval: 1000 * 3,
      refetchIntervalInBackground: !!importJobId,
      enabled: !!importJobId,
    },
  )

  useEffect(() => {
    if (!importStatus) return
    const data = importStatus
    const { completed_steps, total_steps, status, unexpected_errors } = data

    const updateProgress = () => {
      let calc = 0
      if (completed_steps && total_steps) {
        calc = (completed_steps / total_steps) * 100
        setProgressPercent(`${Math.trunc(calc)}`)
      }
    }

    const handleCompletion = () => {
      setProgressPercent(`100`)
      setTimeout(() => {
        cabalToast({
          content: 'Import completed',
          style: 'success',
        })
        setSyncStatus('completed')
        refetchCompanyList()
        setOnClose()
      }, 1500)
    }

    const handleError = () => {
      if (unexpected_errors) {
        cabalToast({
          style: 'error',
          content: 'An unexpected error occurred. Please contact support.',
        })
      }
      setSyncStatus('error')
    }

    switch (status) {
      case 'running':
        setSyncStatus('running')
        updateProgress()
        break
      case 'completed':
        handleCompletion()
        break
      case 'failed':
        handleError()
        break
      default:
        break
    }
  }, [importStatus])

  const templateUrl = isPeople ? '/cabal_sales_people_list.csv' : '/cabal_sales_company_list.csv'
  const googleSheetUrl = isPeople
    ? 'https://docs.google.com/spreadsheets/d/11UsUsottQBW_08lzd0b4DOtH7LAJ45k5NVAqilNmP1k/edit?usp=sharing'
    : 'https://docs.google.com/spreadsheets/d/1BYggCaPjxQ2zYHIUgAKCjHTr5VSa8gv_R3F7Zn3dCCA/edit?usp=sharing'
  const importingObjects = isPeople ? 'people' : 'companies'

  return (
    <div className="mt-4">
      <div className="flex px-2 gap-2">
        <CabalButton
          padding="0"
          variant="tertiary"
          onClick={() => {
            window.open(templateUrl)
          }}
        >
          Download CSV Template
        </CabalButton>

        <Typography color="fog">{' • '}</Typography>

        <CabalButton
          variant="tertiary"
          onClick={() => {
            window.open(googleSheetUrl, '_blank', 'noreferrer')
          }}
          padding="0"
        >
          Open in Google Sheets
        </CabalButton>
      </div>

      {(syncStatus === 'idle' || syncStatus === 'error') && (
        <UploadZone
          uploadOnSelect
          hideFiles={true}
          hideProgress={true}
          accept={['csv', 'zip']}
          onUpload={(upload) => {
            if (!upload.length) return
            importFile(upload[0])
          }}
          beforeUpload={() => {
            setImportJobId(null)
            setSyncStatus('running')
          }}
        />
      )}

      {syncStatus === 'running' && (
        <>
          <div className="mt-3">
            <ProgressBar percent={progressPercent} />
          </div>
          <Typography color="fog" fontSize="12" className="mt-3 flex items-center justify-center">
            {`Adding ${importingObjects} to your list (${progressPercent}%)`}
          </Typography>
        </>
      )}

      {(syncStatus === 'failed' || syncStatus === 'error') &&
        importStatus?.import_errors &&
        importStatus.import_errors.length > 0 && (
          <div className="mt-3">
            <Typography color="red" fontSize="12">
              Errors:
            </Typography>
            <div className="flex flex-wrap gap-1">
              {importStatus.import_errors.map((error, index) =>
                error.error_messages.map((message, i) => (
                  <Pill
                    variant="gray"
                    key={`${index}${i}`}
                    title={`Line ${error.csv_line} : ${message}`}
                  >
                    <Typography>{`Line ${error.csv_line} : ${message}`}</Typography>
                  </Pill>
                )),
              )}
            </div>
          </div>
        )}
    </div>
  )
}

export default ImportSection
