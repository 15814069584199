import React, { useEffect, useState } from 'react'

import { SeeMore } from 'containers/CandidateProfile'
import ShareModal from 'containers/GlobalUserProfile/ShareModal'
import { LEVEL_MAP, ROLE_FUNCTION_MAP } from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

import AboutModal from './Modals/AboutModal'
import { useQuery } from 'react-query'
import { CandidateProfile, CandidateProfileAbout } from 'utils/types'
import api, { callApi } from 'utils/api'
import { useParams } from 'react-router-dom'

const OptionField = ({ title, value }: { title: string; value: string }) => {
  return (
    <div>
      <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
        {title}
      </Typography>

      <Typography fontSize="12" component="div" color="fog">
        {value}
      </Typography>
    </div>
  )
}

const About = ({canEdit}: {canEdit: boolean}) => {
  const { slug } = useParams()
  const [bio, setBio] = useState('')
  const [bioLength, setBioLength] = useState(0)
  const [isExpanded, setIsExpanded] = useState(bioLength < 165 || false)


  const { data: candidateProfile, isLoading, error } = useQuery<CandidateProfileAbout>(
    ['globalPersonAbout', slug],
    () => callApi(api.getGlobalPersonAbout, { slug }),
    {
      enabled: !!slug,
    }
  )

  useEffect(() => {
    if (candidateProfile?.about) {
      setBioLength(candidateProfile.about.length || 0)
      setBio(isExpanded ? candidateProfile?.about : `${candidateProfile?.about?.slice(0, 165)}`)
      
    }
  }, [candidateProfile])

  const { showModal } = useModal()

  const renderAboutModal = (resolve: () => void) => (
    <AboutModal
      onHide={resolve}
      slug={slug}
      options={{
        function: candidateProfile?.function || '',
        location: candidateProfile?.location || '',
        level: candidateProfile?.level || '',
        about: candidateProfile?.about || '',
      }}
    />
  )

  const renderShareModal = (resolve: () => void) => <ShareModal onHide={resolve} header="Share" />

  return (
    <Widget
      title="About"
      className="group"
      cta={
        canEdit && (
          <div className="group-hover:flex hidden flex-row gap-8">
            <CabalButton
              variant="link"
              padding={'0'}
              leftIcon={<i className="far fa-edit" />}
              onClick={() => showModal(renderAboutModal, 'about-modal')}
          >
            Edit
          </CabalButton>
        </div>
        )
      }
    >
      {bio && (
        <p className="leading-5 mb-2">
          <Typography fontSize="12" color="fog" lineHeight={1.2}>
            {bio}
          </Typography>

          {bio.length > 165 && (
            <SeeMore
              className="ml-1 cursor-pointer"
              color="fog"
              fontSize="12"
              lineHeight={1.2}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? '...see less' : '...see more'}
            </SeeMore>
          )}
        </p>
      )}

      <div className="flex flex-col mt-6">
        <div className="flex justify-between">
          <OptionField title="Function" value={ROLE_FUNCTION_MAP[candidateProfile?.function]} />
          <OptionField title="Level" value={LEVEL_MAP[candidateProfile?.level]} />
          <OptionField title="Location" value={candidateProfile?.location} />
        </div>
      </div>
    </Widget>
  )
}

export default About
