import React from 'react'

import { Field, FieldProps, Form, Formik } from 'formik'
import styled from 'styled-components'
import tw from 'twin.macro'
import { array, object, string } from 'yup'

import {
  CandidateProfileInputEducationHistoryEntry,
  CandidateProfileInputWorkHistoryEntry,
  Footer,
  useCandidateProfile,
} from 'containers/CandidateProfileModal'
import MultiDocumentUploader from 'containers/CandidateProfileModal/MultiDocumentUploader'
import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'
import {
  LEVEL_OPTIONS,
  ROLE_FUNCTION_OPTIONS,
  STAGE_OPTIONS,
} from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import { MultiSelectField, Select, SelectField, TextArea, TextInput } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'

const EntryWrapper = styled.div`
  border-bottom: solid 1px ${(props) => props.theme.colors.gray100};
`

const StyledHr = styled.hr`
  ${tw`pt-2 pb-2`}
  border-top: ${({ theme }) => theme.border};
`

const months = [
  {
    value: 1,
    label: 'January',
  },
  {
    value: 2,
    label: 'February',
  },
  {
    value: 3,
    label: 'March',
  },
  {
    value: 4,
    label: 'April',
  },
  {
    value: 5,
    label: 'May',
  },
  {
    value: 6,
    label: 'June',
  },
  {
    value: 7,
    label: 'July',
  },
  {
    value: 8,
    label: 'August',
  },
  {
    value: 9,
    label: 'September',
  },
  {
    value: 10,
    label: 'October',
  },
  {
    value: 11,
    label: 'November',
  },
  {
    value: 12,
    label: 'December',
  },
]

const careerInfoSchema = object({
  functions: array()
    .of(string())
    .min(1, 'At least one option must be selected')
    .required('This field is required'),
  level: string().required('This field is required'),
})

const years = Array(50)
  .fill(null)
  .map((_, i) => {
    const year = new Date().getFullYear() - i
    return {
      value: year,
      label: year.toString(),
    }
  })

export function WorkHistoryEntry(props: {
  isLast?: boolean
  entry: CandidateProfileInputWorkHistoryEntry
  onUpdate: (entry: Partial<CandidateProfileInputWorkHistoryEntry>) => unknown
  hasMultipleEntries: boolean
  onRemove: () => unknown
}) {
  
  return (
    <EntryWrapper
      className="pb-5 pt-2"
      style={{ borderBottomColor: props.isLast ? 'transparent' : undefined }}
    >
      <input type="hidden" value={props.entry.uuid} />
      <div className="flex flex-col gap-3">
        <ModalInputWrapper>
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Company</div>
          </ModalInputLabel>
          <TextInput
            value={props.entry.company}
            onChange={(e) =>
              props.onUpdate({
                company: e.target.value,
              })
            }
            placeholder="Enter the company name"
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Title</div>
          </ModalInputLabel>
          <TextInput
            value={props.entry.title}
            onChange={(e) =>
              props.onUpdate({
                title: e.target.value,
              })
            }
            placeholder="Title"
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">From</div>
          </ModalInputLabel>

          <div className="flex gap-4">
            <div className="flex-1">
              <SelectWrapper>
                <Select
                  className="flex-1"
                  style={{ minWidth: 'initial' }}
                  placeholder="Month"
                  value={props.entry.fromMonth}
                  onChange={(month) => props.onUpdate({ fromMonth: month })}
                  options={months}
                />
              </SelectWrapper>
            </div>
            <div className="flex-1">
              <SelectWrapper>
                <Select
                  className="flex-1"
                  style={{ minWidth: 'initial' }}
                  placeholder="Year"
                  value={props.entry.fromYear}
                  onChange={(year) => props.onUpdate({ fromYear: year })}
                  options={years}
                />
              </SelectWrapper>
            </div>
          </div>
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">To</div>
          </ModalInputLabel>

          <div className="flex gap-4">
            <div className="flex-1">
              <SelectWrapper>
                <Select
                  className="flex-1"
                  style={{ minWidth: 'initial' }}
                  placeholder="Month"
                  options={months}
                  value={props.entry.toMonth}
                  onChange={(month) => props.onUpdate({ toMonth: month })}
                />
              </SelectWrapper>
            </div>
            <div className="flex-1">
              <SelectWrapper>
                <Select
                  className="flex-1"
                  style={{ minWidth: 'initial' }}
                  placeholder="Year"
                  options={years}
                  value={props.entry.toYear}
                  onChange={(year) => props.onUpdate({ toYear: year })}
                />
              </SelectWrapper>
            </div>
          </div>
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>
            <div className="flex items-start py-2 justify-start h-full">Summary</div>
          </ModalInputLabel>
          <TextArea
            value={props.entry.summary}
            onChange={(e) => props.onUpdate({ summary: e.target.value })}
          />
        </ModalInputWrapper>
      </div>

      {props.hasMultipleEntries && (
        <div className="flex justify-end">
          <CabalButton
            variant="tertiary"
            leftIcon={<i className="far fa-trash" />}
            onClick={props.onRemove}
            type="button"
          >
            Remove
          </CabalButton>
        </div>
      )}
    </EntryWrapper>
  )
}

export function EducationEntry(props: {
  isLast?: boolean
  entry: CandidateProfileInputEducationHistoryEntry
  onUpdate: (entry: Partial<CandidateProfileInputEducationHistoryEntry>) => unknown
  onRemove: () => unknown
}) {
  return (
    <EntryWrapper
      className="pb-5 pt-2"
      style={{ borderBottomColor: props.isLast ? 'transparent' : undefined }}
    >
      <input type="hidden" value={props.entry.uuid} />
      <div className="flex flex-col gap-3">
        <ModalInputWrapper>
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">School</div>
          </ModalInputLabel>
          <TextInput
            value={props.entry.school}
            placeholder="Enter the school"
            onChange={(e) =>
              props.onUpdate({
                school: e.target.value,
              })
            }
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">Field of Study</div>
          </ModalInputLabel>
          <TextInput
            value={props.entry.fieldOfStudy}
            placeholder="Enter the field of study"
            onChange={(e) => {
              props.onUpdate({
                fieldOfStudy: e.target.value,
              })
            }}
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">From</div>
          </ModalInputLabel>

          <div className="flex gap-4">
            <div className="flex-1">
              <SelectWrapper>
                <Select
                  className="flex-1"
                  style={{ minWidth: 'initial' }}
                  placeholder="Month"
                  options={months}
                  value={props.entry.fromMonth}
                  onChange={(month) => {
                    props.onUpdate({
                      fromMonth: month,
                    })
                  }}
                />
              </SelectWrapper>
            </div>
            <div className="flex-1">
              <SelectWrapper>
                <Select
                  className="flex-1"
                  style={{ minWidth: 'initial' }}
                  placeholder="Year"
                  options={years}
                  value={props.entry.fromYear}
                  onChange={(year) => {
                    props.onUpdate({
                      fromYear: year,
                    })
                  }}
                />
              </SelectWrapper>
            </div>
          </div>
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>
            <div className="flex items-center justify-start h-full">To</div>
          </ModalInputLabel>

          <div className="flex gap-4">
            <div className="flex-1">
              <SelectWrapper>
                <Select
                  className="flex-1"
                  style={{ minWidth: 'initial' }}
                  placeholder="Month"
                  options={months}
                  value={props.entry.toMonth}
                  onChange={(month) => {
                    props.onUpdate({
                      toMonth: month,
                    })
                  }}
                />
              </SelectWrapper>
            </div>
            <div className="flex-1">
              <SelectWrapper>
                <Select
                  className="flex-1"
                  style={{ minWidth: 'initial' }}
                  placeholder="Year"
                  options={years}
                  value={props.entry.toYear}
                  onChange={(year) => {
                    props.onUpdate({
                      toYear: year,
                    })
                  }}
                />
              </SelectWrapper>
            </div>
          </div>
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>
            <div className="flex items-start py-2 justify-start h-full">Summary</div>
          </ModalInputLabel>
          <TextArea
            value={props.entry.summary}
            onChange={(e) => {
              props.onUpdate({
                summary: e.target.value,
              })
            }}
          />
        </ModalInputWrapper>
      </div>

      <div className="flex justify-end">
        <CabalButton
          variant="tertiary"
          leftIcon={<i className="far fa-trash" />}
          onClick={props.onRemove}
          type="button"
        >
          Remove
        </CabalButton>
      </div>
    </EntryWrapper>
  )
}

export default function WorkAndEducationHistory(props: {
  onBack: () => unknown
  onNext: () => unknown
  creatingCandidateProfile?: boolean
}) {
  const { candidateProfile, updateCandidateProfile } = useCandidateProfile()

  const [uploadedDocuments, setUploadedDocuments] = React.useState<File[]>([])
  const handleDocumentsUploaded = (documents: File[]) => {
    setUploadedDocuments(documents)
  }
  const topRef = React.createRef<HTMLDivElement>()

  return (
    <div ref={topRef}>
      <div className="px-4">
        <Formik
          initialValues={candidateProfile}
          validationSchema={careerInfoSchema}
          onSubmit={(values) => {
            updateCandidateProfile({
              ...values,
              bio: candidateProfile.bio,
              documents: uploadedDocuments,
              workHistory: candidateProfile.workHistory,
              educationHistory: candidateProfile.educationHistory,
            })
            props.onNext()
          }}
        >
          {({ handleSubmit }) => (
            <Form
              onSubmit={handleSubmit}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                }
              }}
            >
              <ModalInputWrapper className="py-2 text-left">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Function</div>
                </ModalInputLabel>
                <Field name="functions">
                  {(props: FieldProps) => (
                    <MultiSelectField
                      placeholder="Primary job function(s)"
                      options={ROLE_FUNCTION_OPTIONS}
                      fieldProps={props}
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Level</div>
                </ModalInputLabel>
                <SelectWrapper>
                  <Field name="level">
                    {(props: FieldProps) => (
                      <SelectField
                        placeholder="Experience level"
                        fieldProps={props}
                        options={LEVEL_OPTIONS}
                      />
                    )}
                  </Field>
                </SelectWrapper>
              </ModalInputWrapper>
              <ModalInputWrapper className="py-2 text-left">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Stage Experience</div>
                </ModalInputLabel>
                <Field name="fundingStageExperience">
                  {(props: FieldProps) => (
                    <MultiSelectField
                      placeholder="Company stage experience"
                      fieldProps={props}
                      options={STAGE_OPTIONS}
                    />
                  )}
                </Field>
              </ModalInputWrapper>
              <div className="mb-4">
                <ModalInputWrapper className="py-2">
                  <ModalInputLabel>
                    <div className="flex items-center justify-start h-full">Bio</div>
                  </ModalInputLabel>
                  <TextArea
                    placeholder="Add a one-line bio"
                    value={candidateProfile.bio}
                    onChange={(e) => updateCandidateProfile({ bio: e.target.value })}
                  />
                </ModalInputWrapper>
                {props.creatingCandidateProfile && (
                  <MultiDocumentUploader
                    uploadedDocuments={uploadedDocuments}
                    onDocumentsUploaded={(documents) => handleDocumentsUploaded(documents)}
                  />
                )}
              </div>

              <StyledHr />
              <div className="flex justify-between items-center">
                <Typography fontSize="14" fontWeight={600}>
                  Work History
                </Typography>
                <CabalButton
                  variant="link"
                  type="button"
                  onClick={() => {
                    updateCandidateProfile({
                      workHistory: [
                        {
                          company: '',
                          title: '',
                          fromMonth: null,
                          fromYear: null,
                          toMonth: null,
                          toYear: null,
                          summary: '',
                          key: Date.now(),
                          uuid: crypto.randomUUID(),
                        },
                        ...candidateProfile.workHistory,
                      ],
                    })
                  }}
                >
                  + Add More
                </CabalButton>
              </div>
              {candidateProfile.workHistory.map((workHistory, i) => (
                <WorkHistoryEntry
                  key={`${workHistory.uuid || workHistory.key}-${i}`}
                  entry={workHistory}
                  onUpdate={(newEntry) => {
                    const newWorkHistory = [...candidateProfile.workHistory]
                    newWorkHistory[i] = {
                      ...newWorkHistory[i],
                      ...newEntry,
                    }
                    updateCandidateProfile({ workHistory: newWorkHistory })
                  }}
                  hasMultipleEntries={candidateProfile.workHistory.length > 1}
                  onRemove={() => {
                    updateCandidateProfile({
                      workHistory: candidateProfile.workHistory.filter(
                        (_, innerIndex) => innerIndex !== i,
                      ),
                    })
                  }}
                  isLast={i === candidateProfile.workHistory.length - 1}
                />
              ))}

              <StyledHr />

              <div className="flex justify-between items-center">
                <Typography fontSize="14" fontWeight={600}>
                  Education
                </Typography>
                <CabalButton
                  variant="link"
                  type="button"
                  onClick={() => {
                    updateCandidateProfile({
                      educationHistory: [
                        {
                          school: '',
                          fieldOfStudy: '',
                          fromMonth: null,
                          fromYear: null,
                          toMonth: null,
                          toYear: null,
                          summary: '',
                          key: Date.now(),
                          uuid: crypto.randomUUID(),
                        },
                        ...candidateProfile.educationHistory,
                      ],
                    })
                  }}
                >
                  + Add More
                </CabalButton>
              </div>
              {candidateProfile.educationHistory.map((entry, i) => (
                <EducationEntry
                  key={`${entry.uuid || entry.key}-${i}`}
                  entry={entry}
                  isLast={i === candidateProfile.educationHistory.length - 1}
                  onUpdate={(newEntry) => {
                    const newEducationHistory = [...candidateProfile.educationHistory]
                    newEducationHistory[i] = {
                      ...newEducationHistory[i],
                      ...newEntry,
                    }
                    updateCandidateProfile({ educationHistory: newEducationHistory })
                  }}
                  onRemove={() => {
                    updateCandidateProfile({
                      educationHistory: candidateProfile.educationHistory.filter(
                        (_, innerIndex) => innerIndex !== i,
                      ),
                    })
                  }}
                />
              ))}
              <Footer className="-mx-6 flex justify-between mt-4 pt-6 pb-5 px-6">
                <CabalButton
                  type="button"
                  variant="tertiary"
                  leftIcon={<i className="far fa-chevron-left" />}
                  onClick={props.onBack}
                  padding="0"
                >
                  Back
                </CabalButton>

                <CabalButton
                  type="button"
                  variant="primary"
                  rightIcon={<i className="far fa-arrow-right" />}
                  onClick={() => {
                    if (topRef.current) topRef.current.scrollIntoView({ behavior: 'instant' })
                    handleSubmit()
                  }}
                  className="w-1/4"
                >
                  Next
                </CabalButton>
              </Footer>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
