import React, { useEffect } from 'react'

import { compose } from 'msw'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import { useComposer } from 'components/Composer'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'

import hubspotImage from '../../../../assets/images/hubspot-200.png'
import salesforceImage from '../../../../assets/images/salesforce-200.png'
import HeaderSection from '../HeaderSection'
import ListCard from './ListCard'

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 700px) {
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 6rem;
  }
`

const NewScreenMail = () => {
  const teamSlug = useTeamSlug()
  const { compose } = useComposer()
  const { team } = useTeam(teamSlug)

  const getTemplatesQuery = useQuery(
    [teamSlug, 'getTemplates'],
    () => callApi(api.getTemplates, teamSlug),
    {
      keepPreviousData: true,
    },
  )
  const templates = getTemplatesQuery.data?.templates

  const [investorUpdateId, setInvestorUpdateId] = React.useState('')
  const [salesReferralId, setSalesReferralId] = React.useState('')
  const [founderOnboardingId, setFounderOnboardingId] = React.useState('')
  const [talentFollowUpId, setTalentFollowUpId] = React.useState('')

  useEffect(() => {
    templates?.forEach((t) => {
      if (t.name.includes('Investor Update')) {
        setInvestorUpdateId(t.uuid)
      } else if (t.name.includes('Intro Request')) {
        setSalesReferralId(t.uuid)
      } else if (t.name.includes('Founder Onboarding')) {
        setFounderOnboardingId(t.uuid)
      } else if (t.name.includes('Talent Follow Up')) {
        setTalentFollowUpId(t.uuid)
      }
    })
  }, [templates])

  return (
    <div className="max-w-4xl flex flex-col self-center">
      <HeaderSection
        icon={<i className="fal fa-inbox"></i>}
        title={'No Messages'}
        description={`Send personalized email to your Members. Use templates to save time and track engagement.`}
      />
      <GridWrapper className="px-4 sm:px-12 pb-10 gap-4">
        {team?.enable_portfolio ? (
          <>
            <ListCard
              icon={<i className="fal fa-envelope-open"></i>}
              title={'Founder Onboarding'}
              onClick={() => compose({ template_uuid: founderOnboardingId })}
            />
            <ListCard
              icon={<i className="fal fa-handshake"></i>}
              title={'Talent Follow-up'}
              onClick={() => compose({ template_uuid: talentFollowUpId })}
            />
          </>
        ) : (
          <>
            <ListCard
              icon={<i className="fal fa-newspaper"></i>}
              title={'Investor Update'}
              onClick={() =>
                compose({
                  template_uuid: investorUpdateId,
                })
              }
            />
            <ListCard
              icon={<i className="fal fa-handshake"></i>}
              title={'Sales Referral'}
              onClick={() =>
                compose({
                  template_uuid: salesReferralId,
                })
              }
            />
          </>
        )}

        <ListCard
          icon={<i className="fal fa-edit"></i>}
          title={'New Draft'}
          onClick={() => compose()}
        />
      </GridWrapper>
    </div>
  )
}

export default NewScreenMail
