import { object, string } from 'yup'

export const LINKEDIN_URL_REGEX = /^https?:\/\/(www\.)?linkedin\.com\/in\/[\w-]+\/?$/

export const validateLinkedInUrl = (url: string): boolean => {
  return LINKEDIN_URL_REGEX.test(url)
}

export const getLinkedInValidationError = (touched: boolean, value: string): string | undefined => {
  if (!touched) return undefined

  if (!value) return 'LinkedIn URL is required'

  return !validateLinkedInUrl(value) ? 'Please enter a valid LinkedIn URL' : undefined
}

export const linkedInUrlSchema = string()
  .required('LinkedIn URL is required')
  .matches(LINKEDIN_URL_REGEX, 'Please enter a valid LinkedIn URL')

export const linkedInFormSchema = object({
  linkedin_url: linkedInUrlSchema,
})
