import styled from 'styled-components'
import tw from 'twin.macro'

export const SearchContainer = styled.div`
  ${tw`rounded flex items-center pl-3`}

  background-color: ${({ theme }) => theme.colors.shadow};
`

export const HeaderContainer = styled.div`
  ${tw`flex justify-between my-5`}

  @media (max-width: 640px) {
    display: block;
  }
`

export const EditPortfolioButtonContainer = styled.div`
  display: inline-block;

  @media (max-width: 640px) {
    margin-top: 1.25rem;
  }
`

export const RowParent = styled.div`
  display: grid;
  grid-template-columns: 0.75fr 6fr 3fr 2fr;
  grid-template-rows: repeat(1, 1fr);
  align-items: center;
`

export const HeaderRowParent = styled(RowParent)`
  ${tw`px-2 py-1 sticky top-0`}

  background-color: ${({ theme }) => theme.colors.table_header};

  button,
  & > div {
    text-transform: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    font-size: inherit;
  }

  button {
    display: inline;
  }
`

export const CompanyRowParent = styled(RowParent)`
  ${tw`p-2 mt-1`}

  background-color: ${({ theme }) => theme.colors.shadow};
`

export const ScrollableContainer = styled.div`
  @media (max-width: 640px) {
    max-width: 100%;
    overflow: scroll;
  }
`

export const TableContainer = styled.div`
  @media (max-width: 640px) {
    max-width: 100vw;
  }
`

export const ButtonsContainer = styled.div`
  ${tw`inline-block`}

  @media (max-width: 640px) {
    margin-top: 0.5rem;
  }
`

export const FacilitateIntroModalNote = styled.div`
  & p {
    ${tw`py-1`}
  }
`
