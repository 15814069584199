import React, { useEffect } from 'react'

import { useMutation } from 'react-query'

import AnimatedVoteCTA from 'global/AnimatedVoteCTA'
import Loading from 'global/Loading'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { PersonBlueprint, VoteType } from 'utils/types'

const SalesCompanyRow = ({ person, list_uuid }: { person: PersonBlueprint; list_uuid: string }) => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const [vote, setVote] = React.useState<VoteType>(person.current_user_upvoted ? 1 : 0)

  useEffect(() => {
    setVote(person.current_user_upvoted ? 1 : 0)
  }, [person])

  const linkedinSearchUrl = (name: string) => {
    return `https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(
      name,
    )}&origin=GLOBAL_SEARCH_HEADER`
  }

  const { mutate: sendVote, isLoading: sendingVote } = useMutation(
    ['createVote'],
    (vote: 1 | -1 | 0) =>
      callApi(api.createVote, {
        team_slug: teamSlug,
        votable_type: 'GlobalPerson',
        list_uuid: list_uuid,
        votable_id: person.uuid,
        vote,
      }),
    {
      onSuccess: (_, variables) => {
        setVote(variables)
        if (variables == 1) {
          cabalToast({
            content: `Your offer to introduce ${person.name} has been logged. ${team?.name} will follow up with you via email. `,
            style: 'success',
          })
        }
      },
    },
  )

  return (
    <DataCardV2
      title={
        <Typography
          component="button"
          onClick={(e) => {
            e.stopPropagation()
            if (!!person.linkedin_url) {
              window.open(person.linkedin_url, '_blank')
            } else {
              window.open(linkedinSearchUrl(person.name), '_blank')
            }
          }}
        >
          {person.name || person.email}
        </Typography>
      }
      className="py-0 h-[30px] rounded-lg"
      titleIcon={
        <>
          <Tooltip label={`You are connected to ${person.name} on Linkedin`}>
            <Typography fontSize="12" lineHeight={1} color="fog_rain">
              1st
            </Typography>
          </Tooltip>
          <Typography fontSize="12" lineHeight={'15px'} color={'fog'} className="">
            {'·'}
          </Typography>
          {person.linkedin_url && (
            <Typography fontSize="12" color="fog_rain" className="cursor-pointer" lineHeight="1">
              <a
                href={person.linkedin_url}
                target="_blank"
                rel="nofollow noopener noreferrer"
                className={'cursor-pointer'}
                onClick={(e) => e.stopPropagation()}
              >
                <i className="fab fa-linkedin fa-fw"></i>
              </a>
            </Typography>
          )}
          {!person.linkedin_url && (
            <Typography fontSize="12" color="fog_rain" className="cursor-pointer" lineHeight="1">
              <a
                href={linkedinSearchUrl(person.name)}
                target="_blank"
                rel="nofollow noopener noreferrer"
                className={'cursor-pointer'}
                onClick={(e) => e.stopPropagation()}
              >
                <i className="fab fa-linkedin fa-fw"></i>
              </a>
            </Typography>
          )}

          <Typography fontSize="12" lineHeight={'15px'} color={'fog'} className="">
            {'·'}
          </Typography>
          <div className="flex truncate max-w-[200px]">
            <Typography color="fog_rain" fontSize="12" className="block cursor-pointer truncate">
              {person.headline}
            </Typography>
          </div>
        </>
      }
      cta={
        <AnimatedVoteCTA
          selected={vote === 1}
          selectedText="Offered"
          unselectedText="Offer intro"
          onClick={() => sendVote(vote === 0 ? 1 : 0)}
        />
      }
    />
  )
}

export default SalesCompanyRow
