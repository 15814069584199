import React from 'react'

import HeadShake from 'react-reveal/HeadShake'

import { useComposer } from 'components/Composer'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import { useModal } from 'global/Modal'
import { useTeamSlug } from 'store/hooks'

import useTeamSetting from 'utils/hooks/useTeamSetting'
import { InvestorCompany } from 'utils/types/investor'

import BulkEditCompaniesModal from '../BulkEditCompaniesModal'
import SharePortfolioModal from '../SharePortfolioModal'

interface Props {
  companies: InvestorCompany[]
  setCompanies: (companies: InvestorCompany[]) => void
  allTags: string[]
  setAllTags: (tags: string[]) => void
  allStages: string[]
  setAllStages: (stages: string[]) => void
  selectedUuids: Set<string>
  companiesRef: React.MutableRefObject<InvestorCompany[]>
}

const GlobalActions: React.VFC<Props> = ({
  selectedUuids,
  companies,
  allTags,
  setAllTags,
  allStages,
  setAllStages,
  setCompanies,
  companiesRef,
  openImportCompaniesModal,
}) => {
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()
  const { compose } = useComposer(teamSlug)
  const { canViewPortfolio } = useAccessControl(teamSlug)

  const { canEditPortfolio, canSendMessages, canManageCompany } = useAccessControl(teamSlug)

  const openSharePortfolioModal = () => {
    showModal(
      (resolve) => <SharePortfolioModal teamSlug={teamSlug} onHide={resolve} />,
      'share-portfolio-modal',
    )
  }

  const openBulkEditCompaniesModal = (field: string) => {
    showModal(
      (resolve) => (
        <BulkEditCompaniesModal
          selectedCompanyUuids={selectedUuids}
          companies={companies as InvestorCompany[]}
          setCompanies={setCompanies}
          allTags={allTags}
          teamSlug={teamSlug}
          onHide={resolve}
          setAllTags={setAllTags}
          allStages={allStages}
          setAllStages={setAllStages}
          field={field}
        />
      ),
      'bulk-edit-portfolio-company-modal',
    )
  }

  const getComposerBody = (selectedCompanies: InvestorCompany[]) => {
    let toInsert = `<p>`
    selectedCompanies.forEach((company) => {
      const { company_name, uuid, description } = company
      const requestUrl = `${window.location.origin}/investor_companies/${uuid}/intro_requests/confirm?team_slug=${teamSlug}`
      let descriptionElement = ''
      if (description) descriptionElement = `${description}<br>`
      toInsert += `
            <li>
              <b>${company_name}</b><br>
              ${descriptionElement}
              <a href="${requestUrl}">Request Intro</a><br>
            </li>
        `
    })

    return toInsert + '</p>'
  }

  return (
    <div className="flex space-x-1">
      {selectedUuids.size > 0 && (
        <div>
          <DropDownMenu
            trigger={
              <HeadShake>
                <CabalButton
                  component="div"
                  variant="secondary"
                  size="small"
                  rightIcon={<i className="fas fa-caret-down" />}
                  data-testid="bulk-edit-portfolio-companies-btn"
                >
                  Bulk Action
                </CabalButton>
              </HeadShake>
            }
            menuItems={[
              {
                label: <div data-testid="share-in-email-action">Send Message</div>,
                onSelect: () => {
                  const selectedCos = companies?.filter((c) => selectedUuids.has(c.uuid)) || []

                  compose({ body: getComposerBody(selectedCos) })
                },
              },
              {
                label: <div data-testid="add-tags-action">Add Tags</div>,
                onSelect: () => openBulkEditCompaniesModal('tags'),
              },
              {
                label: <div data-testid="add-owners-action">Add Owners</div>,
                onSelect: () => openBulkEditCompaniesModal('owners'),
              },
              {
                label: <div data-testid="add-stage-action">Set Stage</div>,
                onSelect: () => openBulkEditCompaniesModal('stage'),
              },
              {
                label: <div data-testid="add-hiring-contact-action">Set Point of Contact</div>,
                onSelect: () => openBulkEditCompaniesModal('talent_poc'),
              },
            ]}
          />
        </div>
      )}
      {canEditPortfolio && (
        <div>
          <CabalButton
            onClick={openImportCompaniesModal}
            variant="secondary"
            size="small"
            leftIcon={<i className="far fa-plus fa-fw" />}
            data-testid="import-portfolio-companies-btn"
          >
            Add Companies
          </CabalButton>
        </div>
      )}
      {canManageCompany && canViewPortfolio && (
        <div>
          <CabalButton
            onClick={openSharePortfolioModal}
            variant="primary"
            size="small"
            leftIcon={<i className="far fa-share fa-fw" />}
            data-testid="share-portfolio-btn"
          >
            Share
          </CabalButton>
        </div>
      )}
    </div>
  )
}

export default GlobalActions
