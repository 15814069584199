import React, { createContext, useContext, useMemo, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useInfiniteQuery, useQuery } from 'react-query'
import { ArrayParam, BooleanParam, StringParam, useQueryParams } from 'use-query-params'

import NoResultsPage from 'containers/ListIndexView/Components/NoResultsPage'
import Searches from 'containers/ListIndexView/Components/Searches'
import AddCandidate from 'containers/ListIndexView/TalentList/PeopleList/AddCandidate'
import PeopleListAdminView from 'containers/ListIndexView/TalentList/PeopleList/PeopleListAdminView'
import PeopleListBulkActions from 'containers/ListIndexView/TalentList/PeopleList/PeopleListBulkActions'
import PeopleListEmptyState from 'containers/ListIndexView/TalentList/PeopleList/PeopleListEmptyState'
import PeopleListFilters from 'containers/ListIndexView/TalentList/PeopleList/PeopleListFilters'
import PeopleListHeader from 'containers/ListIndexView/TalentList/PeopleList/PeopleListHeader'
import PeopleListMemberView from 'containers/ListIndexView/TalentList/PeopleList/PeopleListMemberView'
import TalentListCTAs from 'containers/ListIndexView/TalentList/TalentListCTAs'
import { PeopleListContextProps } from 'containers/ListIndexView/TalentList/type'
import { noFilters, useScrollToElement } from 'containers/ListIndexView/hooks'
import { StickyHeader } from 'containers/ListIndexView/style'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import PageWrapper from 'global/PageWrapper'
import { useTeamSlug } from 'store/hooks'
import SkeletonDataCard from 'ui-components/SkeletonDataCard'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanyListBlueprint } from 'utils/types'
import { SavedSearch } from 'utils/types/savedSearch'

interface IProps {
  companyListData: CompanyListBlueprint
  refetchCompanyList: () => void
}

export const PeopleListContext = createContext<PeopleListContextProps | undefined>(undefined)

export const usePeopleListContext = () => {
  const context = useContext(PeopleListContext)
  if (context === undefined) {
    throw new Error('usePeopleListContext must be used within a PeopleListProvider')
  }
  return context
}

const PeopleList = ({ companyListData, refetchCompanyList }: IProps) => {
  const teamSlug = useTeamSlug()
  const [showAddWidget, setShowAddWidget] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState<any[]>([])
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const [view, setView] = useState<'list' | 'grid'>('grid')
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const scrollToAdd = useScrollToElement('talent-people-add')

  const [talentFiltersValue, setTalentFiltersValue] = useQueryParams({
    query: StringParam,
    function: ArrayParam,
    level: ArrayParam,
    location: ArrayParam,
    function_preference: ArrayParam,
    level_preference: ArrayParam,
    stage_preference: ArrayParam,
    opportunity_type: ArrayParam,
    ideal_company_size: ArrayParam,
    office_preference: ArrayParam,
    member_view: BooleanParam,
    status: StringParam,
  })

  const {
    isLoading: companyDataIsLoading,
    data,
    refetch: refetchPeople,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [
      teamSlug,
      'opportunityData',
      companyListData?.uuid,
      companyListData?.status?.stale,
      companyListData?.item_count,
      talentFiltersValue,
    ],
    ({ pageParam: page = 1 }) =>
      callApi(
        api.getCompanies,
        teamSlug, // team slug
        page, // page
        {
          sources: [companyListData?.uuid],
        }, // filters
        { talent_filters: talentFiltersValue },
      ),
    {
      cacheTime: 0,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      keepPreviousData: true,
    },
  )

  const people = useMemo(() => {
    let companiesWithResults: any = []
    companiesWithResults =
      data?.pages?.flatMap((page) => {
        return Object.values(page.company_records || {})
      }) || []
    return companiesWithResults?.filter((company) => !!company) || []
  }, [data, companyListData])

  const adminView =
    !!isAdminOrEmployee && !!companyListData.owned && !talentFiltersValue.member_view

  const memberView = !isAdminOrEmployee || talentFiltersValue.member_view
  const emptyState =
    (!people || people?.length === 0) && noFilters(talentFiltersValue) && !showAddWidget
  const filterResultEmpty = people?.length === 0 && !noFilters(talentFiltersValue)

  const [savedSearch, setSavedSearch] = useState<SavedSearch | null>(null)
  const [emailFrequency, setEmailFrequency] = useState<string | null>('never')
  const [searchName, setSearchName] = useState<string | null>(null)
  const [savedSearchUuid, setSavedSearchUuid] = useState<string | null>(null)

  const params = new URLSearchParams(new URL(window.location.href).search)
  const filterUuid = params.get('filter')

  if (filterUuid) {
    params.delete('filter')
    const newUrl = `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}`
    window.history.replaceState({}, '', newUrl)
  }

  const handleSelectSearch = (search: SavedSearch | null, isDeleting?: boolean) => {
    const resetFilters = {
      query: undefined,
      function: undefined,
      level: undefined,
      location: undefined,
      function_preference: undefined,
      level_preference: undefined,
      stage_preference: undefined,
      opportunity_type: undefined,
      ideal_company_size: undefined,
      office_preference: undefined,
      member_view: undefined,
      status: undefined,
    }

    if (isDeleting || search === null) {
      setTalentFiltersValue(resetFilters)
      setSavedSearch(null)
      return
    }

    try {
      const queryObject = search.query || {}
      setTalentFiltersValue({
        ...resetFilters,
        ...queryObject,
      })
      setSavedSearch(search)
    } catch (error) {
      console.error('Error parsing saved search query:', error)
      cabalToast({
        content: 'Error loading saved search',
        style: 'error',
      })
    }
  }

  const contextValue = useMemo(() => {
    return {
      companyListData,
      refetchCompanyList,
      adminView,
      talentPeople: people,
      refetchPeople,
      view,
      setView,
      handleSelectSearch,
      savedSearch,
      setSavedSearch,
      setTalentFiltersValue,
      talentFiltersValue,
      savedSearchUuid,
      setSavedSearchUuid,
      searchName,
      setSearchName,
      emailFrequency,
      setEmailFrequency,
    }
  }, [
    companyListData,
    refetchCompanyList,
    adminView,
    people,
    view,
    refetchPeople,
    setView,
    savedSearch,
    setTalentFiltersValue,
    talentFiltersValue,
    savedSearchUuid,
    setSavedSearchUuid,
    searchName,
    setSearchName,
    emailFrequency,
    setEmailFrequency,
  ])

  const count = companyListData.item_count > 16 ? 16 : companyListData.item_count

  return (
    <PeopleListContext.Provider value={contextValue}>
      <PageWrapper title={companyListData?.name || 'People List'}>
        <StickyHeader>
          <div className="flex flex-col sm:flex-row justify-between gap-4 sm:items-center">
            <PeopleListHeader companyListData={companyListData} />

            <div className="flex gap-6">
              <TalentListCTAs
                onShowAddWidget={() => {
                  setShowAddWidget(!showAddWidget)
                  if (!showAddWidget) {
                    scrollToAdd()
                  }
                }}
                view={view}
                setView={setView}
                companyList={companyListData}
                refetchCompanyList={refetchCompanyList}
              />
            </div>
          </div>

          <PeopleListFilters
            disabled={false}
            setSelectedItems={setSelectedPeople}
            talentFiltersValue={talentFiltersValue}
            setTalentFiltersValue={setTalentFiltersValue}
            selectedItems={selectedPeople}
            companyList={companyListData}
          />
        </StickyHeader>
        {companyDataIsLoading && (
          <SkeletonDataCard view={view} itemCount={count} descriptionBottom={true} />
        )}
        {!companyDataIsLoading && (
          <span>
            <div ref={parent} id="talent-people-add">
              {showAddWidget && (
                <AddCandidate
                  teamSlug={teamSlug}
                  listUuid={companyListData.uuid}
                  reload={refetchPeople}
                  onClose={() => setShowAddWidget(false)}
                  isTalent={true}
                />
              )}
            </div>

            {emptyState && <PeopleListEmptyState onShowAddWidget={() => setShowAddWidget(true)} />}

            {filterResultEmpty && <NoResultsPage />}

            {adminView && !emptyState && (
              <PeopleListAdminView
                selectedPeople={selectedPeople}
                setSelectedPeople={setSelectedPeople}
              />
            )}

            {memberView && !emptyState && <PeopleListMemberView />}

            <PeopleListBulkActions
              selectedPeople={selectedPeople}
              setSelectedPeople={setSelectedPeople}
            />

            {hasNextPage && !emptyState && (
              <div className="flex items-center justify-center my-8">
                <CabalButton
                  className="w-1/5"
                  working={isFetchingNextPage}
                  onClick={() => fetchNextPage()}
                >
                  View More
                </CabalButton>
              </div>
            )}
          </span>
        )}
      </PageWrapper>
    </PeopleListContext.Provider>
  )
}

export default PeopleList
