import React from 'react'

import { AnimatePresence, motion } from 'framer-motion'

import Typography from 'global/Typography'

import { cn } from 'utils/styles'

interface AnimatedVoteCTAProps {
  selected: boolean
  selectedText: string
  unselectedText: string
  onClick: () => void
  className?: string
}

const AnimatedVoteCTA = ({
  selected,
  selectedText,
  unselectedText,
  onClick,
  className,
}: AnimatedVoteCTAProps) => {
  return (
    <Typography
      className={cn('flex gap-1 items-center justify-end cursor-pointer', className)}
      onClick={onClick}
      component="button"
    >
      <Typography
        color={selected ? 'green' : 'purple'}
        lineHeight="1"
        fontSize="12"
        className="flex items-center"
      >
        <span>{selected ? selectedText : unselectedText}</span>
      </Typography>
      <AnimatePresence initial={false} mode="wait">
        {selected && (
          <motion.span
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: [0, 1.5, 1] }}
            transition={{ type: 'spring', stiffness: 400, damping: 20 }}
            key="check"
            className="flex items-center gap-1"
          >
            <Typography
              color="green"
              lineHeight="1"
              fontSize="14"
              className="flex items-center"
              component="p"
            >
              <i className="far fa-check" />
            </Typography>
          </motion.span>
        )}
      </AnimatePresence>
    </Typography>
  )
}

export default AnimatedVoteCTA
