import { useQuery, useMutation, useQueryClient } from 'react-query'
import api, { callApi } from 'utils/api'
import { CandidateProfile, CandidateProfileAbout } from 'utils/types'
import { GlobalPerson } from './useGlobalPerson'

interface GlobalPersonWithProfile {
  person: GlobalPerson
  candidateProfile: CandidateProfile | null
}

export const useGlobalPersonWithProfile = (slug: string) => {
  const personQuery = useQuery<GlobalPerson>(
    ['globalPerson', slug],
    () => callApi(api.getGlobalPersonV2, { slug }),
    {
      enabled: !!slug,
    }
  )

  const profileQuery = useQuery<CandidateProfile>(
    ['globalPersonCandidateProfile', slug],
    () => callApi(api.getCandidateProfile, { slug }),
    {
      enabled: !!slug,
    }
  )

  return {
    data: personQuery.data && {
      person: personQuery.data,
      candidateProfile: profileQuery.data || null,
    },
    isLoading: personQuery.isLoading || profileQuery.isLoading,
    error: personQuery.error || profileQuery.error,
  }
}

export const useUpdateCandidateProfile = (slug: string) => {
  const queryClient = useQueryClient()

  return useMutation<CandidateProfile, Error, Partial<CandidateProfile>>(
    (updateData) => callApi(api.updateGlobalPersonCandidateProfile, { slug, updateData }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['globalPersonCandidateProfile', slug])
      }
    }
  )
}

export const useUpdateCandidateProfileAbout = (slug: string) => {
  const queryClient = useQueryClient()

  return useMutation<CandidateProfileAbout, Error, Partial<CandidateProfileAbout>>(
    (updateData) => callApi(api.updateGlobalPersonAbout, { slug, about: updateData }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['globalPersonCandidateProfile', slug])
        queryClient.invalidateQueries(['globalPersonAbout', slug])
      }
    }
  )
} 