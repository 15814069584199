import React from 'react'

import { linkedinSearchUrl } from 'components/Block/GlobalPerson'
import AnimatedVoteCTA from 'global/AnimatedVoteCTA'
import Avatar from 'global/Avatar'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'

import { cn } from 'utils/styles'

export interface IPerson {
  email: string | null
  global_id: number
  headline: string
  image_url?: string
  linkedin_url: string
  name: string
  source: string | null
  verified: boolean
}

const OfferRow = ({
  person,
  selected = false,
  onSelect,
  connected = false,
}: {
  person: IPerson
  selected: boolean
  onSelect: () => void
  connected?: boolean
}) => {
  return (
    <DataCardV2
      onClick={() => {
        onSelect()
      }}
      className={cn({
        'bg-[#5C69D11F] rounded-none': selected,
      })}
      checkbox={{
        checked: selected,
        onChange: () => {
          onSelect()
        },
      }}
      key={person.global_id}
      title={person.name}
      description={[person.headline]}
      avatar={<Avatar name={person.name} src={person.image_url} size={'32'} round />}
      titleIcon={
        <>
          {person.verified && (
            <Typography fontSize="12" color="purple" lineHeight={1}>
              <i className="fa fa-badge-check fa-fw" />
            </Typography>
          )}

          {connected && (
            <Tooltip label={`You are connected to ${person.name} on Linkedin`}>
              <Typography fontSize="12" lineHeight={1} color="fog_rain">
                1st
              </Typography>
            </Tooltip>
          )}

          {person.linkedin_url && (
            <Typography fontSize="12" color="fog_rain" className="cursor-pointer" lineHeight="1">
              <a
                href={person.linkedin_url}
                target="_blank"
                rel="nofollow noopener noreferrer"
                className={'cursor-pointer'}
                onClick={(e) => e.stopPropagation()}
              >
                <i className="fab fa-linkedin fa-fw"></i>
              </a>
            </Typography>
          )}
          {!person.linkedin_url && (
            <Typography fontSize="12" color="fog_rain" className="cursor-pointer" lineHeight="1">
              <a
                href={linkedinSearchUrl(person.name)}
                target="_blank"
                rel="nofollow noopener noreferrer"
                className={'cursor-pointer'}
                onClick={(e) => e.stopPropagation()}
              >
                <i className="fab fa-linkedin fa-fw"></i>
              </a>
            </Typography>
          )}
        </>
      }
      cta={
        <AnimatedVoteCTA
          selected={selected}
          selectedText="Selected"
          unselectedText="Select"
          onClick={() => {
            onSelect()
          }}
        />
      }
    />
  )
}

export default OfferRow
