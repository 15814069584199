import React, { useEffect, useState } from 'react'

import { SeeMore } from 'containers/CandidateProfile'
import { LEVEL_MAP, ROLE_FUNCTION_MAP } from 'containers/ListIndexView/constants'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import api, { callApi } from 'utils/api'
import { CandidateProfile } from 'utils/types'
import PreferencesModal from './Modals/PreferencesModal'

const OptionField = ({ title, value }: { title: string; value: string }) => {
  return (
    <div>
      <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
        {title}
      </Typography>

      <Typography fontSize="12" component="div" color="fog">
        {value}
      </Typography>
    </div>
  )
}

const Preferences = ({canEdit, hasCandidateProfile}: {canEdit: boolean, hasCandidateProfile: boolean}) => {
  const { slug } = useParams()
  const [bio, setBio] = useState('')
  const [bioLength, setBioLength] = useState(0)
  const [isExpanded, setIsExpanded] = useState(bioLength < 165 || false)

  const [showEmptyState, setShowEmptyState] = useState(!hasCandidateProfile)


  const { data: candidateProfile, isLoading, error } = useQuery<CandidateProfile>(
    ['globalPersonCandidateProfile', slug],
    () => callApi(api.getCandidateProfile, { slug }),
    {
      enabled: !!slug,
    }
  )

  useEffect(() => {
    if (candidateProfile?.bio) {
      setBioLength(candidateProfile.bio.length || 0)
      setBio(isExpanded ? candidateProfile?.bio : `${candidateProfile?.bio?.slice(0, 165)}`)
      
    }
  }, [candidateProfile])

  const { showModal } = useModal()

  const renderPreferencesModal = (resolve: () => void, tab?: 'visibility' | 'edit') => (
    <PreferencesModal
      onHide={resolve}
      options={{
        functions: candidateProfile?.functions || [],
        location: candidateProfile?.location || '',
        level: candidateProfile?.level || '',
        funding_stage_experience: candidateProfile?.funding_stage_experience || [],
        bio: candidateProfile?.bio || '',
        work_status: candidateProfile?.work_status || '',
        ideal_company_size: candidateProfile?.ideal_company_size || [],
        minimum_salary: candidateProfile?.minimum_salary || '',
      }}
      personUuid={slug}
      defaultTab={tab}
    />
  )


  return (
    <Widget
      title="Candidate Preferences"
      className="group"
      cta={
        canEdit && (
          <div className="group-hover:flex hidden flex-row gap-8">
            <CabalButton
              variant="link"
              padding={'0'}
              leftIcon={<i className="far fa-edit" />}
              onClick={() =>
                showModal((resolve) => renderPreferencesModal(resolve, 'edit'), 'preferences-modal')
              }
            >
              Edit
            </CabalButton>
            {/* <CabalButton
              variant="link"
              padding={'0'}
              leftIcon={<i className="far fa-lock" />}
              onClick={() => showModal(renderPreferencesModal, 'preferences-modal')}
            >
              Visibility
            </CabalButton> */}
          </div>
        )
      }
    >
      {showEmptyState ? (
        <div className="flex flex-col gap-4">
          <Typography fontSize="14" component="div" className="mb-1">
            Enable candidate preferences to share your job search details. You always control who
            can see this information.
          </Typography>
          <CabalButton
            variant="primary"
            onClick={() => showModal(renderPreferencesModal, 'preferences-modal')}
          >
            Enable
          </CabalButton>
        </div>
      ) : (
        <>
          {bio && (
            <p className="leading-5 mb-2">
              <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
                What I’m looking for
              </Typography>
              <Typography fontSize="12" color="fog" lineHeight={1.2}>
                {bio}
              </Typography>

              {bio.length > 165 && (
                <SeeMore
                  className="ml-1 cursor-pointer"
                  color="fog"
                  fontSize="12"
                  lineHeight={1.2}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? '...see less' : '...see more'}
                </SeeMore>
              )}
            </p>
          )}

          <div className="flex flex-col mt-6">
            <div className="flex justify-between">
              <OptionField title="Function" value={ROLE_FUNCTION_MAP[candidateProfile?.functions || '']} />
              <OptionField title="Level" value={LEVEL_MAP[candidateProfile?.level || '']} />
              <OptionField title="Size" value={candidateProfile?.ideal_company_size || ''} />
              <OptionField title="Location" value={candidateProfile?.location || ''} />
              <OptionField title="Comp" value={candidateProfile?.minimum_salary || ''} />
              <OptionField 
                title="Restrictions" 
                value={candidateProfile?.work_status === 'legal_to_work_in_us' ? 'Legal to Work in US' : 
                       candidateProfile?.work_status === 'visa_requirement' ? 'Visa Requirement' : ''} 
              />
            </div>
          </div>
        </>
      )}
    </Widget>
  )
}

export default Preferences
